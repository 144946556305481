export const sendAnalytics = (name: string, params?: Object) => {
  // @ts-ignore
  if (window.gtag) {
    if (params) {
      // @ts-ignore
      window.gtag('event', name, { ...params })
    } else {
      // @ts-ignore
      window.gtag('event', name)
    }
  }
}

export const setReferral = () => {
  // @ts-ignore
  if (window.gtag) {
    // @ts-ignore
    window.gtag('set', { referrer: "https://t.me/cryptobunny_bot" })
  }
}