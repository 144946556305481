import React, {FC} from 'react'

const Icon152Building: FC = () => {
  return (
    <svg width="152" height="152" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.4995 20.9984V3.74841C13.4995 3.5495 13.4204 3.35874 13.2798 3.21808C13.1391 3.07743 12.9484 2.99841 12.7495 2.99841H3.74945C3.55054 2.99841 3.35977 3.07743 3.21912 3.21808C3.07847 3.35874 2.99945 3.5495 2.99945 3.74841V20.9984" stroke="#4B526A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.9995 20.9984V9.74841C20.9995 9.5495 20.9204 9.35874 20.7798 9.21808C20.6391 9.07743 20.4484 8.99841 20.2495 8.99841H13.4995" stroke="#4B526A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.99945 6.74841H8.99945" stroke="#4B526A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.49945 14.2484H10.4995" stroke="#4B526A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.99945 17.9984H8.99945" stroke="#4B526A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.4995 16.4984H17.9995" stroke="#4B526A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.4995 12.7484H17.9995" stroke="#4B526A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Icon152Building;