
export class AdController {
  controller = null as any

  constructor () {
    if (this.controller) return
    //@ts-ignore
    this.controller = window.Adsgram.init({ blockId: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_ADS_BLOCK_ID : "115", debug: false });
  }

  async showAd () {
    if (this.controller) {
      try {
        await this.controller.show()
        return 'success'
      } catch {
        return 'error'
      }
    } return 'error'
  }
}