import React, {FC, ReactNode} from 'react';
import './Tab.css';

interface TabProps {
    icon: ReactNode
    name: string
    active: boolean
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const Tab: FC<TabProps> = ({ icon, name, active, onClick }) => {
    return (
        <div className="Tab--container" onClick={onClick}>
            {icon}
            <p className={`${active ? 'active' : ''}`}>{name}</p>
        </div>
    );
};

export default Tab;