import { Snackbar } from '@mui/material'
import React, { FC, useMemo } from 'react'
import AchievementCell from '../../panels/Achievements/components/Cell/Cell';
import useSnackbar from '../../hooks/useSnackbar';
import { SNACKBAR_ACHIEVEMENT } from '../../routes';
import { DefaultStateType } from '../../store/reducer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

type SnackParams = null | {
  ach?: {
    type: "building" | "total" | "tap" | "friends" | "profit" | "improv"
    level: number
    id: string
    target: number
  }
}

const SnackBar: FC = () => {

  const { t } = useTranslation();

  const { activeSnackbar, setActiveSnackbar, activeSnackbarParams } = useSnackbar()

  const achList = useSelector((s: DefaultStateType) => s.achievementsList)
  const selector = useSelector((s: DefaultStateType) => s)

  const allBuildings = useMemo(() => {
    return { ...selector.buildings, ...selector.premiumBuildings, ...selector.limitedBuildings }
  }, [selector.buildings, selector.premiumBuildings, selector.limitedBuildings])

  const params = useMemo<SnackParams>(() => {
    console.log('Opened snack: ' + activeSnackbar)
    console.log('Inside snack: ' + activeSnackbarParams)
    if (!activeSnackbar) return null
    if (activeSnackbar === SNACKBAR_ACHIEVEMENT) {
      if (achList && activeSnackbarParams && (activeSnackbarParams.id) in achList) {
        const item = achList[activeSnackbarParams.id]

        return {
          ach: {
            type: item.type,
            id: item.innerId in allBuildings ? allBuildings[item.innerId].name : 'ATM',
            level: item.level,
            target: item.target
          }
      }

      } else return null
    } else return null
  }, [activeSnackbar, activeSnackbarParams, achList])

  const descriptionAch = useMemo(() => {
    if (!params || !params.ach) return ''
    let line = ''
    if (params.ach.target) {
      line += Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 0 }).format(params.ach.target) + ' '
    } else return ''
    if (params.ach.type === 'building' && params.ach.id) {
      line += t(`buildingName--${params.ach.id}`) + ' '
    }
    line += t(`ach--${params.ach.type}`)
    return line
  }, [params])

  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setActiveSnackbar(null);
  };

  return (
    <div className='Snackbar--wrapper'>
      <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open={activeSnackbar === SNACKBAR_ACHIEVEMENT}
          autoHideDuration={3000}
          onClose={handleClose}
      >
        <div className='flex w-full items-center bg-[#EBEBEB] rounded-lg py-2.5 px-2 gap-3'>
          {
            params && params.ach ? 
            <div className='w-16 h-16 object-contain'>
              <AchievementCell 
                type={params.ach.type}
                id={params.ach.id}
                level={params.ach.level}
                target={params.ach.target}
                height={64}
                unlocked
                showModal={false}
              /> 
            </div>: null
          }
          <div className='flex flex-col text-black'>
            <div className='font-bold text-sm'>
              {t('achievementUnlocked')}
            </div>
            <div className='font-normal text-sm'>
              { descriptionAch }
            </div>
          </div>
        </div>
      </Snackbar>
    </div>
  )
}

export default SnackBar