
import React, {FC} from 'react';

const Icon96Ad: FC = () => {
  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5837_19560)">
      <path d="M48 93.0001C72.8528 93.0001 93 72.8529 93 48.0001C93 23.1473 72.8528 3.00012 48 3.00012C23.1472 3.00012 3 23.1473 3 48.0001C3 72.8529 23.1472 93.0001 48 93.0001Z" fill="#9C91E1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M27.75 11.2501C14.85 18.3001 6 32.1001 6 48.0001C6 71.2501 24.75 78.0001 48 78.0001C71.25 78.0001 90 71.2501 90 48.0001H93C93 65.1001 87 80.2501 73.5 88.6501C66.15 93.3001 57.3 96.0001 48 96.0001C21.45 96.0001 0 74.5501 0 48.0001C0 30.0001 10.05 14.2501 24.75 6.00012L27.75 11.2501Z" fill="#4F3CC8"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24 6.45023C34.95 0.000227213 48.15 -1.64977 60.45 1.65023C72.75 4.95023 83.25 13.0502 89.55 24.0002C95.85 34.9502 97.65 48.1502 94.35 60.4502C91.05 72.7502 82.95 83.2502 72 89.5502L69 84.3002C78.6 78.7502 85.65 69.6002 88.5 58.8002C91.35 48.0002 90 36.6002 84.3 27.0002C78.75 17.4002 69.6 10.3502 58.8 7.50023C48 4.65023 36.6 6.00023 27 11.7002L24 6.45023Z" fill="#4B526A"/>
      <path d="M18 34.5001C18 31.9501 19.95 30.0001 22.5 30.0001H48C54.6 30.0001 60 35.4001 60 42.0001V61.5001C60 64.0501 58.05 66.0001 55.5 66.0001H30C23.4 66.0001 18 60.6001 18 54.0001V34.5001ZM74.4 30.6001L64.8 40.2001C63.6 41.4001 63 42.9001 63 44.4001V51.4501C63 53.1001 63.6 54.6001 64.8 55.6501L74.4 65.2501C74.85 65.7001 75.3 65.8501 75.9 65.8501C77.1 65.8501 78 64.9501 78 63.7501V32.1001C78 30.9001 77.1 30.0001 75.9 30.0001C75.3 30.0001 74.85 30.1501 74.4 30.6001Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_5837_19560">
      <rect width="96" height="96" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
};

export default Icon96Ad;