import React, {FC, useEffect} from 'react';
import TelegramBackButton from "../../components/TelegramBackButton/TelegramBackButton";
import {Table, TableBody, TableCell, TableColumn, TableHeader, TableRow} from '@nextui-org/react';
import {useTranslation} from "react-i18next";
import { formatNumberWithSpaces } from '../../utils/mathUtils';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultStateType, SET_EMISSION, getDispatchObject } from '../../store/reducer';
import Icon24ChevronRight from '../../assets/icons/Icon24ChevronRight';
import { useNavigate } from 'react-router-dom';
import { ROUTE_BALANCE, ROUTE_PUBLIC_SALE } from '../../routes';
import Icon12Check from '../../assets/icons/Icon12Check';
import { fetchData } from '../../utils/api';

const EMISSIONS: any = [
    { id: 1, price: 0.005, mult: 0},
    { id: 2, price: 0.01, mult: 2},
    { id: 3, price: 0.02, mult: 4},
    { id: 4, price: 0.03, mult: 6}
];

const PrivateSale: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const emission = useSelector((s: DefaultStateType) => s.emission)
    const stage = useSelector((s: DefaultStateType) => s.stage)
    const startDate = new Date(Date.UTC(2024, 4, 30, 14, 0, 0, 0))
    const classNames = React.useMemo(
      () => ({
          th: ["EmissionTable--header bg-transparent h-fit text-xs px-4 py-3 text-white text-wrap text-center"],
          td: ["py-3 px-3 border-white/10 text-center",
              "group-data-[first=true]:border-b-[1px]",
              // middle
              "group-data-[middle=true]:border-b-[1px]"
          ],
        
      }),
      [],
    );
    
    const loadEmission = async () => {
      const response2 = await fetchData('/ttc/emission')
      if (!response2.result) return
      dispatch(getDispatchObject(SET_EMISSION, response2.result.emission))
    }
    
    useEffect(() => {
      loadEmission()
    }, [])

    return (
      <div className='overflow-x-hidden grow relative flex flex-col h-full w-full pt-4 justify-between'>
        <TelegramBackButton returnTo={ROUTE_BALANCE} />
        
        <div className='flex flex-col w-full grow'>
          <div className='EmissionTable--title'>
              <p className='font-bold max-[359px]:text-2xl min-[360px]:text-3xl text-white text-center w-full'>
                  Private-Sale
              </p>
              <p className='text-xl/6 text-white font-semibold text-center w-full mt-8'>
                  {t('emission')}
              </p>
              <p className='text-base/5 text-white font-semibold text-center w-full mt-2'>
                  {formatNumberWithSpaces(Math.round(emission ?? 0))} / {formatNumberWithSpaces(1_500_000_000)}
              </p>
          </div>

          <>
              <Table 
                  removeWrapper 
                  aria-label="Friends bonus table"
                  className='w-full h-full mt-3 max-w-full'
                  classNames={classNames}
                  layout='fixed'
              >
                  <TableHeader>
                      <TableColumn width={'15%'} align='start'>{t('emissionStage')}</TableColumn>
                      <TableColumn width={'35%'} align='start'>{t('emissionDate')}</TableColumn>
                      <TableColumn width={'20%'} align='start'>{t('emissionPrice')}</TableColumn>
                      <TableColumn width={'30%'} align='start'>{t('emissionMult')}</TableColumn>
                  </TableHeader>

                  <TableBody>

                      {EMISSIONS.map((item: any, index: number) => (
                          <TableRow key={index + 'emissionpr'}>
                              <TableCell>
                                {item.id}
                              </TableCell>
                              <TableCell>
                                {t('emissionUntil')} {
                                new Date(startDate.getTime() + 3*7*24*60*60*1000*(index+1)).toJSON().slice(0,10).split('-').reverse().join('.')
                                }
                              </TableCell>
                              <TableCell>
                                ${item.price}
                              </TableCell>
                              <TableCell>
                                <div className='flex flex-row items-center justify-center gap-1'>
                                  x{item.mult}
                                  {
                                    stage === item.id ?
                                    <Icon12Check /> : null
                                  }
                                </div>
                              </TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
          </>
        </div>
        
        <div className='flex flex-col gap-3 w-full h-fit px-4'>
          <div 
            className='bg-[#16172F] flex justify-between rounded-2xl p-4 items-center cursor-pointer'
            onClick={() => navigate(ROUTE_PUBLIC_SALE)}
          >
            <p className='text-base text-white'>
              {t('emissionMoreAbout')} Public-Sale
            </p>
            <Icon24ChevronRight />
          </div>
          <a 
            className='px-3 py-2 text-[#4F3CC8] text-center' 
            href={t('emissionHowToLink')}
            target='_blank'
          >
            {t('emissionHowTo')}
          </a>
        </div>
      </div>
    );
};

export default PrivateSale;