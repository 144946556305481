import { t } from "i18next";
import React, {FC, MouseEventHandler, useEffect, useMemo, useState} from "react";
import WoodRocket from "../../../../assets/icons/currency/rocket/Wood";
import { formatNumberShort } from "../../../../utils/mathUtils";
import Button from "../../../../components/Button/Button";
import Icon28Lock from "../../../../assets/icons/Icon28Lock";
import OverflowIcon from "../../../../components/OverflowIcon/OverflowIcon";
import CrystalBase from "../../../../assets/icons/currency/crystal/Base";
import CrystalShadow from "../../../../assets/icons/currency/crystal/Shadow";
import { useSelector } from "react-redux";
import { DefaultStateType } from "../../../../store/reducer";
import useModal from "../../../../hooks/useModal";
import { MODAL_DONATE } from "../../../../routes";
import { getTimeLeft } from "../../../../utils/utils";
import dayjs from "dayjs";

interface BuildingProps {
  imgSrc: any
  name: string
  profitRate: number
  piecePrice: number
  purchased: number
  premium: boolean
  locked: boolean
  onBuy: Function
  friendsReq: number | null
  achievementReq: number | null
  unlockPrice: number | null
  unlocked: boolean
  endsAt: string | null
  onLimitEnded: Function
}

// @ts-ignore
const tg = window['Telegram'].WebApp;

const Building: FC<BuildingProps> = ({imgSrc, name, profitRate, piecePrice, purchased, friendsReq, unlocked, achievementReq, unlockPrice, locked, premium, onBuy, onLimitEnded, endsAt}) => {

  const gold = useSelector((selector: DefaultStateType) => selector.gold)
  const crystals = useSelector((selector: DefaultStateType) => selector.crystals)
  const friendsCount = useSelector((selector: DefaultStateType) => selector.friendsCount)
  const achievementsList = useSelector((selector: DefaultStateType) => selector.achievementsList)
  const [timeLeft, setTimeLeft] = useState<string>(endsAt ? getTimeLeft(dayjs(endsAt).unix(), 'hours') : '24:00:00')
  const [unlockTime, setUnlockTime] = useState(dayjs(endsAt ?? 0).unix())
  const {setActiveModal} = useModal()

  const achCount = useMemo(() => {
    return achievementsList ? Object.values(achievementsList).filter(item => item.unlocked).length : 0
  }, [achievementsList])

  useEffect(() => {
    if (!endsAt || unlockTime === 0 || purchased > 0) return
    const interval = setInterval(() => {
      updateTime(unlockTime)
    }, 1000);

    return () => clearInterval(interval);
  }, [unlockTime]);

  const updateTime = async (lockAt: number) => {
    const newTimeLeft = getTimeLeft(lockAt, 'hours')
      if (newTimeLeft === '0:00:00') {
        onLimitEnded()
      } else {
        setTimeLeft(newTimeLeft)
      }
  }

  const buttonStyles = () => (
      (premium && ((crystals ?? 0) < piecePrice)) || (!premium && ((gold ?? 0) < piecePrice))
    ) ?
      'bg-transparent border-solid border-1 border-[#4B526A]' : 'bg-[#4F3CC8]'

  const buyBuilding = () => {
    if (disabled()) return
    (premium ? (crystals && crystals >= piecePrice) : (gold && gold >= piecePrice)) ? onBuy() : setActiveModal(MODAL_DONATE)
  }

  const disabled = () => {
    return (
      purchased === 0
      && (friendsReq && friendsReq > friendsCount)
      && (achievementReq && achievementReq > achCount)
    ) ? true : false
  }

  const BuyButton: FC = () => {
    return (
      <Button
        width="full"
        customClass={buttonStyles()}
        onClick={buyBuilding}
        disabled={disabled()}
        content={
          ((premium && ((crystals ?? 0) < piecePrice)) || (!premium && ((gold ?? 0) < piecePrice))) ?
          <div className="ImprovementItemBox--buyHour flex place-items-center w-fit">
            {
              premium ?
              <OverflowIcon 
                Base={<CrystalBase width="7.2px" height="12px" />} 
                Shadow={<CrystalShadow width="17px" height="16px"/>}
                shadowOffsetTop={6}
                shadowOffsetLeft={-5}
                width={8}
                height={12}
              /> :
              <WoodRocket height="12px" width="12px" /> 
            }
            <p className="ImprovementItemBox--value text-red-500 font-extrabold text-xs/4 ml-1">
              {formatNumberShort(piecePrice)}
            </p>
          </div> :
          <div className="BuildingItemBox--buy flex flex-col w-fit place-items-center">
            <p className="text-xs">
              {purchased === 0 ? t('buildingsBuyFor') : t('buildingsImproveFor')}
            </p>
            <div className="BuildingItemBox--buyHour flex place-items-center w-fit">
              {
                premium ?
                <OverflowIcon 
                  Base={<CrystalBase width="7.2px" height="12px" />} 
                  Shadow={<CrystalShadow width="17px" height="16px"/>}
                  shadowOffsetTop={6}
                  shadowOffsetLeft={-5}
                  width={8}
                  height={12}
                /> :
                <WoodRocket height="12px" width="12px" /> 
              }
              <p className="BuildingItemBox--value font-extrabold text-xs/4 ml-1">
                {formatNumberShort(piecePrice)}
              </p>
            </div>
          </div>
        }
      />
    )
  }

  return (
    <div className={`BuildingItemBox--container mb-2 flex flex-col w-full h-fit p-2 gap-2 rounded-2xl bg-[#16172F]`}>
      <div className="flex gap-2 w-full relative">
        <div className={`BuildingItemBox--image flex place-content-center items-center rounded-lg min-[450px]:w-[4.75rem] h-[4.75rem] aspect-square relative`}>
          <img className={`${locked? 'blur-[2px]' : ''} object-contain`} src={imgSrc} alt={name}/>
          {
            locked ? 
            <div className="absolute w-full bg-white/10 rounded-lg h-full flex place-content-center items-center">
              <Icon28Lock />
            </div>
            : null
          }
          {
            endsAt && timeLeft !== '0:00:00' && purchased === 0 ? 
            <div className="ImprovementItemBox--levelnormal absolute w-full inset-x-0 bottom-0 flex items-center justify-center gap-1 px-1 py-0.5 rounded-lg">
              <p className="text-sm text-white">
                {timeLeft}
              </p>
            </div>
            : null
          }
        </div>
        <div className={`BuildingItemBox--info w-full`}>
          <p className={`BuildingItemBox--title text-600 text-sm`}>
            {name === '?????' ? name : t(`buildingName--${name}`)}
          </p>
          <div className={`BuildingItemBox--profit mt-1 flex`}>
            <div className={`BuildingItemBox--hour mr-4 h-9 flex flex-col`}>
              <p className="text-nowrap text-xs">
                {t('profitPerHour')}
              </p>
              <div className="BuildingItemBox--profitValue flex place-items-center w-fit">
                <WoodRocket height="12px" width="12px" /> 
                <p className="font-normal text-sm ml-1">
                  {formatNumberShort(profitRate)}
                </p>
              </div>
            </div>
            { locked ? null : 
              (
                purchased ? 
                  <div className="BuildingItemBox--full border-l-1 border-slate-700/[.85] pl-4 h-9 flex flex-col">
                    <p className="text-nowrap text-xs">
                      {purchased + ' '+ t('buildingsProduce')}
                    </p>
                    <div className="BuildingItemBox--profitValue flex place-items-center w-fit flex-nowrap">
                      <WoodRocket height="12px" width="12px" /> 
                      <p className="font-normal text-sm ml-1">
                        {formatNumberShort(profitRate * purchased) + '/' +t('hourShort')}
                      </p>
                    </div>
                  </div> : 
                  (BuyButton({}))
              )
            }
          </div>
          {
            !locked && purchased ? 
            <div className="mt-2">
              {BuyButton({})}
            </div> : null
          }
        </div>
        {
          purchased > 0 ? 
            <div className="BuildingItemBox--count absolute rounded-2xl text-xs p-1 min-w-5 text-center text-semibold">
              {purchased}
            </div>
          : null
        }
      </div>

      {
        disabled() && !locked ? 
          <>
            <div className="text-xs/4 text-red-500 font-bold">
              { t('buildingRequirement') }
            </div>
            {
              friendsReq && !achievementReq ? 
              <div className="text-sm text-white">
                { t('buildingsFriends') + (friendsCount ?? 0) + '/' + friendsReq}
              </div> :
              <div className="text-sm text-white">
                { `${t('buildingsReqGet')}${achievementReq} ${t('buildingsReqAchOrFriends')}${friendsReq}${t('buildingsReqFriendsTotal')}`}
              </div>
            }
          </>
        : null
      }
    </div>
  )
}

export default Building;