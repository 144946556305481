import React, { FC } from "react";
import TapAchievement from "../../../../assets/icons/achievements/Tap";
import Building25Achievement from "../../../../assets/icons/achievements/Building25";
import Building50Achievement from "../../../../assets/icons/achievements/Building50";
import Building100Achievement from "../../../../assets/icons/achievements/Building100";
import FriendsAchievement from "../../../../assets/icons/achievements/Friends";
import ImprovementAchievement from "../../../../assets/icons/achievements/Improvement";
import ProfitAchievement from "../../../../assets/icons/achievements/Profit";
import TBAchievement from "../../../../assets/icons/achievements/TotalBuildings";
import Icon28Lock from "../../../../assets/icons/Icon28Lock";
import useModal from "../../../../hooks/useModal";
import { MODAL_ACHIEVEMENT } from "../../../../routes";

interface CellProps {
  type: 'building' | 'total' | 'tap' | 'friends' | 'profit' | 'improv'
  unlocked: boolean
  level: number
  target: number
  showModal: boolean
  height?: number
  id?: string
}

const AchievementCell: FC<CellProps> = (props) => {
  
  const {activeModal, setActiveModal, activeModalParams} = useModal();

  const cellElement = () => {
    switch (props.type) {
      case 'building': {
        const id = props.id ?? 'ATM'
        return (props.level === 0) ? <Building25Achievement id={id} height={props.height ?? undefined} /> :
                (props.level === 1) ? <Building50Achievement id={id} height={props.height ?? undefined} /> : <Building100Achievement id={id} height={props.height ?? undefined} />
      }
      case 'friends' : {
        return <FriendsAchievement level={props.level} height={props.height ?? undefined} />
      }
      case 'improv' : {
        return <ImprovementAchievement level={props.level} height={props.height ?? undefined} />
      }
      case 'profit' : {
        return <ProfitAchievement level={props.level} height={props.height ?? undefined} />
      }
      case "total": {
        return <TBAchievement level={props.level} height={props.height ?? undefined} />
      }
      case "tap": {
        return <TapAchievement level={props.level} height={props.height ?? undefined} />
      }
    }
  }
  return (
    <div 
      className={[
        'relative w-fit',
        !props.unlocked ? 'grayscale' : ''
      ].join(' ')}
      onClick={() => props.showModal && setActiveModal(MODAL_ACHIEVEMENT, { type: props.type, level: props.level, id: props.id, target: props.target})}
    >
      {cellElement()}
      {
        !props.unlocked ? 
        <div className="absolute inset-0">
          <Icon28Lock />
        </div> : null
      }
    </div>
  )
}

export default AchievementCell;