import React, {FC, HTMLAttributes, useEffect, useState} from 'react';
import Panel from "../../components/Panel/Panel";
import TelegramBackButton from "../../components/TelegramBackButton/TelegramBackButton";
import {useDispatch, useSelector} from "react-redux";
import {DefaultStateType, SET_FREEZE, getDispatchObject} from "../../store/reducer";
import { t } from 'i18next';
import './Boosts.css'

import LeagueProgress from '../General/LeagueProgress/LeagueProgress';
import BoostItem from './components/BoostItem/BoostItem';
import useModal from '../../hooks/useModal';
import { MODAL_BOOST_NORMAL, MODAL_BOOST_PREMIUM, MODAL_BUY_BUILDINGS } from '../../routes';
import { getTimeLeft } from '../../utils/utils';

const League: FC = ({}) => {

    const {activeModal, setActiveModal, activeModalParams} = useModal();
    const gold = useSelector((selector: DefaultStateType) => selector.gold)
    const crystals = useSelector((selector: DefaultStateType) => selector.crystals)
    const freezeEnergy = useSelector((selector: DefaultStateType) => selector.freezeEnergy)
    const boosts = useSelector((selector: DefaultStateType) => selector.boosts)

    const [freezeLeft, setFreezeLeft] = useState<string>(freezeEnergy.until ? getTimeLeft(freezeEnergy.until, 'minutes') : '1:00')
    const dispatch = useDispatch()

    useEffect(() => {
        if (!freezeEnergy.run) return
    
        const interval = setInterval(() => {
            const timeLeft = getTimeLeft(freezeEnergy.until, 'minutes')
            if (timeLeft === '0:00') {
                setFreezeLeft('1:00')
                dispatch(getDispatchObject(SET_FREEZE, {run: false, until: 0}))
            } else {
                setFreezeLeft(timeLeft)
            }
        }, 1000);
    
        return () => clearInterval(interval);
    }, [freezeEnergy]);

    const runningFreeze = (id: string) => {
        if (id !== 'freeze') return undefined
        else return (freezeEnergy.run && freezeLeft !== '1:00') ? freezeLeft : undefined
    }

    const openBoostTab = (item: { [key: string]: any }) => {
        if (freezeEnergy.run && item.id === 'freeze' || !gold) return
        if (item.isPremium) {
            setActiveModal(MODAL_BOOST_PREMIUM, {
                id: item.id, price: item.price, level: item.level
            })
        } else {
            if (gold >= item.price) {
                setActiveModal(MODAL_BOOST_NORMAL, {
                    id: item.id, price: item.price, level: item.level
                })
            }
            else {
                setActiveModal(MODAL_BUY_BUILDINGS)
            }
        }
    }

    return (
        <Panel scrollable={true} spaceBetween={false}>
            <TelegramBackButton />
            <LeagueProgress />
            <p className='Boosts-sectiontitle tracking-wider text-sm font-bold uppercase mt-5 mb-3'>
                {t('boostsForCoins')}
            </p>
            {
                boosts && Object.values(boosts).filter(item => !item.isPremium).map(item => 
                    <BoostItem
                        id={item.id}
                        price={item.price}
                        isPremium={item.isPremium}
                        available={gold && gold >= item.price ? true : false}
                        level={item.level}
                        running={runningFreeze(item.id)}
                        onOpenEvent={() => openBoostTab(item)}
                    />
                )
            }
            <p className='Boosts-sectiontitle tracking-wider text-sm font-bold uppercase mt-6 mb-3'>
                {t('boostsForCrystals')}
            </p>
            {
                boosts && Object.values(boosts).filter(item => item.isPremium).map(item => 
                    <BoostItem
                        id={item.id}
                        price={item.price}
                        isPremium={item.isPremium}
                        available={crystals && crystals >= item.price ? true : false}
                        level={item.level}
                        running={runningFreeze(item.id)}
                        onOpenEvent={() => openBoostTab(item)}
                    />
                )
            }
        </Panel>
    );
};

export default League;