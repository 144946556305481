import React, {FC} from "react";

const CrystalBase: FC<{width: string, height: string}> = ({width, height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8664 36.0965L4.02627 31.8462L0.278238 25.6253L5.91141 5.19971L11.8665 8.64398L11.8664 36.0965Z" fill="url(#paint0_linear_4715_14951)"/>
      <path opacity="0.8" d="M11.8669 36.0965L11.8668 21.9897L4.94249 26.0682L4.02678 31.8462L11.8669 36.0965Z" fill="url(#paint1_linear_4715_14951)" fillOpacity="0.7"/>
      <g opacity="0.8">
      <path d="M11.867 36.0963L19.7085 31.8453L23.4552 25.6253L17.822 5.19971L11.8669 8.64398L11.867 36.0963Z" fill="url(#paint2_linear_4715_14951)" fillOpacity="0.7"/>
      <path d="M11.867 36.0963L19.7085 31.8453L23.4552 25.6253L17.822 5.19971L11.8669 8.64398L11.867 36.0963Z" fill="url(#paint3_linear_4715_14951)" fillOpacity="0.5"/>
      </g>
      <path d="M5.91132 5.19976L11.8667 8.64395L11.8667 1.75557L5.91132 5.19976Z" fill="url(#paint4_linear_4715_14951)"/>
      <path d="M11.8665 25.2497L15.8097 27.5303L11.8665 29.8108L7.92322 27.5303L11.8665 25.2497Z" fill="url(#paint5_linear_4715_14951)" fillOpacity="0.5"/>
      <path d="M17.8218 5.19976L11.8665 1.75557L11.8665 8.64395L17.8218 5.19976Z" fill="url(#paint6_linear_4715_14951)" fillOpacity="0.8"/>
      <path d="M11.8665 3.2071L15.0606 5.05442L11.8665 6.90173L8.67227 5.05442L11.8665 3.2071Z" fill="url(#paint7_linear_4715_14951)" fillOpacity="0.6"/>
      <path opacity="0.8" d="M11.8665 36.0965L11.8666 21.9897L18.7909 26.0682L19.7066 31.8462L11.8665 36.0965Z" fill="url(#paint8_linear_4715_14951)"/>
      <path d="M23.454 25.6252L19.706 31.8461L18.7903 26.0681L23.454 25.6252Z" fill="url(#paint9_linear_4715_14951)" fillOpacity="0.7"/>
      <path d="M11.8665 19.8756L18.2841 23.5872L11.8665 27.2987L5.44885 23.5872L11.8665 19.8756Z" fill="url(#paint10_linear_4715_14951)" fillOpacity="0.7"/>
      <path d="M11.8665 19.8756L18.2841 23.5872L11.8665 27.2987L5.44885 23.5872L11.8665 19.8756Z" fill="url(#paint11_linear_4715_14951)" fillOpacity="0.7"/>
      <path d="M11.8665 27.2985L18.284 23.5872L15.8096 27.5305L11.8665 29.8108V27.2985Z" fill="url(#paint12_linear_4715_14951)" fillOpacity="0.9"/>
      <path d="M11.8664 27.2996L5.44894 23.5882L8.67242 5.05457L11.8665 6.90193L11.8664 27.2996Z" fill="url(#paint13_linear_4715_14951)" fillOpacity="0.6"/>
      <path d="M11.8665 27.2996L18.284 23.5882L15.0605 5.05457L11.8665 6.90193L11.8665 27.2996Z" fill="url(#paint14_linear_4715_14951)" fillOpacity="0.9"/>
      <path d="M11.8665 27.2985L5.44896 23.5872L7.92329 27.5305L11.8665 29.8108V27.2985Z" fill="url(#paint15_linear_4715_14951)" fillOpacity="0.9"/>
      <path d="M0.278419 25.6252L4.02642 31.8461L4.94214 26.0681L0.278419 25.6252Z" fill="url(#paint16_linear_4715_14951)" fillOpacity="0.7"/>
      <defs>
      <linearGradient id="paint0_linear_4715_14951" x1="34.6759" y1="3.03801" x2="36.3473" y2="26.1348" gradientUnits="userSpaceOnUse">
      <stop stopColor="#3D79ED"/>
      <stop offset="0.265625" stopColor="#3458C1" stopOpacity="0.7"/>
      <stop offset="0.618879" stopColor="#3753B8" stopOpacity="0.7"/>
      <stop offset="0.895833" stopColor="#5BB0FF" stopOpacity="0.9"/>
      <stop offset="1" stopColor="#5BB0FF" stopOpacity="0.6"/>
      </linearGradient>
      <linearGradient id="paint1_linear_4715_14951" x1="10.9397" y1="34.2349" x2="6.90635" y2="25.2899" gradientUnits="userSpaceOnUse">
      <stop offset="0.046875" stopColor="white"/>
      <stop offset="0.427083" stopColor="#DFF4FF"/>
      <stop offset="0.994792" stopColor="#0E42C8"/>
      </linearGradient>
      <linearGradient id="paint2_linear_4715_14951" x1="23.4551" y1="36.9476" x2="10.0324" y2="26.7064" gradientUnits="userSpaceOnUse">
      <stop offset="0.280479" stopColor="white"/>
      <stop offset="0.469567" stopColor="#FF79DA"/>
      <stop offset="0.692708" stopColor="#3D3AE3" stopOpacity="0.9"/>
      </linearGradient>
      <linearGradient id="paint3_linear_4715_14951" x1="23.4551" y1="35.5159" x2="10.1861" y2="22.7809" gradientUnits="userSpaceOnUse">
      <stop offset="0.161458" stopColor="white"/>
      <stop offset="1" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint4_linear_4715_14951" x1="11.8814" y1="1.87007" x2="8.75415" y2="6.99418" gradientUnits="userSpaceOnUse">
      <stop stopColor="#1AD6FF"/>
      <stop offset="1" stopColor="#496BE4" stopOpacity="0.6"/>
      <stop offset="1" stopColor="#1F3CA3"/>
      </linearGradient>
      <linearGradient id="paint5_linear_4715_14951" x1="14.1297" y1="28.8167" x2="8.70333" y2="27.5303" gradientUnits="userSpaceOnUse">
      <stop stopColor="#81FF6C"/>
      <stop offset="1" stopColor="#31F3FF"/>
      </linearGradient>
      <linearGradient id="paint6_linear_4715_14951" x1="17.8219" y1="4.84657" x2="12.145" y2="4.31909" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FF79C9"/>
      <stop offset="1" stopColor="#1F3CA3"/>
      </linearGradient>
      <linearGradient id="paint7_linear_4715_14951" x1="14.4433" y1="5.48703" x2="10.6756" y2="5.05441" gradientUnits="userSpaceOnUse">
      <stop stopColor="#52FF6E"/>
      <stop offset="1" stopColor="#31FFFF"/>
      </linearGradient>
      <linearGradient id="paint8_linear_4715_14951" x1="18.1732" y1="33.8581" x2="15.2406" y2="23.4499" gradientUnits="userSpaceOnUse">
      <stop offset="0.103809" stopColor="white"/>
      <stop offset="0.436548" stopColor="#FFAACE" stopOpacity="0.8"/>
      <stop offset="0.71875" stopColor="#6F97FF" stopOpacity="0.8"/>
      </linearGradient>
      <linearGradient id="paint9_linear_4715_14951" x1="21.1222" y1="30.241" x2="18.7903" y2="25.6252" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="0.591466" stopColor="#FF94D4"/>
      <stop offset="1" stopColor="#C994FF"/>
      </linearGradient>
      <linearGradient id="paint10_linear_4715_14951" x1="15.5499" y1="25.6809" x2="6.71848" y2="23.5871" gradientUnits="userSpaceOnUse">
      <stop stopColor="#81FF6C"/>
      <stop offset="1" stopColor="#31F3FF"/>
      </linearGradient>
      <linearGradient id="paint11_linear_4715_14951" x1="11.8665" y1="27.0322" x2="11.8665" y2="19.7062" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint12_linear_4715_14951" x1="18.597" y1="23.5872" x2="12.029" y2="29.5175" gradientUnits="userSpaceOnUse">
      <stop offset="0.149863" stopColor="white"/>
      <stop offset="0.300261" stopColor="#8CFF31"/>
      <stop offset="0.449118" stopColor="#45C3EB"/>
      <stop offset="1" stopColor="#72E6FF"/>
      </linearGradient>
      <linearGradient id="paint13_linear_4715_14951" x1="9.00112" y1="27.2996" x2="-1.17865" y2="20.1665" gradientUnits="userSpaceOnUse">
      <stop stopColor="#6CFFED"/>
      <stop offset="1" stopColor="#197F9F"/>
      </linearGradient>
      <linearGradient id="paint14_linear_4715_14951" x1="34.2943" y1="18.9596" x2="23.6611" y2="7.8261" gradientUnits="userSpaceOnUse">
      <stop offset="0.464598" stopColor="white"/>
      <stop offset="0.790313" stopColor="#6DF390"/>
      <stop offset="1" stopColor="#21CAFF" stopOpacity="0.9"/>
      </linearGradient>
      <linearGradient id="paint15_linear_4715_14951" x1="5.44896" y1="23.5872" x2="11.7041" y2="29.509" gradientUnits="userSpaceOnUse">
      <stop offset="0.125" stopColor="white"/>
      <stop offset="0.297772" stopColor="#8CFF31"/>
      <stop offset="0.447917" stopColor="#5BF5FF"/>
      </linearGradient>
      <linearGradient id="paint16_linear_4715_14951" x1="4.27042" y1="32.351" x2="3.36617" y2="25.6252" gradientUnits="userSpaceOnUse">
      <stop stopColor="#DFF4FF"/>
      <stop offset="0.994792" stopColor="#0E42C8"/>
      </linearGradient>
      </defs>
    </svg>
  )
}

export default CrystalBase;