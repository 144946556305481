import React, {FC, useEffect, useRef} from "react";
import './LeagueCoin.css'

interface LeagueCoinProps{
  onTouch: React.TouchEventHandler
  touchable: boolean
  league: 'wood' | 'iron' | 'bronze' | 'silver' | 'gold' | 'platinum' | 'diamond' | 'master'
  className: string
}

function coinImportAll(r: __WebpackModuleApi.RequireContext) : string[] {
  return r.keys().map(r) as string[];
}

const coinLeagues = coinImportAll(require.context('../../../assets/images/leagues', false, /\.(png|jpe?g|svg)$/));

const LeagueCoin: FC<LeagueCoinProps> = ({onTouch, touchable, league, className}) => {

  function load(src: string) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', resolve);
        image.addEventListener('error', reject);
        image.src = src;
    });
  }

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const leagueFile = coinLeagues.findIndex((item) => item.includes(league))
    const defaultStatic = coinLeagues.findIndex((item) => item.includes('default'))
    const url = leagueFile === -1 ? coinLeagues[defaultStatic] : coinLeagues[leagueFile]
    load(url)
      .then(() => {
        if (ref && ref.current) {
          ref.current.style.background = `url("${url}") no-repeat center`
          ref.current.style.backgroundSize = 'contain'
        }
      })
      .catch(() => {
        console.log("IMAGE LOADING ERROR")
        if (ref && ref.current) {
          ref.current.style.background = `url("${coinLeagues[defaultStatic]}") no-repeat center`
          ref.current.style.backgroundSize = 'contain'
        }
      })
  }, [league])

  return (
    <div
      ref={ref}
      onTouchEnd={onTouch}
      className={['LeagueCoin', className, league].join(' ')}
    />
  )
}

export default LeagueCoin;