import React, {FC} from 'react';
import Panel from "../../components/Panel/Panel";
import TelegramBackButton from "../../components/TelegramBackButton/TelegramBackButton";
import InviteInfo from "./components/InviteInfo/InviteInfo";
import Spacing from "../../components/Spacing/Spacing";
import {Table, TableBody, TableCell, TableColumn, TableHeader, TableRow} from '@nextui-org/react';
import IconText from "../../components/IconText/IconText";
import {formatNumberWithSpaces} from "../../utils/mathUtils";
import InviteFriend from "./components/InviteFriend/InviteFriend";
import {useTranslation} from "react-i18next";
import OverflowIcon from '../../components/OverflowIcon/OverflowIcon';
import CrystalBase from '../../assets/icons/currency/crystal/Base';
import CrystalShadow from '../../assets/icons/currency/crystal/Shadow';
import { BONUSES } from '../../utils/utils';
import WoodRocket from '../../assets/icons/currency/rocket/Wood';

const FriendsBonus: FC = () => {

    const { t } = useTranslation();

    const classNames = React.useMemo(
        () => ({
            th: ["FriendsLeague--header bg-transparent h-fit text-sm"],
            td: ["py-3 border-white/10",
                "group-data-[first=true]:border-b-[1px]",
                // middle
                "group-data-[middle=true]:border-b-[1px]"
            ],
          
        }),
        [],
      );

    return (
        <Panel spaceBetween={false} bottomElement={<InviteFriend />} scrollable={true}>
            <TelegramBackButton />

            <div className='FriendsLeague--title flex flex-col gap-2 justify-center items-center'>
                <div className='flex items-center justify-center gap-3'>
                    <p className='font-bold max-[359px]:text-2xl min-[360px]:text-3xl text-white'>
                        {t('friendsLeagueGetIt')}
                    </p>
                    <OverflowIcon 
                        Base={<CrystalBase width='24px' height='36px' />}
                        Shadow={<CrystalShadow width='51px' height='44px' />}
                        shadowOffsetTop={22}
                        shadowOffsetLeft={-14}
                        width={24}
                        height={36}
                    />
                </div>
                <p className='text-base text-white text-center'>
                    {t('friendsLeagueEveryTime')}
                </p>
            </div>

            <>
                <Table 
                    removeWrapper 
                    aria-label="Friends bonus table"
                    className='w-full h-full mt-5'
                    classNames={classNames}
                >
                    <TableHeader>
                        <TableColumn width={'50%'} align='start'>{t('friendsLeague')}</TableColumn>
                        <TableColumn width={'25%'} align='start'>{t('friendsForFriends')}</TableColumn>
                        <TableColumn width={'25%'} align='start'>{t('friendsPremium')}</TableColumn>
                    </TableHeader>

                    <TableBody>
                        <TableRow key="-1">
                            <TableCell>
                                <div className='flex gap-2 w-max items-center'>
                                    <img width={40} height={40} src={require(`../../assets/images/leagues/wood.png`)} alt="" />
                                    <p className='text-sm font-normal'>
                                        {t('wood')}
                                    </p>
                                </div>
                            </TableCell>
                            <TableCell className='text-left'>
                                <div className='InviteInfo--normalReward flex gap-1 items-center'>
                                    <WoodRocket height="12px" width="12px" /> 
                                    <p className='text-sm font-black'>
                                        1000
                                    </p>
                                </div>
                            </TableCell>
                            <TableCell className='text-left'>
                                <div className='InviteInfo--normalReward flex gap-1 items-center'>
                                    <WoodRocket height="12px" width="12px" /> 
                                    <p className='text-sm font-black'>
                                        2000
                                    </p>
                                </div>
                            </TableCell>
                        </TableRow>

                        {BONUSES.map((item: any, index: number) => (
                            <TableRow key={index + 1}>
                                <TableCell>
                                    <div className='flex gap-2 w-max items-center'>
                                        <img width={40} height={40} src={require(`../../assets/images/leagues/${item.id}.png`)} alt="" />
                                        <p className='text-sm font-normal'>
                                            {t(item.name.toLowerCase())}
                                        </p>
                                    </div>
                                </TableCell>
                                <TableCell className='text-left'>
                                    <div className='InviteInfo--normalReward flex gap-1'>
                                        <OverflowIcon 
                                            Base={<CrystalBase width='12px' height='20px' />}
                                            Shadow={<CrystalShadow width='26px' height='23px' />}
                                            shadowOffsetTop={11}
                                            shadowOffsetLeft={-7}
                                            width={12}
                                            height={20}
                                        />
                                        <p className='text-sm font-black'>
                                            {item.gold}
                                        </p>
                                    </div>
                                </TableCell>
                                <TableCell className='text-left'>
                                    <div className='InviteInfo--normalReward flex gap-1'>
                                        <OverflowIcon 
                                            Base={<CrystalBase width='12px' height='20px' />}
                                            Shadow={<CrystalShadow width='26px' height='23px' />}
                                            shadowOffsetTop={11}
                                            shadowOffsetLeft={-7}
                                            width={12}
                                            height={20}
                                        />
                                        <p className='text-sm font-black'>
                                            {item.premium}
                                        </p>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </>
        </Panel>
    );
};

export default FriendsBonus;