import React, {FC, useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { DefaultStateType } from "../../../store/reducer";
import { Progress } from "@nextui-org/react";
import { formatNumberWithSpacesShort } from "../../../utils/mathUtils";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { ROUTE_LEAGUE } from "../../../routes";

const LeagueInfo: FC = ({}) => {

  const prizepool = useSelector((selector: DefaultStateType) => selector.prizepool);
  
  const {
      level,
      goldPerHour,
      levels,
      idToLevelMap
  } = useSelector((selector: DefaultStateType) => ({
      level: selector.level ?? 'wood',
      goldPerHour: selector.goldPerHour,
      levels: selector.levels,
      idToLevelMap: selector.idToLevelMap
  }));

  const navigate = useNavigate()
  const [nextProfit, setNextProfit] = useState(0)

  useEffect(() => {
    if (!prizepool || !idToLevelMap) return
    const levelId = Object.values(idToLevelMap).indexOf(level)
    if (levelId === Object.values(idToLevelMap).length - 1) setNextProfit(prizepool[levelId])
    setNextProfit(prizepool[idToLevelMap[levelId + 1]])
  }, [level, levels, prizepool])

  return (
    levels && idToLevelMap ? <>
      <div 
        className='LeagueInfo--container flex flex-col gap-1.5 w-full h-fit'
        onClick={() => navigate(ROUTE_LEAGUE)}
      >
        <div className={`LeagueInfo--bonus flex flex-row ${level === 'master' ? 'justify-center' : 'justify-between'}`}>
          <p className='font-extrabold'>
            {`+${prizepool && prizepool[level] ? formatNumberWithSpacesShort(Math.round(prizepool[level])) : 0} CBC / ${t('day')}`}
          </p>
          {
            level !== 'master' ? 
            <p className='font-extrabold text-neutral-400'>
              {`+${prizepool && nextProfit ? formatNumberWithSpacesShort(Math.round(nextProfit)) : 0} CBC / ${t('day')}`}
            </p> : null
          }
        </div>
        <Progress
          size="md"
          aria-label="Loading..."
          classNames={{
              base: 'LeagueInfo--bar',
              indicator: 'bg-gradient-to-r from-teal-300 via-white to-violet-700'
          }}
          value={level === 'master' ? 100 : ((goldPerHour !== null && levels[level]) ? ((goldPerHour - levels[level].min) / (levels[level].max - levels[level].min)) * 100 : 0)}
        />
        <div className='LeagueInfo--gold flex flex-row'>
          <p className='font-extrabold w-1/3'>
            {formatNumberWithSpacesShort(levels[level] ? levels[level].min: 0, 0)}
          </p>
          <p
            className='font-extrabold w-1/3 text-center'
          >
            {t(level).toUpperCase()}
          </p>
          <p className='font-extrabold text-neutral-400 w-1/3 text-right'>
            {level === 'master' ? '∞' : formatNumberWithSpacesShort(levels[level] ? levels[level].max: 0, 0)}
          </p>
        </div>
      </div>
    </> : null
  )
}

export default LeagueInfo;