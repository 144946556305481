import React, {FC} from "react";

const CrystalShadow: FC<{width: string, height: string}> = ({width, height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 51 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_4703_15473" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="9" y="7" width="32" height="37">
      <rect x="9.1543" y="7.52515" width="31.7312" height="36.4013" fill="url(#paint0_linear_4703_15473)"/>
      </mask>
      <g mask="url(#mask0_4703_15473)">
      <g opacity="0.8">
      <path d="M25.867 9.69024L33.7071 13.9405L37.4552 20.1614L31.822 40.587L25.8669 37.1428L25.867 9.69024Z" fill="url(#paint1_linear_4703_15473)"/>
      <path opacity="0.8" d="M25.8662 9.69037L25.8663 23.7971L32.7907 19.7187L33.7064 13.9407L25.8662 9.69037Z" fill="url(#paint2_linear_4703_15473)" fillOpacity="0.7"/>
      <g opacity="0.8">
      <path d="M25.8662 9.69042L18.0247 13.9415L14.278 20.1614L19.9112 40.587L25.8662 37.1428L25.8662 9.69042Z" fill="url(#paint3_linear_4703_15473)" fillOpacity="0.7"/>
      <path d="M25.8662 9.69042L18.0247 13.9415L14.278 20.1614L19.9112 40.587L25.8662 37.1428L25.8662 9.69042Z" fill="url(#paint4_linear_4703_15473)" fillOpacity="0.5"/>
      </g>
      <path d="M31.8221 40.587L25.8667 37.1428L25.8667 44.0312L31.8221 40.587Z" fill="url(#paint5_linear_4703_15473)"/>
      <path d="M25.8669 20.537L21.9237 18.2565L25.8669 15.976L29.8102 18.2565L25.8669 20.537Z" fill="url(#paint6_linear_4703_15473)" fillOpacity="0.5"/>
      <path d="M19.9111 40.587L25.8664 44.0312L25.8664 37.1428L19.9111 40.587Z" fill="url(#paint7_linear_4703_15473)" fillOpacity="0.8"/>
      <path d="M25.8669 42.5796L22.6728 40.7323L25.8669 38.885L29.0611 40.7323L25.8669 42.5796Z" fill="url(#paint8_linear_4703_15473)" fillOpacity="0.6"/>
      <path opacity="0.8" d="M25.8665 9.69013L25.8664 23.7969L18.942 19.7185L18.0263 13.9405L25.8665 9.69013Z" fill="url(#paint9_linear_4703_15473)"/>
      <path d="M14.2784 20.1614L18.0264 13.9405L18.9421 19.7185L14.2784 20.1614Z" fill="url(#paint10_linear_4703_15473)" fillOpacity="0.7"/>
      <path d="M25.8669 25.9111L19.4493 22.1996L25.8669 18.488L32.2846 22.1996L25.8669 25.9111Z" fill="url(#paint11_linear_4703_15473)" fillOpacity="0.7"/>
      <path d="M25.8669 25.9111L19.4493 22.1996L25.8669 18.488L32.2846 22.1996L25.8669 25.9111Z" fill="url(#paint12_linear_4703_15473)" fillOpacity="0.7"/>
      <path d="M25.8669 18.4881L19.4494 22.1995L21.9238 18.2562L25.8669 15.9758L25.8669 18.4881Z" fill="url(#paint13_linear_4703_15473)" fillOpacity="0.9"/>
      <path d="M25.867 18.4871L32.2845 22.1986L29.061 40.7322L25.8669 38.8848L25.867 18.4871Z" fill="url(#paint14_linear_4703_15473)" fillOpacity="0.6"/>
      <path d="M25.8669 18.4871L19.4494 22.1986L22.6729 40.7322L25.8669 38.8848L25.8669 18.4871Z" fill="url(#paint15_linear_4703_15473)" fillOpacity="0.9"/>
      <path d="M25.8669 18.4881L32.2844 22.1995L29.8101 18.2562L25.8669 15.9758L25.8669 18.4881Z" fill="url(#paint16_linear_4703_15473)" fillOpacity="0.9"/>
      <path d="M37.4547 20.1614L33.7067 13.9405L32.791 19.7185L37.4547 20.1614Z" fill="url(#paint17_linear_4703_15473)" fillOpacity="0.7"/>
      </g>
      </g>
      <g filter="url(#filter0_f_4703_15473)">
      <ellipse cx="25.8667" cy="13.1748" rx="21.8667" ry="9.54957" fill="url(#paint18_radial_4703_15473)" fillOpacity="0.6"/>
      </g>
      <g filter="url(#filter1_f_4703_15473)">
      <ellipse cx="25.8668" cy="13.1748" rx="15.5321" ry="6.78315" fill="url(#paint19_radial_4703_15473)" fillOpacity="0.6"/>
      </g>
      <path d="M25.8667 5.30846L37.248 11.8907L25.8667 18.4729L14.4854 11.8907L25.8667 5.30846Z" fill="url(#paint20_linear_4703_15473)" fillOpacity="0.5"/>
      <defs>
      <filter id="filter0_f_4703_15473" x="0.912149" y="0.537394" width="49.9091" height="25.2748" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="1.54393" result="effect1_foregroundBlur_4703_15473"/>
      </filter>
      <filter id="filter1_f_4703_15473" x="7.24687" y="3.30375" width="37.2399" height="19.742" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="1.54393" result="effect1_foregroundBlur_4703_15473"/>
      </filter>
      <linearGradient id="paint0_linear_4703_15473" x1="25.0199" y1="-6.85346" x2="25.0199" y2="32.4932" gradientUnits="userSpaceOnUse">
      <stop/>
      <stop offset="1" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint1_linear_4703_15473" x1="3.05748" y1="42.7487" x2="1.38609" y2="19.6519" gradientUnits="userSpaceOnUse">
      <stop stopColor="#3D79ED"/>
      <stop offset="0.265625" stopColor="#3458C1" stopOpacity="0.7"/>
      <stop offset="0.618879" stopColor="#3753B8" stopOpacity="0.7"/>
      <stop offset="0.895833" stopColor="#5BB0FF" stopOpacity="0.9"/>
      <stop offset="1" stopColor="#5BB0FF" stopOpacity="0.6"/>
      </linearGradient>
      <linearGradient id="paint2_linear_4703_15473" x1="26.7935" y1="11.552" x2="30.8268" y2="20.497" gradientUnits="userSpaceOnUse">
      <stop offset="0.046875" stopColor="white"/>
      <stop offset="0.427083" stopColor="#DFF4FF"/>
      <stop offset="0.994792" stopColor="#0E42C8"/>
      </linearGradient>
      <linearGradient id="paint3_linear_4703_15473" x1="14.278" y1="8.83916" x2="27.7008" y2="19.0803" gradientUnits="userSpaceOnUse">
      <stop offset="0.280479" stopColor="white"/>
      <stop offset="0.469567" stopColor="#FF79DA"/>
      <stop offset="0.692708" stopColor="#3D3AE3" stopOpacity="0.9"/>
      </linearGradient>
      <linearGradient id="paint4_linear_4703_15473" x1="14.278" y1="10.2709" x2="27.547" y2="23.0058" gradientUnits="userSpaceOnUse">
      <stop offset="0.161458" stopColor="white"/>
      <stop offset="1" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint5_linear_4703_15473" x1="25.852" y1="43.9167" x2="28.9792" y2="38.7926" gradientUnits="userSpaceOnUse">
      <stop stopColor="#1AD6FF"/>
      <stop offset="1" stopColor="#496BE4" stopOpacity="0.6"/>
      <stop offset="1" stopColor="#1F3CA3"/>
      </linearGradient>
      <linearGradient id="paint6_linear_4703_15473" x1="23.6037" y1="16.97" x2="29.0301" y2="18.2565" gradientUnits="userSpaceOnUse">
      <stop stopColor="#81FF6C"/>
      <stop offset="1" stopColor="#31F3FF"/>
      </linearGradient>
      <linearGradient id="paint7_linear_4703_15473" x1="19.911" y1="40.9402" x2="25.5879" y2="41.4677" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FF79C9"/>
      <stop offset="1" stopColor="#1F3CA3"/>
      </linearGradient>
      <linearGradient id="paint8_linear_4703_15473" x1="23.2901" y1="40.2997" x2="27.0578" y2="40.7323" gradientUnits="userSpaceOnUse">
      <stop stopColor="#52FF6E"/>
      <stop offset="1" stopColor="#31FFFF"/>
      </linearGradient>
      <linearGradient id="paint9_linear_4703_15473" x1="19.5597" y1="11.9285" x2="22.4923" y2="22.3367" gradientUnits="userSpaceOnUse">
      <stop offset="0.103809" stopColor="white"/>
      <stop offset="0.436548" stopColor="#FFAACE" stopOpacity="0.8"/>
      <stop offset="0.71875" stopColor="#6F97FF" stopOpacity="0.8"/>
      </linearGradient>
      <linearGradient id="paint10_linear_4703_15473" x1="16.6103" y1="15.5456" x2="18.9421" y2="20.1614" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="0.591466" stopColor="#FF94D4"/>
      <stop offset="1" stopColor="#C994FF"/>
      </linearGradient>
      <linearGradient id="paint11_linear_4703_15473" x1="22.1835" y1="20.1059" x2="31.0149" y2="22.1996" gradientUnits="userSpaceOnUse">
      <stop stopColor="#81FF6C"/>
      <stop offset="1" stopColor="#31F3FF"/>
      </linearGradient>
      <linearGradient id="paint12_linear_4703_15473" x1="25.8669" y1="18.7545" x2="25.8669" y2="26.0805" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint13_linear_4703_15473" x1="19.1364" y1="22.1995" x2="25.7044" y2="16.2691" gradientUnits="userSpaceOnUse">
      <stop offset="0.149863" stopColor="white"/>
      <stop offset="0.300261" stopColor="#8CFF31"/>
      <stop offset="0.449118" stopColor="#45C3EB"/>
      <stop offset="1" stopColor="#72E6FF"/>
      </linearGradient>
      <linearGradient id="paint14_linear_4703_15473" x1="28.7323" y1="18.4871" x2="38.912" y2="25.6202" gradientUnits="userSpaceOnUse">
      <stop stopColor="#6CFFED"/>
      <stop offset="1" stopColor="#197F9F"/>
      </linearGradient>
      <linearGradient id="paint15_linear_4703_15473" x1="3.43913" y1="26.8271" x2="14.0723" y2="37.9606" gradientUnits="userSpaceOnUse">
      <stop offset="0.464598" stopColor="white"/>
      <stop offset="0.790313" stopColor="#6DF390"/>
      <stop offset="1" stopColor="#21CAFF" stopOpacity="0.9"/>
      </linearGradient>
      <linearGradient id="paint16_linear_4703_15473" x1="32.2844" y1="22.1995" x2="26.0293" y2="16.2777" gradientUnits="userSpaceOnUse">
      <stop offset="0.125" stopColor="white"/>
      <stop offset="0.297772" stopColor="#8CFF31"/>
      <stop offset="0.447917" stopColor="#5BF5FF"/>
      </linearGradient>
      <linearGradient id="paint17_linear_4703_15473" x1="33.4627" y1="13.4357" x2="34.367" y2="20.1614" gradientUnits="userSpaceOnUse">
      <stop stopColor="#DFF4FF"/>
      <stop offset="0.994792" stopColor="#0E42C8"/>
      </linearGradient>
      <radialGradient id="paint18_radial_4703_15473" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.8667 13.1748) rotate(90) scale(9.54957 21.8667)">
      <stop stopColor="#739AFF"/>
      <stop offset="1" stopColor="#6978FF" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint19_radial_4703_15473" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.8668 13.1748) rotate(90) scale(6.78315 15.5321)">
      <stop stopColor="#739AFF"/>
      <stop offset="1" stopColor="#6978FF" stopOpacity="0"/>
      </radialGradient>
      <linearGradient id="paint20_linear_4703_15473" x1="31.5573" y1="6.20004" x2="20.1761" y2="17.5813" gradientUnits="userSpaceOnUse">
      <stop stopColor="#6C7BFF"/>
      <stop offset="1" stopColor="#31B5FF"/>
      </linearGradient>
      </defs>
    </svg>
  )
}

export default CrystalShadow;