import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/react';
import React, {FC, useEffect, useMemo, useState} from 'react';
import {MODAL_AD} from "../routes";
import useModal from "../hooks/useModal";
import {useTranslation} from "react-i18next";
import { useNavigate } from 'react-router-dom';
import Icon96Ad from '../assets/icons/Icon96Ad';

const AdModal: FC = () => {

    const { t } = useTranslation();
    const {activeModal, setActiveModal, activeModalParams} = useModal();
    const navigate = useNavigate()

    const params = useMemo(() => {
      if (activeModal !== MODAL_AD || !activeModalParams) return null
      return {
        bonus: activeModalParams.bonus
      }
    }, [activeModalParams])
    
    return (
        <Modal
            isOpen={activeModal === MODAL_AD}
            placement='bottom'
            scrollBehavior='inside'
            className='bg-[#19192B] relative'
            onClose={() => setActiveModal(null)}
        >
            <ModalContent>
                {() => (
                    <>
                        <ModalHeader></ModalHeader>
                        <ModalBody className="px-6 gap-6">
                            <div className='flex flex-col items-center justify-center'>
                              <Icon96Ad />
                              <p className='text-3xl font-bold text-center mt-5'>
                                {t('adTitle')}
                              </p>
                              <p className='text-base/6 text-center mt-1.5'>
                                {t(params?.bonus ? 'adDescBonus' : 'adDesc')}
                              </p>
                            </div>
                        </ModalBody>
                        <ModalFooter className='flex-col'>
                          <Button
                            fullWidth
                            className='bg-[var(--link\_color)] opacity-100 rounded-lg px-3 h-fit py-4 text-lg'
                            onPress={() => {
                              setActiveModal(null)
                            }}
                          >
                            {t('modalGreat')}
                          </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default AdModal;