import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/react';
import React, {FC, useEffect, useState} from 'react';
import {MODAL_BOOST_PREMIUM, MODAL_DONATE, ROUTE_HOME} from "../routes";
import useModal from "../hooks/useModal";
import {useTranslation} from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { ADD_GOLD, DefaultStateType, REDUCE_CRYSTALS, SET_ENERGY_LEFT, SET_FREEZE, SET_RECHARGING_ENERGY_PER_SECOND, UPDATE_BOOST, getDispatchObject } from '../store/reducer';
import OverflowIcon from '../components/OverflowIcon/OverflowIcon';
import CrystalBase from '../assets/icons/currency/crystal/Base';
import CrystalShadow from '../assets/icons/currency/crystal/Shadow';
import { fetchData } from '../utils/api';
import { boostsFrontToServerId } from '../utils/utils';
import { formatNumberShort } from '../utils/mathUtils';
import { sendAnalytics } from '../utils/gtag';

interface ModalParams {
  id: string
  name: string
  description: string
  price: number
  level: number
}

// @ts-ignore
const tg = window['Telegram'].WebApp;

const BoostsPremiumModal: FC = () => {

    const { t } = useTranslation();
    const crystals = useSelector((s: DefaultStateType) => s.crystals)
    const goldPerHour = useSelector((s: DefaultStateType) => s.goldPerHour)
    const {activeModal, setActiveModal, activeModalParams} = useModal();
    const [params, setParams] = useState<ModalParams>()
    const dispatch = useDispatch()
    // const navigate = useNavigate()

    useEffect(() => {
      if (!activeModalParams) return
      setParams({
        id: activeModalParams.id,
        name: t(`boostName--${activeModalParams.id}`),
        description: t(`boostDescription--${activeModalParams.id}`),
        price: activeModalParams.price,
        level: activeModalParams.level
      })
    }, [activeModalParams])

    const updateBoost = async () => {
      if (!params?.id) return
      const response = await fetchData('/boosts/get')
      const result = response.result
      const newBoost = result.find((item: any) => item.id === boostsFrontToServerId[params?.id])

      if (!newBoost) return

      dispatch(getDispatchObject(UPDATE_BOOST, {
        id: params.id,
        level: newBoost.level,
        price: newBoost.updateCost,
        isPremium: newBoost?.updateCostCurrency !== 'gold'
      }))
    }
    
    const buyBoost = async () => {
      if (!params?.id || !params?.price) return

      const response = await fetchData('/boosts/buy', {
        boost: boostsFrontToServerId[params?.id]
      })

      if (response.error) return
      const result = response.result

      if (result.id === 'recharging') {
        dispatch(getDispatchObject(SET_ENERGY_LEFT, result['newValue']));
      }

      if (result.id === 'diamonds_exchange_10_hours' && goldPerHour) {
        console.log(JSON.stringify(goldPerHour))
        dispatch(getDispatchObject(ADD_GOLD, goldPerHour * 10))
      }

      if (result.id === 'diamonds_exchange_24_hours' && goldPerHour) {
        dispatch(getDispatchObject(ADD_GOLD, goldPerHour * 24))
      }

      if (params?.id === 'freeze') {
        dispatch(getDispatchObject(SET_FREEZE, { run: true, until: new Date().getTime() + 61*1000 }))
      }

      sendAnalytics('spend_virtual_currency', {
        event_category: 'boost',
        value: params.price,
        virtual_currency_name: 'crystals',
        item_name: params.id
      })
      
      dispatch(getDispatchObject(REDUCE_CRYSTALS, params.price));
      await updateBoost()
      setActiveModal(null)

    }

    const confirm =() => {
        tg.showPopup({
          title: t('boostsConfirmTitle'),
          message: t('boostsConfirmText'),
          buttons: [
              {
                  id: 'confirm',
                  type: 'default',
                  text: t('boostsConfirmButton')
              },
              {
                  id: 'cancel',
                  type: 'cancel',
                  text: t('boostsConfirmCancelButton')
              },
          ],
      }, (id: string) => {
          if (id === 'confirm') {
            buyBoost();
          }
      });
    }

    return (
        <Modal
            isOpen={params && activeModal === MODAL_BOOST_PREMIUM}
            placement='bottom'
            scrollBehavior='inside'
            className='bg-[#19192B] relative'
            onClose={() => setActiveModal(null)}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader></ModalHeader>
                        <ModalBody className="px-4 gap-6">
                            <div className='flex flex-col items-center justify-center'>
                              <img width={96} height={96} className='object-contain' src={require(`../assets/images/boosts/${params?.id ?? 'energyUp'}.png`)} alt="" />
                              <p className='text-3xl font-bold text-center mt-5'>
                                {(params?.name) ?? t('noTitle')}
                              </p>
                              <p className='text-base/6 text-center mt-1.5'>
                                {(params?.description) ?? t('noDescription')}
                              </p>
                              {
                                (params?.price ?? 0) > (crystals ?? 1) ? 
                                <p className='text-base/6 text-red-600 font-semibold text-center mt-4'>
                                  {t('boostsInsufficientFunds')}
                                </p> : null
                              }
                              <div className='flex w-fit gap-2 items-center mt-4'>
                                <OverflowIcon 
                                  Base={<CrystalBase width='24px' height='36px' />}
                                  Shadow={<CrystalShadow width='51px' height='44px' />}
                                  shadowOffsetTop={22}
                                  shadowOffsetLeft={-14}
                                  width={24}
                                  height={40}
                                />
                                <p className='text-4xl font-bold text-center'>
                                  {formatNumberShort(Math.round(Math.ceil((params?.price ?? 0) * 10) / 10))}
                                </p>
                                {
                                  params?.level ?
                                  <p className="BoostItem--divider text-3xl">•</p> : null
                                }
                                {
                                  params?.level ?
                                  <p className="BoostItem--level text-3xl">
                                    {params?.level ?? 0} {t('boostsLevel')}
                                  </p> : null
                                }
                              </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='flex-col'>
                          {
                            (params?.price ?? 0) > (crystals ?? 1) ? 
                              <Button
                                fullWidth
                                className='bg-[var(--link\_color)] opacity-100 rounded-lg px-3 py-4 h-fit text-lg'
                                onPress={() => setActiveModal(MODAL_DONATE)}
                              >
                                {t('boostsBuyCrystals')}
                              </Button> :
                              <Button
                                fullWidth
                                className='bg-[var(--link\_color)] opacity-100 rounded-lg px-3 py-4 h-fit text-lg'
                                onPress={confirm}
                              >
                                {t('boostsGet')}
                              </Button>

                          }
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default BoostsPremiumModal;