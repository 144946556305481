import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/react';
import React, {FC} from 'react';
import {MODAL_BANK} from "../routes";
import useModal from "../hooks/useModal";
import {useTranslation} from "react-i18next";

const BankDescriptionModal: FC = () => {

    const { t } = useTranslation();

    const {activeModal, setActiveModal} = useModal();

    return (
        <Modal
            isOpen={activeModal === MODAL_BANK}
            placement='bottom'
            className='bg-[#19192B]'
            onClose={() => setActiveModal(null)}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader></ModalHeader>
                        <ModalBody className='px-6'>
                            <div className='flex flex-col gap-3 items-center justify-center'>
                              <p className="text-3xl text-white font-black text-center">
                                {t('leagueBank')}
                              </p>
                              <p className="text-base text-white font-normal text-center">
                                {t('modalBankRaffleDistribution')}
                              </p>
                              <p className="text-base text-white font-normal text-center">
                                {t('modalBankRaffleFill')}
                              </p>
                            </div>
                        </ModalBody>
                        <ModalFooter className='px-6 mb-4'>
                            <Button
                                fullWidth
                                className='bg-[var(--link\_color)] opacity-100 rounded-xl text-lg py-4 h-fit'
                                onPress={onClose}
                            >
                                {t('modalGotIt')}
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default BankDescriptionModal;