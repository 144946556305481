import React, {FC} from 'react';

const Icon22Premium: FC = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="22" height="22" rx="11" fill="url(#paint0_linear_6065_15664)"/>
      <path d="M15.334 14.3333H6.66732C6.48398 14.3333 6.33398 14.4833 6.33398 14.6667V15.3333C6.33398 15.5167 6.48398 15.6667 6.66732 15.6667H15.334C15.5173 15.6667 15.6673 15.5167 15.6673 15.3333V14.6667C15.6673 14.4833 15.5173 14.3333 15.334 14.3333ZM16.6673 7.66667C16.1152 7.66667 15.6673 8.11458 15.6673 8.66667C15.6673 8.81458 15.7007 8.95208 15.759 9.07917L14.2507 9.98333C13.9298 10.175 13.5152 10.0667 13.3298 9.74167L11.6319 6.77083C11.8548 6.5875 12.0007 6.3125 12.0007 6C12.0007 5.44792 11.5527 5 11.0007 5C10.4486 5 10.0007 5.44792 10.0007 6C10.0007 6.3125 10.1465 6.5875 10.3694 6.77083L8.67148 9.74167C8.48607 10.0667 8.0694 10.175 7.75065 9.98333L6.2444 9.07917C6.30065 8.95417 6.33607 8.81458 6.33607 8.66667C6.33607 8.11458 5.88815 7.66667 5.33607 7.66667C4.78398 7.66667 4.33398 8.11458 4.33398 8.66667C4.33398 9.21875 4.7819 9.66667 5.33398 9.66667C5.38815 9.66667 5.44232 9.65833 5.4944 9.65L7.00065 13.6667H15.0007L16.5069 9.65C16.559 9.65833 16.6132 9.66667 16.6673 9.66667C17.2194 9.66667 17.6673 9.21875 17.6673 8.66667C17.6673 8.11458 17.2194 7.66667 16.6673 7.66667Z" fill="#FEFCFF"/>
      <defs>
      <linearGradient id="paint0_linear_6065_15664" x1="-1.62901e-08" y1="-0.6875" x2="25.502" y2="4.38141" gradientUnits="userSpaceOnUse">
      <stop stopColor="#17AEE9"/>
      <stop offset="0.496" stopColor="#E44EBF"/>
      <stop offset="1" stopColor="#FE7014"/>
      </linearGradient>
      </defs>
    </svg>
  )
}

export default Icon22Premium;