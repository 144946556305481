import React, {FC, useEffect, useMemo, useState} from 'react';
import Panel from "../../components/Panel/Panel";
import {useTranslation} from "react-i18next";
import {MODAL_DAILY_TASK, MODAL_DEFAULT_TASK, MODAL_TASK_CHANNEL} from "../../routes";
import useModal from "../../hooks/useModal";
import WoodRocket from '../../assets/icons/currency/rocket/Wood';
import TaskItem from './components/TaskItem/TaskItem';
import './Tasks.css'
import TaskSection from './components/TaskSection/TaskSection';
import { useSelector } from 'react-redux';
import { DefaultStateType } from '../../store/reducer';
import dayjs from 'dayjs';
import TaskAds from './components/TaskAds/TaskAds';

type TaskType = 'available' | 'time_limited' | 'time_locked' | 'completed'

const Tasks: FC = () => {

    const { t } = useTranslation();

    const {setActiveModal} = useModal();
    const currentDaily = useSelector((s: DefaultStateType) => s.currentDaily)
    const tasks = useSelector((s: DefaultStateType) => s.tasks)

    const normalTasks = useMemo(() => {
        if (!tasks) return null
        return Object.values(tasks).filter((task) => (!task.startsFrom || !task.endsAt) && !task.completed)
    }, [tasks])

    const limitedTasks = useMemo(() => {
        if (!tasks) return null

        return Object.values(tasks).filter((task) => 
            (task.startsFrom 
            && task.endsAt 
            && task.endsAt > dayjs().unix() && task.startsFrom < dayjs().unix()) 
            && !task.completed)
    }, [tasks])

    const completedTasks = useMemo(() => {
        if (!tasks) return null
        return Object.values(tasks).filter((task) => task.completed)
    }, [tasks])

    const taskTitle = (type: string, action: string) => {
        return t(`tasks--${type}${action === "" ? "" : ('-' + action)}`)
    }

    const goToModal = (type: string, task: { [key: string]: any }) => {
        if (type === 'daily') {
            setActiveModal(MODAL_DAILY_TASK)
        } else if (type === 'time_limited') {
            setActiveModal(MODAL_DEFAULT_TASK, { 
                code: task.code,
                type: task.type, 
                action: task.action, 
                description: task?.locale?.description ? task.locale.description : taskTitle(task.type, task.action ?? ""), 
                status: 'time_limited', 
                'time' : task.time, 
                bonus: task.bonus,
                bonuses: task.bonuses,
                completed: task.completed,
                dbtype: task.dbType,
                imageLink: task.imageLink ?? null,
                link: task?.link ?? null
            })
        } else {
            setActiveModal(MODAL_DEFAULT_TASK, { 
                code: task.code, 
                type: task.type, 
                action: task.action, 
                description: task?.locale?.description ? task.locale.description : taskTitle(task.type, task.action ?? ""), 
                status: 'available', 
                bonus: task.bonus,
                bonuses: task.bonuses,
                completed: task.completed,
                dbtype: task.dbType,
                imageLink: task.imageLink ?? null,
                link: task?.link ?? null
            })
        }
    }

    return (
        <Panel spaceBetween={false} scrollable={true}>

            <div className='Tasks--title flex flex-col items-center justify-center gap-2 w-full'>
                <p className='font-bold text-3xl w-fit'>
                    {t('navbarTasks')}
                </p>
                <div className='flex gap-1 w-fit items-center text-base'>
                    {t('tasksTitleStart')}
                    <WoodRocket width='16px' height='16px' />
                    {t('tasksTitleEnd')}
                </div>
            </div>

            {
                currentDaily ?
                <div className='Tasks--section flex flex-col w-full mt-6 gap-3'>
                    <div className='Tasks--sectiontitle flex'>
                        <p className='tracking-wider text-sm font-bold uppercase'>
                            {t('tasksDailySection')}
                        </p>
                    </div>
                    <TaskItem 
                        type='daily'
                        startsFrom={currentDaily.unlockedAt}
                        endsAt={currentDaily.unlockedAt}
                        title={t('tasksDaily')}
                        bonus={currentDaily.profit}
                        bonuses={null}
                        status={currentDaily.available ? 'time_limited' : 'time_locked'}
                        onClick={() => goToModal('daily', currentDaily)}
                        link={null}
                        imageLink={null}
                        locale={{
                            title: t('tasksDaily'),
                            description: ''
                        }}
                    />
                </div> : null
            }
            {
                limitedTasks && limitedTasks.length > 0 ? 
                <TaskSection 
                    section='limited'
                    tasks={limitedTasks}
                    openModal={(task: any) => goToModal(MODAL_DEFAULT_TASK ,task)}
                /> : null
            }
            {
                normalTasks && normalTasks.length > 0 ? 
                <TaskSection 
                    section='extra'
                    tasks={normalTasks}
                    openModal={(task: any) => goToModal(MODAL_DEFAULT_TASK ,task)}
                /> : null
            }
            <TaskAds />
            {
                completedTasks && completedTasks.length > 0 ? 
                <TaskSection 
                    section='completed'
                    tasks={completedTasks}
                    openModal={(task: any) => goToModal(MODAL_DEFAULT_TASK ,task)}
                /> : null
            }
        </Panel>
    );
};

export default Tasks;