import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/react';
import React, {FC} from 'react';
import {MODAL_LEVEL_UP} from "../routes";
import useModal from "../hooks/useModal";
import {useTranslation} from "react-i18next";
import LeagueCoin from '../components/Leagues/Coin/LeagueCoin';
import LeagueValue from '../components/Leagues/Value/LeagueValue';

const LevelUpModal: FC = () => {

    const { t } = useTranslation();

    const {activeModal, setActiveModal, activeModalParams} = useModal();

    const isModalLoaded = () => {
      return activeModalParams && activeModalParams['level'] && activeModalParams['bonuses']
    }

    return (
        <Modal
            isOpen={activeModal === MODAL_LEVEL_UP && isModalLoaded()}
            placement='bottom'
            className='bg-[#19192B]'
            onClose={() => setActiveModal(null)}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader></ModalHeader>
                        <ModalBody className='px-6 gap-6'>
                            <div className='LevelUp--title flex flex-row gap-2 max-[419px]:text-2xl min-[420px]:text-3xl font-bold items-center justify-center'>
                              <p className='text-white text-right'>
                                {t('modalLevelUp')}
                              </p>
                              <p className='text-slate-600'>•</p>
                              <p className='text-white capitalize'>
                                {t(activeModalParams['level'])}
                              </p>
                            </div>
                            <div className='LevelUp--bonus flex w-full gap-4 h-[100px]'>
                              <LeagueCoin 
                                league={activeModalParams['level']}
                                touchable={false}
                                onTouch={() => {}}
                                className='w-[100px] h-[100px] m-0'
                              />
                              <div className='flex flex-col gap-3'>
                                <div className='align-box-end flex flex-row gap-2'>
                                  <LeagueValue 
                                    text={activeModalParams['bonuses'].tap}
                                    league={activeModalParams['level']}
                                  />
                                  <p className='text-base pb-0.5'>
                                    {t('profitPerHour')}
                                  </p>
                                </div>
                                <div className='align-box-end flex flex-row gap-2'>
                                  <LeagueValue 
                                    text={activeModalParams['bonuses'].energy}
                                    league={activeModalParams['level']}
                                  />
                                  <p className='text-base pb-0.5'>
                                    {t('energyLimit')}
                                  </p>
                                </div>
                              </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='px-6 mb-4'>
                            <Button
                                fullWidth
                                className='bg-[var(--link\_color)] opacity-100 rounded-lg text-lg'
                                onPress={onClose}
                            >
                                {t('modalCool')}
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default LevelUpModal;