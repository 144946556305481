import { t } from "i18next"
import React, {FC} from "react"
import TaskItem from "../TaskItem/TaskItem"

interface TaskSectionProps {
  section: 'limited' | 'extra' | 'completed'
  tasks: Array<{
    type: string
    bonus: number
    bonuses: { type: string, value: number }[] | null
    action?: string
    startsFrom: number | null
    endsAt: number | null
    link: string | null
    imageLink: string | null
    locale: {
      title: string | null
      description: string | null
    }
  }>
  openModal: Function
}

const TaskSection: FC<TaskSectionProps> = (props) => {

  const passModal = (task: { [key: string]: any}) => {
    props.openModal(task)
  }

  const taskTitle = (type: string, action: string) => {
    return t(`tasks--${type}${action === "" ? "" : ('-' + action)}`)
  }

  return (
    <div className='Tasks--section flex flex-col w-full mt-6 gap-3'>
      <div className='Tasks--sectiontitle flex'>
        <p className='tracking-wider text-sm font-bold uppercase'>
          {t(`tasks${props.section[0].toUpperCase() + props.section.slice(1)}Section`)}
        </p>
        {
          props.section !== "limited" ?
            <div className='ml-1.5 px-1.5 font-semibold text-sm text-white bg-white/10 rounded-2xl'>
              {props.tasks.length}
            </div> : null 
        }
      </div>
      {
        props.tasks.map((task, index) => 
          <TaskItem
            key={index + task.type + props.section}
            type={task.type}
            startsFrom={task.startsFrom}
            endsAt={task.endsAt}
            title={taskTitle(task.type, task.action ?? '')}
            bonus={task.bonus}
            bonuses={task.bonuses}
            status={props.section === 'limited' ? 'time_limited' : props.section}
            onClick={() => passModal(task)}
            locale={task.locale}
            link={task.link}
            imageLink={task.imageLink}
          />
        )
      }
    </div>
  )
}

export default TaskSection;