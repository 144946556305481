import React, {FC} from 'react';
import './ScreenLoader.css';
import { Progress, Spinner } from '@nextui-org/react';
import BottomLayout from "../BottomLayout/BottomLayout";
import Div from "../Div/Div";
import Spacing from "../Spacing/Spacing";
import {useTranslation} from "react-i18next";
import Img from "../Img/Img";

// @ts-ignore
const tg = window["Telegram"]['WebApp'];
import pj from '../../../package.json';
// todo
interface ScreenLoaderProps {
    content: null | 'connection' | 'unSupportPlatform' | 'manyConnections'
    progress: number
}

const ScreenLoader: FC<ScreenLoaderProps> = ({ content, progress }) => {

    const {t} = useTranslation();

    return (
        (content) && (
            <div className="ScreenLoader--container">
                {(content === 'connection' || content === 'manyConnections') && (
                    <>
                        <div className="ScreenLoader--content w-full h-full px-4 py-8 flex flex-col justify-between relative">
                            <div className='flex flex-col'>
                                <div className='w-full h-fit p-16'>
                                    <img className='w-full object-contain' src={require('../../assets/images/preload/crown.png')} alt="" />
                                </div>
                                <p className='w-full text-white font-medium text-4xl text-center'>
                                Public-Sale
                                </p>
                                <div className='w-full mt-10 py-6 relative'>
                                    <p className='font-black text-white text-4xl relative text-center z-[40]'>
                                        1 CBC = 0.05$
                                    </p>
                                    <div className='ScreenLoader--ellipse absolute inset-0'/>
                                </div>
                            </div>
                            <Progress
                                size="md"
                                aria-label="Loading..."
                                classNames={{
                                    base: 'ScreenLoader--bar mb-4',
                                    indicator: 'bg-gradient-to-r from-teal-300 via-white to-violet-700'
                                }}
                                value={progress}
                            />
                            <p className='text-white/5 text-sm bottom-0 inset-x-0 text-center'>
                                v{pj.version}
                            </p>
                        </div>
                    </>
                )}

                {(content === 'unSupportPlatform') && (
                    <>
                        <div className="ScreenLoader--content p-4 h-full" style={{ marginTop: '5vh' }}>
                            <div className='p-6'>
                                <Img className='!rounded-[30px]' src={require('../../assets/images/qr.png')} />
                            </div>
                            <h1 className='text-center'>{t('unSupportPlatform')}</h1>
                        </div>
                    </>
                )}
            </div>
        )
    );
};

export default ScreenLoader;