import { t } from "i18next";
import React, {FC} from "react";
import { formatNumberWithSpaces, formatNumberWithSpacesShort } from "../../../../utils/mathUtils";
import Icon48Users from "../../../../assets/icons/Icon48Users";
import Icon22Premium from "../../../../assets/icons/Icon22Premium";

export interface LeagueProps {
  imgSrc: any
  self: boolean
  name: string
  value: number
  position: number | string
  premium: boolean
}

const LeagueUser: FC<LeagueProps> = ({imgSrc, name, self, value, position, premium}) => {

  const positionShorten = (pos: number | string) => {
    if (typeof pos === 'string') return pos;
    if (pos < 1000) return `${pos}`;
    else if (pos < 10000) return `${Math.round(pos / 1000) * 1000}+`;
    else if (pos < 100000) return `${Math.round(pos / 10000) * 10000}+`;
    else return `100000+`
  }

  return (
    <div className={["LeagueUser--container [&:not(:last-child)]:mb-2 flex w-full p-2 relative items-center gap-3 rounded-2xl", self ? 'self' : 'other'].join(' ')}>
      <div className={[
        "relative w-[60px] h-[60px] rounded-lg",
        premium ? "RainbowOutline" : (imgSrc === null ? 'border-2 border-[#4F3CC8]' : '')
      ].join(' ')}>
        {
          imgSrc === null ?
          <div className="w-[56px] h-[56px] flex items-center justify-center">
            <Icon48Users/> 
          </div> :
          <img width={premium ? 56 : 60} height={premium ? 56 : 60} className="LeagueUser--image object-contain h-full rounded-lg" src={imgSrc} alt="" />
        }
        {
            premium ? 
            <div className='absolute top-[-0.25rem] left-[-0.25rem]'>
                <Icon22Premium />
            </div> : null
        }
      </div>
      <div className="LeagueUser--info flex w-full flex-col gap-1 max-w-[60%]">
        <p className="font-base text-base text-white truncate">
          {name}
        </p>
        <div className="LeagueUser--value font-black text-base text-white">
          {formatNumberWithSpacesShort(value, 3)} CBC
        </div>
      </div>
      <p className="LeagueUser--position text-2xl font-normal pr-2">
        {positionShorten(position)}
      </p>
    </div>
  )
}

export default LeagueUser;