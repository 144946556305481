import React, {FC} from "react";
import './LeagueTitle.css'
import Icon20Question from "../../../assets/icons/Icon20Question";

interface LeagueTitleProps {
  text: string
  league: 'wood' | 'iron' | 'bronze' | 'silver' | 'gold' | 'platinum' | 'diamond' | 'master'
}

const LeagueTitle: FC<LeagueTitleProps> = ({ text, league }) => {

  return (
    <p 
      className={['LeagueTitle text-base flex gap-1.5 items-center', league].join(' ')}
    >
      {text}
      <Icon20Question />
    </p>
  )
}

export default LeagueTitle;