import { t } from "i18next";
import React, {FC} from "react";
import WoodRocket from "../../../../assets/icons/currency/rocket/Wood";
import { formatNumberShort } from "../../../../utils/mathUtils";
import OverflowIcon from '../../../../components/OverflowIcon/OverflowIcon';
import CrystalBase from "../../../../assets/icons/currency/crystal/Base";
import CrystalShadow from "../../../../assets/icons/currency/crystal/Shadow";
import Icon48Users from "../../../../assets/icons/Icon48Users";
import Icon22Premium from "../../../../assets/icons/Icon22Premium";

export interface FriendProps {
  imgSrc: any
  name: string
  gold: number
  level: string
  crystals: number
  premium: boolean
}

const FriendsItem: FC<FriendProps> = ({imgSrc, name, gold, level, crystals, premium}) => {

  return (
    <div className="FriendItem--container [&:not(:last-child)]:mb-2 flex w-full p-2 bg-[#16172F] relative items-center gap-3 rounded-2xl">
      <div className={[
        "relative w-[60px] h-[60px] rounded-lg",
        premium ? "RainbowOutline" : (imgSrc === null ? 'border-2 border-[#4F3CC8]' : '')
      ].join(' ')}>
        {
          imgSrc === null ?
          <div className="w-[56px] h-[56px] flex items-center justify-center">
            <Icon48Users/> 
          </div> :
          <img width={premium ? 56 : 60} height={premium ? 56 : 60} className="LeagueUser--image object-contain h-full rounded-lg" src={imgSrc} alt="" />
        }
        {
            premium ? 
            <div className='absolute top-[-0.25rem] left-[-0.25rem]'>
                <Icon22Premium />
            </div> : null
        }
      </div>
      <div className="FriendItem--info flex w-full flex-col gap-1 max-w-[60%]">
        <p className="font-base text-base text-white truncate">
          {name}
        </p>
        <div className="FriendItem--coins flex gap-1.5 w-full">
          <p className="text-sm font-base capitalize text-white">
            {t(level)}
          </p>
          <p className="FriendItem--coinsdivider">•</p>
          <div className="FriendItem--price flex place-items-center w-fit">
            <WoodRocket height="16px" width="16px" />
            <p className="BuildingItemBox--icon font-extrabold text-xs/4 ml-1">
              +{formatNumberShort(+(gold * 3600).toFixed(3))}/{t('hourShort')}
            </p>
          </div>
        </div>
      </div>
      <div className="FriendItem--crystals flex w-fit gap-1.5 pr-2">
        <OverflowIcon 
          Base={<CrystalBase width='12px' height='20px' />}
          Shadow={<CrystalShadow width='26px' height='23px' />}
          shadowOffsetTop={11}
          shadowOffsetLeft={-7}
          width={12}
          height={20}
        />
        <p className='text-sm font-black'>
          {formatNumberShort(crystals)}
        </p>
      </div>
    </div>
  )
}

export default FriendsItem;