import React, {FC, Key, useEffect, useMemo, useState} from 'react';
import './Buildings.css'
import Panel from "../../components/Panel/Panel";
import LeagueProgress from "../General/LeagueProgress/LeagueProgress"
import {Tabs, Tab} from "@nextui-org/tabs";
import { t } from 'i18next';
import Building from './components/Building/Building';
import Imrpovement, { ImprovementProps } from './components/Improvenent/Improvement';
import { fetchData } from '../../utils/api';
import { REDUCE_GOLD, getDispatchObject, DefaultStateType, SET_BUILDINGS, SET_GOLD_PER_HOUR, MOVE_IMPROVEMENT, SET_LEVEL, SET_ENERGY_LEFT, SET_GOLD_PER_CLICK, SET_DAILY_ENERGY, SET_GOLD, SET_CRYSTALS, REDUCE_CRYSTALS, SET_LIMITED_BUILDINGS, SET_PREMIUM_BUILDINGS, SET_AD_TIMER, ADD_GOLD, ADD_TTC } from '../../store/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_AD, MODAL_LEVEL_UP } from '../../routes';
import useModal from '../../hooks/useModal';
import Icon152Building from '../../assets/icons/Icon152Building';
import { sendAnalytics } from '../../utils/gtag';
import { Button } from '@nextui-org/react';
import Icon24Ad from '../../assets/icons/Icon24Ad';
import dayjs from 'dayjs';
import { AdController } from '../../utils/ads';

const adController = new AdController()

export interface BuildingItem {
  id: number
  imgSrc: any
  name: string
  profitRate: number
  piecePrice: number
  upgradePrice: number
  purchased: number
  premium: boolean
  locked: boolean
  hidden: boolean
  friendsReq: number | null
  achievementReq: number | null
  unlockPrice: number | null
  unlocked: boolean
  startsFrom: string | null
  endsAt: string | null
  asLimited: boolean
}

export interface ImprovementItem {
  id: number
  imgSrc: any
  name: string
  piecePrice: number
  description: string
  requirement: string
  premium: boolean
  buildingCount: number
  buildingId: number
  scalePercent: number
  status: "locked" | "available" | "bought"
}

// @ts-ignore
const tg = window["Telegram"]['WebApp'];

const Home: FC = () => {
  const setTab = (key: Key) => {
    console.log("Switch tab to " + key)
  }

  const {setActiveModal} = useModal()

  const normalBuildings = useSelector((selector: DefaultStateType) => selector.buildings)
  const premiumBuildings = useSelector((selector: DefaultStateType) => selector.premiumBuildings)
  const limitedBuildings = useSelector((selector: DefaultStateType) => selector.limitedBuildings)
  const improvements = useSelector((selector: DefaultStateType) => selector.improvements)
  const selector = useSelector((selector: DefaultStateType) => selector)
  const [txOngoing, setTxOngoing] = useState(false)

  const [lockAd, setLockAd] = useState(true)
  const lastAd = useSelector((s: DefaultStateType) => s.lastAdWatch)
  const [timePassed, setTimePassed] = useState(0)

  const buildingsTop = useMemo(() => {
    if (!normalBuildings || !limitedBuildings) return null
    const buildList = [...Object.values(normalBuildings).filter(item => !item.hidden), ...Object.values(limitedBuildings).filter(item => !item.hidden || item.purchased > 0)].sort((a: any, b: any) => a.profitRate - b.profitRate)
    return buildList
  }, [normalBuildings, limitedBuildings])

  const buildingsAll = useMemo(() => {
    if (!normalBuildings || !limitedBuildings || !premiumBuildings) return null
    return { ...normalBuildings, ...limitedBuildings, ...premiumBuildings }
  }, [normalBuildings, limitedBuildings, premiumBuildings])

  const improvementsAll = useMemo(() => {
    if (!buildingsAll || !improvements) return null
    return Object.values(improvements.available).filter(item => item.buildingId in buildingsAll && buildingsAll[item.buildingId].purchased >= item.buildingCount)
  }, [buildingsAll, improvements])

  const improvementsBought = useMemo(() => {
    if (!improvements) return null
    return Object.values(improvements.bought)
  }, [buildingsAll, improvements])

  const dispatch = useDispatch();
  
  const levelUp = async ( level: { [key: string]: any }) => {
    const tapBonus = (level.goldperclick && selector.goldPerClick) ? (level.goldperclick - selector.goldPerClick) : 1
    const energyBonus = (level.dailyenergy && selector.dailyEnergy) ? (level.dailyenergy - selector.dailyEnergy) : 500
    const newLevel = selector.idToLevelMap ? selector.idToLevelMap[level.level] : 'wood'
    setActiveModal(MODAL_LEVEL_UP, { bonuses: { tap: `+${tapBonus}`, energy: `+${energyBonus}` }, level: newLevel }) // TESTING
    dispatch(getDispatchObject(SET_LEVEL, newLevel));
    dispatch(getDispatchObject(SET_GOLD, level.gold));
    dispatch(getDispatchObject(SET_DAILY_ENERGY, level.dailyenergy));
    dispatch(getDispatchObject(SET_GOLD_PER_CLICK, level.goldperclick));
    if (tg.initDataUnsafe.user.id) {
      sendAnalytics('level_up', {
        level: newLevel,
        character: tg.initDataUnsafe.user.id
      })
    }
  }

  const hideLimited = (building: BuildingItem) => {
    let newBuildings = { ...limitedBuildings, [building.id] : {
      ...building, hidden: true
    }}

    dispatch(getDispatchObject(SET_LIMITED_BUILDINGS, newBuildings));
  }

  const buyBuilding = async (building: BuildingItem) => {
    if (txOngoing) {
      console.log('awaiting transaction end')
      return;
    }
    setTxOngoing(true)
    const response = await fetchData('/buildings/buy_building', {
      buildingId: building.id,
    });

    if (response.error) {
        setTxOngoing(false)
        return;
    }

    const result = response.result;

    if (result) {
      
      if (limitedBuildings && building.id in limitedBuildings) {

        const newLimited = { ...limitedBuildings, [building.id] : {
          ...building, piecePrice: Math.round(building.piecePrice * 1.15), purchased: building.purchased += 1
        }}

        dispatch(getDispatchObject(SET_LIMITED_BUILDINGS, newLimited));
      } 
      else if (building.premium) {

        const newPremium = { ...premiumBuildings, [building.id] : {
          ...building, piecePrice: Math.round(building.piecePrice * 1.15), purchased: building.purchased += 1
        }} as { [key: number | string]: BuildingItem }

        dispatch(getDispatchObject(SET_PREMIUM_BUILDINGS, newPremium));

      }
      else {
        let showIndex: string | null = null
        let unhideIndex: string | null = null
        if (normalBuildings && building.id in normalBuildings) {
          const keys = Object.keys(normalBuildings)
          const currInd = keys.indexOf(String(building.id))
          if ((currInd + 1) < keys.length) showIndex = keys[currInd + 1]
          if ((currInd + 3) < keys.length) unhideIndex = keys[currInd + 3]
        }
  
        const setNextBuildingFlag = (normalBuildings && showIndex && showIndex in normalBuildings)
        const unhideThreeAhead = (normalBuildings && unhideIndex && unhideIndex in normalBuildings)
  
        let newBuildings = { ...normalBuildings, [building.id] : {
          ...building, piecePrice: Math.round(building.piecePrice * 1.15), purchased: building.purchased += 1
        }} as { [key: number | string]: BuildingItem }

        if (setNextBuildingFlag && showIndex) {
          newBuildings[showIndex] = {
            ...newBuildings[showIndex], locked: false
          }
        }
  
        if (unhideThreeAhead  && unhideIndex) {
          newBuildings[unhideIndex] = {
            ...newBuildings[unhideIndex], hidden: false
          }
        }

        dispatch(getDispatchObject(SET_BUILDINGS, newBuildings));
      }

      sendAnalytics('spend_virtual_currency', {
        event_category: 'building',
        value: building.piecePrice,
        virtual_currency_name: building.premium ? 'crystals' : 'coins',
        item_name: building.name
      })

      dispatch(getDispatchObject(SET_GOLD_PER_HOUR, result.goldPerSecond * 3600));
      if (result.renewUserLevel) {
        await levelUp(result.renewUserLevel)
        setTxOngoing(false)
      } else {
        if (building.premium) {
          dispatch(getDispatchObject(REDUCE_CRYSTALS, building['piecePrice']));
        } else {
          dispatch(getDispatchObject(REDUCE_GOLD, building['piecePrice']));
        }
        setTxOngoing(false)
      }

      const showAd = Math.random() < 0.1
      if (showAd) {
        watchAd(false)
      }
    }

  }

  const buyImprovement = async (improv: ImprovementItem) => {
    const response = await fetchData('/buildings/buy_improvement', {
      improvementId: improv.id,
    });

    if (response.error) {
        return;
    }

    const result = response.result;

    if (result) {

      dispatch(getDispatchObject(SET_GOLD_PER_HOUR, result.goldPerSecond * 3600));
      dispatch(getDispatchObject(MOVE_IMPROVEMENT, improv.id))
      sendAnalytics('spend_virtual_currency', {
        event_category: 'improvement',
        value: improv.piecePrice,
        virtual_currency_name: improv.premium ? 'crystals' : 'coins',
        item_name: improv.name
      })
      if (result.renewUserLevel) {
        levelUp(result.renewUserLevel)
      } else {
        if (improv.premium) {
          dispatch(getDispatchObject(REDUCE_CRYSTALS, improv['piecePrice']));
        } else {
          dispatch(getDispatchObject(REDUCE_GOLD, improv['piecePrice']));
        }
      }

      const showAd = Math.random() < 0.2
      if (showAd) {
        watchAd(false)
      }
    }

  }


  useEffect(() => {
    if (!buildingsTop) return
    if (buildingsTop[0].purchased === 0) return
    
    const interval = setInterval(() => {

        const lastAdRewardDate = lastAd ? dayjs(lastAd) : null;

        if (!lastAdRewardDate) {
            setLockAd(false)
        }

        const now = dayjs()
        setTimePassed(now.diff(lastAdRewardDate, 's'))

        if (now.diff(lastAdRewardDate, 's') <= 60) {
            setLockAd(true)
        } else {
            setLockAd(false)
        }

    }, 1000);

    return () => clearInterval(interval);
  }, [lastAd, lockAd, timePassed, buildingsTop]);

  const watchAd = async (onlyClaim: boolean) => {
    const res = await adController.showAd()
    console.log(dayjs().toDate())
    if (res == 'success') {
        try {
            const response = await fetchData('/user/claim_ad_reward')

            const result = response.result
            if (!result) return
            setActiveModal(MODAL_AD, {bonus: result.tokenReward !== 0})
            setLockAd(true)
            dispatch(getDispatchObject(SET_AD_TIMER, dayjs().toDate()))
            if (result.goldReward) dispatch(getDispatchObject(ADD_GOLD, Math.round(result.goldReward)))
            if (result.tokenReward !== 0) dispatch(getDispatchObject(ADD_TTC, result.tokenReward))

        } catch (err) {
            console.log(err)
        }
    }
  }

  return (
    <Panel spaceBetween={false}>
      <LeagueProgress />
      <div className="Buildings--tabs flex w-full flex-col">
        <Tabs 
          aria-label="Options" 
          color="primary" 
          variant="underlined"
          classNames={{
            tabList: "gap-3 w-full relative",
            cursor: "w-full h-1 rounded-md bg-[#4F3CC8]",
            tab: "w-1/2 px-0 py-2 h-12",
            tabContent: "w-full",
            panel: "overflow-y-auto"
          }}
          onSelectionChange={setTab}
        >
          <Tab
            key="buildings"
            title={
              <p className='BuildingsTab--name relative text-xl text-600 group-data-[selected=true]:text-[#4F3CC8]'>
                {t('tabBuildings')}
              </p>
            }
          >
            {
              buildingsTop && buildingsTop.filter(item => !item.hidden && !item.premium).map(item => 
                <Building
                  key={item.name + item.id}
                  imgSrc={item.imgSrc}
                  name={item.locked ? '?????' : item.name}
                  profitRate={item.profitRate}
                  piecePrice={item.piecePrice}
                  purchased={item.purchased}
                  locked={item.locked}
                  premium={item.premium}
                  friendsReq={item.friendsReq}
                  achievementReq={item.achievementReq}
                  unlockPrice={item.unlockPrice}
                  unlocked={item.unlocked}
                  onBuy={() => buyBuilding(item)}
                  endsAt={item.endsAt}
                  onLimitEnded={() => hideLimited(item)}
                />)
            }
            <div className='BuildingsTab--purchased mt-6 mb-3 text-sm uppercase tracking-wider'>
              {t('buildingsPremium')}
            </div>
            {
              premiumBuildings && Object.values(premiumBuildings).filter(item => item.premium).map(item => 
                <Building
                  key={item.name + item.id}
                  imgSrc={item.imgSrc}
                  name={item.locked ? '?????' : item.name}
                  profitRate={item.profitRate}
                  piecePrice={item.piecePrice}
                  purchased={item.purchased}
                  locked={item.locked}
                  premium={item.premium}
                  friendsReq={item.friendsReq}
                  achievementReq={item.achievementReq}
                  unlockPrice={item.unlockPrice}
                  unlocked={item.unlocked}
                  onBuy={() => buyBuilding(item)}
                  endsAt={item.endsAt}
                  onLimitEnded={() => {}}
                />)
            }
            {
              buildingsTop && buildingsTop[0].purchased > 0 ? 
              <div className='sticky bottom-0 inset-x-0 z-10'>
                <div className='flex w-full px-4'>
                  <Button 
                    className='bg-[var(--link\_color)] w-full h-fit rounded-lg py-3 flex gap-2.5 items-center justify-center'
                    onClick={() => watchAd(false)}
                    isDisabled={lockAd}
                  >
                    <Icon24Ad />
                    <div className='font-semibold text-lg'>
                        {t('getBonus')}
                    </div>
                  </Button>
                </div>
              </div> : null
            }
          </Tab>
          <Tab
            key="improvements"
            title={
              <div className='BuildingsTab--name flex flex-row items-center justify-center gap-1 text-xl text-600 group-data-[selected=true]:text-[#4F3CC8]'>
                {t('tabImprovements')}
                {
                  improvementsAll && improvementsAll.length > 0 ? 
                  <p className='rounded-2xl text-xs p-1 min-w-5 text-center text-semibold text-white bg-[--link\_color]'>
                    {improvementsAll.length}
                  </p> : null
                }
              </div>
            }
          >
            {
              improvementsAll && improvementsAll.length > 0 ? improvementsAll.sort((a, b) => {
                return a.premium === b.premium ? a.piecePrice - b.piecePrice : (a.premium ? 1 : -1)
              }).map(item => 
                <Imrpovement
                  key={item.name + item.id}
                  imgSrc={item.imgSrc}
                  name={item.name}
                  piecePrice={item.piecePrice}
                  description={item.description}
                  requirement={item.requirement}
                  premium={item.premium}
                  status={item.status}
                  scalePercent={item.scalePercent}
                  onBuy={() => buyImprovement(item)}
                />) : 
                <div className='flex flex-col gap-2 items-center justify-center'>
                  <Icon152Building />
                  <p className='text-center text-base font-normal text-white'>
                    {t('improvementEmpty')}
                  </p>
                </div>
            }
            {
              improvementsBought && improvementsBought.length > 0 ?
              <div className='BuildingsTab--purchased mt-6 mb-3 text-sm uppercase tracking-wider'>
                {t('improvementPurchased')}
              </div> : null
            }
            {
              improvementsBought && improvementsBought.length > 0 ? improvementsBought.sort((a, b) => {
                return a.premium === b.premium ? a.piecePrice - b.piecePrice : (a.premium ? 1 : -1)
              }).map(item => 
                <Imrpovement 
                  key={item.name + item.id}
                  imgSrc={item.imgSrc}
                  name={item.name}
                  piecePrice={item.piecePrice}
                  description={item.description}
                  requirement={item.requirement}
                  premium={item.premium}
                  status={item.status}
                  scalePercent={item.scalePercent}
                  onBuy={() => {}}
                />) : null
            }
          </Tab>
        </Tabs>
      </div>
    </Panel>
  );
};

export default Home;
