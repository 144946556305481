

import React, {FC} from 'react';


const Icon24Ad: FC = () => {
    return (
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.5982 12.1234L12.1989 16.4426C11.8008 16.761 11.2269 16.7292 10.8664 16.3688L8.13093 13.6334C7.77045 13.273 7.73867 12.6991 8.05713 12.3011L12.3766 6.90206C14.3517 4.43325 17.3421 2.99609 20.5039 2.99609V2.99609C21.0564 2.99609 21.5043 3.444 21.5043 3.99651V3.99651C21.5043 7.15813 20.0671 10.1483 17.5982 12.1234Z" stroke="#FEFCFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.5024 13V17.3838C16.5024 17.7628 16.2882 18.1092 15.9493 18.2786L13.5322 19.4871C13.2728 19.6168 12.9699 19.6276 12.702 19.5166C12.434 19.4056 12.2274 19.1838 12.1357 18.9087L11.5 17.0017" stroke="#FEFCFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.4974 13.0001L5.59034 12.3645C5.3152 12.2728 5.0934 12.0662 4.98241 11.7982C4.87142 11.5303 4.88217 11.2274 5.01187 10.968L6.22044 8.55107C6.3899 8.21216 6.73629 7.99805 7.11522 7.99805H11.4993" stroke="#FEFCFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.24734 20.5909L3.49609 21.0042L3.90942 18.253C4.0435 17.3605 4.74412 16.6599 5.63663 16.5258V16.5258C6.28157 16.4289 6.93424 16.6438 7.39541 17.105C7.85658 17.5661 8.07151 18.2188 7.9746 18.8637V18.8637C7.84049 19.7562 7.13985 20.4568 6.24734 20.5909V20.5909Z" stroke="#FEFCFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    );
};

export default Icon24Ad;