

import React, {FC} from 'react';

const Icon12Crown: FC = () => {
    return (
      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5305_778)">
      <path d="M11.3335 9.99999H2.66683C2.4835 9.99999 2.3335 10.15 2.3335 10.3333V11C2.3335 11.1833 2.4835 11.3333 2.66683 11.3333H11.3335C11.5168 11.3333 11.6668 11.1833 11.6668 11V10.3333C11.6668 10.15 11.5168 9.99999 11.3335 9.99999ZM12.6668 3.33332C12.1147 3.33332 11.6668 3.78124 11.6668 4.33332C11.6668 4.48124 11.7002 4.61874 11.7585 4.74582L10.2502 5.64999C9.92933 5.84166 9.51475 5.73332 9.32933 5.40832L7.63141 2.43749C7.85433 2.25416 8.00016 1.97916 8.00016 1.66666C8.00016 1.11457 7.55225 0.666656 7.00016 0.666656C6.44808 0.666656 6.00016 1.11457 6.00016 1.66666C6.00016 1.97916 6.146 2.25416 6.36891 2.43749L4.671 5.40832C4.48558 5.73332 4.06891 5.84166 3.75016 5.64999L2.24391 4.74582C2.30016 4.62082 2.33558 4.48124 2.33558 4.33332C2.33558 3.78124 1.88766 3.33332 1.33558 3.33332C0.783496 3.33332 0.333496 3.78124 0.333496 4.33332C0.333496 4.88541 0.781413 5.33332 1.3335 5.33332C1.38766 5.33332 1.44183 5.32499 1.49391 5.31666L3.00016 9.33332H11.0002L12.5064 5.31666C12.5585 5.32499 12.6127 5.33332 12.6668 5.33332C13.2189 5.33332 13.6668 4.88541 13.6668 4.33332C13.6668 3.78124 13.2189 3.33332 12.6668 3.33332Z" fill="#4F3CC8"/>
      </g>
      <defs>
      <clipPath id="clip0_5305_778">
      <rect width="13.3333" height="10.6667" fill="white" transform="translate(0.333496 0.666656)"/>
      </clipPath>
      </defs>
      </svg>
    );
};

export default Icon12Crown;
