import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en.json';
import translationENBuildings from './locales/buildings/en.json';
import translationRU from './locales/ru.json';
import translationRUBuildings from './locales/buildings/ru.json';
import translatonsHI from './locales/hi.json'
import translatonsDE from './locales/de.json'
import translatonsUZ from './locales/uz.json'
import translatonsMS from './locales/ms.json'
import translatonsBN from './locales/bn.json'
import translatonsID from './locales/id.json'
import translatonsTR from './locales/tr.json'
import translatonsES from './locales/es.json'
import translatonsFR from './locales/fr.json'
import translatonsVI from './locales/vi.json'
import translatonsPT from './locales/pt.json'
import translatonsAR from './locales/ar.json'
import translatonsFA from './locales/fa.json'

const resources = {
    en: {
        translation: { ...translationEN, ...translationENBuildings },
    },
    ru: {
        translation: { ...translationRU, ...translationRUBuildings },
    },
    hi: {
        translation: { ...translatonsHI}
    },
    de: {
        translation: { ...translatonsDE}
    },
    uz: {
        translation: { ...translatonsUZ}
    },
    ms: {
        translation: { ...translatonsMS}
    },
    bn: {
        translation: { ...translatonsBN}
    },
    id: {
        translation: { ...translatonsID}
    },
    tr: {
        translation: { ...translatonsTR}
    },
    es: {
        translation: { ...translatonsES}
    },
    fr: {
        translation: { ...translatonsFR}
    },
    vi: {
        translation: { ...translatonsVI}
    },
    pt: {
        translation: { ...translatonsPT}
    },
    ar: {
        translation: { ...translatonsAR}
    },
    fa: {
        translation: { ...translatonsFA}
    }
};

// @ts-ignore
const tg = window['Telegram'].WebApp;
const langList = ['en', 'ru', 'hi', 'de', 'uz', 'ms', 'bn', 'id', 'tr', 'es', 'fr', 'vi', 'pt', 'ar', 'fa']
const index = langList.findIndex(item => tg.initDataUnsafe.user.language_code.startsWith(item))
const lang = index !== -1 ? langList[index] : 'en';
console.log('tg code: ', tg.initDataUnsafe.user.language_code);
console.log('lang: ', lang);

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: lang,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
        },
    })
    .then();

export default i18n;