import React, {FC} from 'react';
import IconText from "../../../../../components/IconText/IconText";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {DefaultStateType} from "../../../../../store/reducer";
import {Skeleton} from "@nextui-org/react";
import {formatNumberWithSpacesShort} from "../../../../../utils/mathUtils";
import {ROUTE_LEAGUE} from '../../../../../routes'

interface ScoreBarProps {

}

const ScoreBar: FC<ScoreBarProps> = ({}) => {

    const navigate = useNavigate();

    const {
        level,
        gold,
        rocket
    } = useSelector((selector: DefaultStateType) => ({
        level: selector.level,
        gold: selector.gold,
        rocket: selector.rocket
    }));

    return (
        <>
            {gold !== null ? (
                <IconText
                    onClick={() => navigate(ROUTE_LEAGUE)}
                    size="large"
                    imgPath={require('../../../../../assets/images/coins/coin-wood.png')}
                    text={formatNumberWithSpacesShort(gold, 0)}
                    stretched
                />
            ) : (
                <Skeleton
                    style={{
                        height: 51,
                        width: 200,
                        borderRadius: 16,
                        margin: '0 auto'
                    }}
                />
            )}
        </>
    );
};

export default ScoreBar;