import React, {FC} from 'react';

const Icon48Users: FC = () => {
    return (
      <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.3624 14.8076C12.3687 14.1391 13.133 13.1645 13.5423 12.0279C13.9516 10.8913 13.9841 9.65307 13.635 8.49654C13.2859 7.34002 12.5738 6.32661 11.6039 5.60631C10.6341 4.886 9.4581 4.49707 8.25003 4.49707C7.04197 4.49707 5.86599 4.886 4.89616 5.60631C3.92632 6.32661 3.21414 7.34002 2.86503 8.49654C2.51592 9.65307 2.54843 10.8913 2.95773 12.0279C3.36704 13.1645 4.1314 14.1391 5.13769 14.8076C3.42226 15.4404 1.93964 16.5796 0.886362 18.0741C0.807285 18.1865 0.760577 18.3184 0.751334 18.4554C0.742091 18.5925 0.770667 18.7294 0.833946 18.8514C0.897225 18.9733 0.992775 19.0755 1.11017 19.1468C1.22757 19.2182 1.3623 19.2559 1.49967 19.2559L15.0001 19.2554C15.1375 19.2554 15.2722 19.2177 15.3896 19.1463C15.507 19.075 15.6025 18.9727 15.6658 18.8508C15.729 18.7289 15.7576 18.5919 15.7483 18.4548C15.7391 18.3178 15.6924 18.1859 15.6133 18.0736C14.56 16.5793 13.0776 15.4403 11.3624 14.8076Z" fill="#4F3CC8"/>
        <path d="M23.256 18.0736C22.2027 16.5793 20.7202 15.4403 19.005 14.8076C20.1202 14.0652 20.9338 12.9492 21.2995 11.6604C21.6652 10.3716 21.5589 8.99465 20.9997 7.77725C20.4405 6.55985 19.4652 5.58198 18.2493 5.01962C17.0334 4.45725 15.6567 4.3473 14.3669 4.70957C14.2498 4.7425 14.1425 4.80341 14.0542 4.88706C13.9659 4.97071 13.8993 5.07457 13.86 5.18971C13.8208 5.30484 13.8102 5.42779 13.8291 5.54795C13.8479 5.6681 13.8958 5.78187 13.9684 5.87943C14.8162 7.0183 15.3036 8.38495 15.3678 9.8033C15.432 11.2217 15.07 12.6267 14.3285 13.8375C14.2305 13.9972 14.1959 14.1877 14.2314 14.3717C14.2669 14.5556 14.3699 14.7196 14.5202 14.8314C14.8045 15.043 15.0781 15.2687 15.3399 15.5077C15.3534 15.5224 15.3671 15.5371 15.382 15.5509C16.383 16.4763 17.1943 17.5878 17.7706 18.8232C17.831 18.9526 17.927 19.062 18.0474 19.1386C18.1678 19.2152 18.3075 19.2559 18.4503 19.2559L22.6428 19.2554C22.7802 19.2554 22.9149 19.2177 23.0323 19.1463C23.1497 19.075 23.2452 18.9727 23.3085 18.8508C23.3717 18.7289 23.4003 18.5919 23.391 18.4549C23.3818 18.3178 23.335 18.1859 23.256 18.0736L23.256 18.0736Z" fill="#4F3CC8"/>
      </svg> 
    );
};

export default Icon48Users;