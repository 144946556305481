import {useDispatch, useSelector} from "react-redux";
import {getDispatchObject, SET_ACTIVE_SNACKBAR, SET_ACTIVE_SNACKBAR_PARAMS} from "../store/reducer";

const useSnackbar = () => {

    const selector: any = useSelector((s) => s);
    const dispatch = useDispatch();

    const activeSnackbar = selector['activeSnackbar'];
    const activeSnackbarParams = selector['activeSnackbarParams'];

    const setActiveSnackbar = (modalId: string | null, params?: string | number | object) => {
      console.log('Snackbar ' + params)
        dispatch(getDispatchObject(SET_ACTIVE_SNACKBAR, modalId));

        if (params) {
            dispatch(getDispatchObject(SET_ACTIVE_SNACKBAR_PARAMS, params));
        }

        if (!params) {
            dispatch(getDispatchObject(SET_ACTIVE_SNACKBAR_PARAMS, null));
        }
    }

    return { activeSnackbar, setActiveSnackbar, activeSnackbarParams };

};

export default useSnackbar;