import { THEME, TonConnectUI, Wallet } from "@tonconnect/ui";

export const tonConnectUI = new TonConnectUI({
  manifestUrl: `${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : 'https://127.0.0.1:10888'}/tonconnect-manifest.json`,
});

export let wallet: Wallet | null = null

export const setWallet = (w: Wallet | null) => {
  wallet = w
}

tonConnectUI.uiOptions = {
  uiPreferences: {
      theme: THEME.DARK
  }
}

tonConnectUI.onStatusChange(
  walletAndwalletInfo => {
    console.log('wallet status changed to' + walletAndwalletInfo)
    setWallet(walletAndwalletInfo)
  } 
);