import React, {FC, useMemo} from 'react';
import { colorMapping } from './colors';

interface TapAchProps {
  level: number
  height?: number
}

const TapAchievement: FC<TapAchProps> = ({ level, height = 96 }) => {
  
    const colorScheme = useMemo(() => {
      return level in colorMapping ? colorMapping[level] : colorMapping[0]
    }, [level])
  
    return (
      <svg width="100%" height={height} viewBox="4 0 100 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g 
          clipPath={`url(#clip0_dd_5859_13851_${level})`}
          filter={`url(#filter0_dd_5859_13851_${level})`}
        >
          <path fillRule="evenodd" clipRule="evenodd" d="M72.0315 39.6427C74.6459 39.336 77.2226 38.3866 79.5194 36.7539C86.8161 31.567 88.5263 21.4471 83.3394 14.1505C78.1525 6.85385 68.0326 5.14357 60.736 10.3305C55.6018 13.9802 53.2335 20.0722 54.0876 25.9045L56.8695 23.927C56.7493 19.6516 58.6958 15.3968 62.4436 12.7326C68.4136 8.48877 76.6935 9.88809 80.9373 15.8581C85.1811 21.828 83.7818 30.1079 77.8119 34.3518C75.5749 35.9419 73.0137 36.7398 70.462 36.8018C70.6346 36.9967 70.7985 37.2027 70.9527 37.4196C71.4486 38.1172 71.8062 38.8687 72.0315 39.6427ZM68.5127 33.7302C71.0854 34.1382 73.8135 33.5781 76.1043 31.9496C80.7476 28.6489 81.8359 22.2089 78.5352 17.5656C75.2344 12.9223 68.7945 11.834 64.1512 15.1347C61.8604 16.7632 60.4349 19.1557 59.9748 21.7196L65.8564 17.5385C69.1523 15.1956 73.7754 15.9566 76.133 19.2732C78.5072 22.613 77.7123 27.1906 74.3943 29.5492L68.5127 33.7302ZM67.5307 45.2763C69.5086 43.8702 69.9751 41.1311 68.5506 39.1272C67.136 37.1372 64.3649 36.6787 62.4079 38.0699L57.7439 41.3854L51.5765 45.7695L49.8689 43.3674L55.8742 39.0984L72.6684 27.1601C74.6698 25.7374 75.1554 22.9847 73.7309 20.9808C72.3163 18.9908 69.5687 18.5156 67.5456 19.9537L42.8284 37.5243C44.5992 28.8223 45.782 18.1823 41.2033 17.4212C36.7213 16.6761 34.27 28.5489 25.1383 49.186C21.024 58.4844 19.9064 67.3435 26.3253 76.3756C33.3984 86.3255 47.1982 88.6577 57.1482 81.5847L64.9628 76.0295L76.3498 67.9349C78.3483 66.5143 78.8315 63.7633 77.407 61.7594C75.9924 59.7694 73.2207 59.3113 71.2271 60.7285L65.8383 64.5592L65.2371 64.9866L63.5296 62.5844L69.1069 58.6197L71.958 56.593C73.9364 55.1866 74.4033 52.4472 72.9788 50.4433C71.5642 48.4533 68.8259 47.9715 66.8352 49.3866L64.3924 51.1231L60.8089 53.6705L59.1013 51.2683L62.6848 48.721L67.5307 45.2763Z" 
            fill={`url(#paint0_linear_5859_13851_${level})`}
            stroke={`url(#paint1_linear_5859_13851_${level})`} 
            strokeWidth="2"
          />
        </g>
      <defs>
        <filter id={`filter0_dd_5859_13851_${level}`} x="-10" y="-2" width="124" height="112" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="8"/>
          <feGaussianBlur stdDeviation="5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.00392157 0 0 0 0 0.0196078 0 0 0 0 0.2 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5859_13716"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="8"/>
          <feGaussianBlur stdDeviation="5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.00392157 0 0 0 0 0.0196078 0 0 0 0 0.2 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="effect1_dropShadow_5859_13716" result="effect2_dropShadow_5859_13716"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_5859_13716" result="shape"/>
        </filter>
        <linearGradient id={`paint0_linear_5859_13851_${level}`} x1="95.5" y1="26" x2="18.8309" y2="57.8119" gradientUnits="userSpaceOnUse">
          <stop stopColor={colorScheme.mainGrad.from}/>
          <stop offset="0.38" stopColor={colorScheme.mainGrad.middle}/>
          <stop offset="1" stopColor={colorScheme.mainGrad.to}/>
        </linearGradient>
        <linearGradient id={`paint1_linear_5859_13851_${level}`} x1="88.3639" y1="21.2186" x2="21.1043" y2="69.0309" gradientUnits="userSpaceOnUse">
          <stop stopColor={colorScheme.secGrad.from}/>
          <stop offset="1" stopColor={colorScheme.secGrad.to}/>
        </linearGradient>
        <clipPath id={`clip0_dd_5859_13851_${level}`}>
          <rect width="100%" height="92" fill="white"/>
        </clipPath>
      </defs>
      </svg>
    )
}

export default TapAchievement;
