import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/react';
import React, {FC} from 'react';
import {MODAL_BUY_CRYSTALS, MODAL_DONATE} from "../routes";
import useModal from "../hooks/useModal";
import {useTranslation} from "react-i18next";
import './Modals.css'
import OverflowIcon from '../components/OverflowIcon/OverflowIcon';
import CrystalBase from '../assets/icons/currency/crystal/Base';
import CrystalShadow from '../assets/icons/currency/crystal/Shadow';
import { t } from 'i18next';
import { donations } from '../utils/utils';

const BuyOptionCell: FC<{id: string, amount: number, price: number, pack: string, discount: number | null, original: number | null, status: string | null}> = (props) => {

  const {setActiveModal} = useModal();

  const buyCrystals = () => {
    setActiveModal(MODAL_BUY_CRYSTALS, {
      donation: props.id,
      amount: props.amount,
      price: props.price,
      pack: props.pack,
      discount: props.discount,
      original: props.original
    })
  }

  return (
    <div 
      key={props.id + 'donate'}
      className={
        ['ModalDonate--cellwrap border-solid rounded-lg relative',
        props.status !== null ? 'border-2 border-[#4F3CC8]' : 'border-1 border-[#FFFFFF14]'
        ].join(' ')
      }
    >
      {
        props.status !== null ?
        <div className="absolute w-full inset-x-0 top-[-12px] flex justify-center items-center">
          <p className='text-sm px-1.5 font-semibold tracking-wider w-fit bg-[#4F3CC8] rounded-xl'>
            {t(props.status === 'fav' ? 'donateFavourable' : 'donatePopular')}
          </p>
        </div> : null
      }
      <div
        key={props.id + props.amount}
        className={[
          'flex flex-col items-center justify-center py-4 px-2 rounded-xl',
          props.status !== null ? (props.status === 'fav' ? 'ModalTexturedBg before:inset-0' : 'ModalPentagonBg before:inset-0') : null
        ].join(' ')}
      >
        <img width={84} height={84} className='object-contain' src={require(`../assets/images/donate/${props.id}.png`)} alt="" />
        <div className='flex flex-row gap-1 mt-2'>
          <p className='ModalDonate--amount text-base font-black'>
            {props.amount}
          </p>
          <OverflowIcon
            Base={<CrystalBase width='12px' height='20px' />}
            Shadow={<CrystalShadow width='26px' height='23px' />}
            shadowOffsetTop={11}
            shadowOffsetLeft={-7}
            width={12}
            height={20}
          />
        </div>
        {
          props.discount ?
          <div className='bg-[#16C784] px-1 text-[8px]/3 tracking-widest font-black text-black uppercase'>
            {t('donateSave')} {props.discount}%
          </div> :
          <div className='h-3 w-full'/>
        }
        <Button
          fullWidth
          className='bg-[var(--link\_color)] opacity-100 rounded-xl text-lg py-2 px-3 mt-2 h-fit flex items-baseline gap-1 z-3'
          onClick={buyCrystals}
        >
            <p className='text-base font-semibold text-white'>
              ${props.price}
            </p>
            {
              props.original ?
              <p className='text-xs text-[#AAAAAA] strike-through'>
                ${props.original}
              </p> : null
            }
        </Button>
      </div>
    </div>
  )
}

const DonateModal: FC = () => {

    const { t } = useTranslation();

    const {activeModal, setActiveModal, activeModalParams} = useModal();


    return (
        <Modal
            isOpen={activeModal === MODAL_DONATE}
            placement='bottom'
            className='bg-[#19192B] max-h-[70%]'
            scrollBehavior='inside'
            onClose={() => setActiveModal(null)}
        >
            <ModalContent className='ModalDonate--ellipse'>
                {(onClose) => (
                    <>
                      <ModalHeader></ModalHeader>
                      <ModalBody className='px-6 z-1'>
                        <div className='flex flex-col items-center justify-between relative gap-1.5'>
                          <p className='text-3xl font-bold text-white'>
                            {t('donateBuyingCrystals')}
                          </p>
                          <p className='text-base font-normal text-white/95 text-center'>
                            {t('donateDescription')}
                          </p>
                        </div>
                        <div className='flex w-full flex-wrap gap-x-3 gap-y-6 mt-6'>
                          {
                            donations.map(item =>
                              BuyOptionCell({
                               ...item
                              })
                            )
                          }
                        </div>
                      </ModalBody>
                      <ModalFooter className='px-6 mb-4 z-1'>
                          <Button
                              fullWidth
                              className='bg-[var(--link\_color)] opacity-100 rounded-lg py-4 text-lg h-fit'
                              onPress={onClose}
                          >
                              {t('modalClose')}
                          </Button>
                      </ModalFooter>
                  </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default DonateModal;
