import React, {FC, ReactElement} from "react";

interface OverflowIconProps {
  Base: React.ReactNode
  Shadow: React.ReactNode
  shadowOffsetTop: number
  shadowOffsetLeft: number
  width: number
  height: number
}

const OverflowIcon: FC<OverflowIconProps> = ({ Base, Shadow, shadowOffsetTop, shadowOffsetLeft, height, width }) => {
  return (
    <div className="OverflowIcon--container relative">
      <div className="OverflowIcon--placeholder" style={{height: `${height}px`, width: `${width}px`}}/>
      <div 
        className="OverflowIcon--shadow absolute z-0"
        style={{top: `${shadowOffsetTop}px`, left: `${shadowOffsetLeft}px`}}
      >
        {Shadow}
      </div>
      <div 
        className="OverflowIcon--placeholder absolute top-0 left-0 z-1"
      >
        {Base}
      </div>
    </div>
  )
}

export default OverflowIcon;