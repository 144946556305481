import React, {FC, HTMLAttributes, useState, useEffect, useMemo} from 'react';
import Panel from "../../components/Panel/Panel";
import TelegramBackButton from "../../components/TelegramBackButton/TelegramBackButton";
import {useDispatch, useSelector} from "react-redux";
import {DefaultStateType, SET_LEVELS, SET_MY_POSITION, SET_PRIZEPOOL, SET_PRIZEPOOL_WINNERS, getDispatchObject} from "../../store/reducer";
import LeagueInfo from '../General/LeagueInfo/LeagueInfo';
import Icon24ChevronRight from '../../assets/icons/Icon24ChevronRight';
import Icon24ChevronLeft from '../../assets/icons/Icon24ChevronLeft';
import './League.css'
import { t } from 'i18next';
import { formatNumberWithSpaces, formatNumberWithSpacesShort } from '../../utils/mathUtils';
import LeagueUser from './components/LeagueUser/LeagueUser';
import { fetchData, getTelegramImageUrl } from '../../utils/api';
import LeagueValue from '../../components/Leagues/Value/LeagueValue';
import LeagueTitle from '../../components/Leagues/Title/LeagueTitle';
import LeagueCoin from '../../components/Leagues/Coin/LeagueCoin';
import { LEAGUES, LEAGUE_NAMES } from '../../utils/levelsUtils';
import { getTimeLeft } from '../../utils/utils';
import { MODAL_BANK } from '../../routes';
import useModal from '../../hooks/useModal';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const League: FC = ({}) => {

    const selector = useSelector((s: DefaultStateType) => s)
    const prizepool = useSelector((selector: DefaultStateType) => selector.prizepool)
    const prizepoolWinners = useSelector((selector: DefaultStateType) => selector.prizepoolWinners)
    const myPosition = useSelector((selector: DefaultStateType) => selector.myPosition)
    const level = useSelector((selector: DefaultStateType) => selector.level)
    const levelMap = useSelector((selector: DefaultStateType) => selector.idToLevelMap)
    const premium = useSelector((selector: DefaultStateType) => selector.premium)
    const [nextTime, setNextTime] = useState(() => {
        const next = dayjs()
        const utc = dayjs(Date.UTC(next.year(), next.month(), next.date(), 14, 0, 0, 0))
        if (utc <= next) return dayjs(utc.unix() * 1000 + 24*60*60*1000).unix()
        return utc.unix()
    })
    const [countDown, setCountdown] = useState(getTimeLeft(nextTime, 'hours'))
    const [showLeague, setShowLeague] = useState<LEAGUE_NAMES>(level ?? 'wood')
    const {setActiveModal} = useModal()
    const dispatch = useDispatch()

    const getPos = useMemo(() => {
        if (!prizepoolWinners || !myPosition) return '100+'
        const myLeague = prizepoolWinners[myPosition.league]
        const pos = myLeague.findIndex(item => String(item.tg_id) == String(myPosition.id))
        if (pos !== -1) return pos + 1
        else return '100+'
        
    }, [myPosition, prizepoolWinners])

    const refetcher = async () => {
        let levelmapLocal = {}
        if (!levelMap) {
            const response = await fetchData('/misc/get_levels');
            levelmapLocal = response.result.reduce((res: any, item: any) => ({ ...res, [item.level]: item.levelName}), {})
        } else {
            levelmapLocal = {...levelMap}
        }
        await fetchPrizepools()
        await fetchMyLastWinnings(levelmapLocal)
        await fetchPrizepoolWinners(levelmapLocal)
    }

    useEffect(() => {
        refetcher()
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            const timeLeft = getTimeLeft(nextTime, 'hours')
                if (timeLeft === '0:00:00') {
                setNextTime(value => value + 24*60*60)
                setCountdown(timeLeft)
            } else {
                setCountdown(timeLeft)
            }
        }, 1000);
    
        return () => clearInterval(interval);
    }, [nextTime]);

    const showPrevious = () => {
        if (!levelMap) return
        const pos = Object.values(levelMap).indexOf(showLeague)
        setShowLeague(Object.values(levelMap)[pos === 0 ? Object.values(levelMap).length - 1: pos - 1])
    }

    const showNext = () => {
        if (!levelMap) return
        const pos = Object.values(levelMap).indexOf(showLeague)
        setShowLeague(Object.values(levelMap)[pos === Object.values(levelMap).length - 1 ? 0: pos + 1])
    }

    const fetchPrizepools = async () => {
        const response = await fetchData('/prizepool/get_prizepool');
        if (response.error) return

        const result = response.result.map((item: any) => {
            return {
                league: item.levelName,
                value: item.prizePool ?? 0
            }
        })

        dispatch(getDispatchObject(SET_PRIZEPOOL, result.reduce((res: any, item: any) => ({ ...res, [item.league]: item.value}), {})))
    }

    const fetchPrizepoolWinners = async (levels: { [key: string]: string}) => {
        const response = await fetchData('/prizepool/get_top_winners');
        if (response.error) return

        const result = {} as { [key: string] : any}
        for (const [key, value] of Object.entries(response.result as { [key: string] : any})) {
            const levelName = levels[key]
            result[levelName] = value.map((item: any, index: number) => {
                return {
                    position: index + 1,
                    name: (item.first_name ? (item.first_name) : `Anonymous`) + (item.last_name && item.last_name !== ' ' ? (" " + item.last_name) : ""),
                    tg_id: item.user_id,
                    reward: Number(item.total_reward),
                    photo: item.file_path ? ((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : '') + (item.file_path)) : null,
                    premium: item.is_premium ?? false
                }
            })
        }

        dispatch(getDispatchObject(SET_PRIZEPOOL_WINNERS, result))
    }

    const fetchMyLastWinnings = async (levels: { [key: string]: string}) => {
        const response = await fetchData('/prizepool/get_my_position_in_top');
        if (response.error) return

        const result = response.result
        dispatch(getDispatchObject(SET_MY_POSITION, {
            id: result.userId,
            league: levels[result.userLevel],
            position: result.userPosition,
            profit: Number(result.reward),
            name: result.firstName ?? "Anonymous",
            photo: result.avatar ? ((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : '') + result.avatar) : null
        }))
    }

    return (
        <Panel scrollable={true} spaceBetween={false} padTop={false}>
            <TelegramBackButton />

            {selector.level !== null && selector.gold !== null && (
                <div className='League--header flex flex-col gap-4 w-full relative'>
                    <div className='League--name flex flex-col gap-3 w-full pt-4'>
                        <div className='League--selector flex flex-row items-center justify-between h-28 relative'>
                            <div className={['League--ellipse absolute left-0 right-0 top-[-10px] bottom-[-10px] z-0', showLeague].join(' ')}/>
                            <div className='p-[5px]' onClick={showPrevious}>
                                <Icon24ChevronLeft />
                            </div>
                            <LeagueCoin 
                                league={showLeague}
                                touchable={false}
                                onTouch={() => {}}
                                className='League--coin w-28 h-28'
                            />
                            <div className='p-[5px]' onClick={showNext}>
                                <Icon24ChevronRight />
                            </div>
                        </div>
                        <p className='font-bold text-xl capitalize text-center'>
                            {t(showLeague)}
                        </p>
                    </div>
                    <div 
                        className={['League--bankbox rounded-2xl pt-4 pb-2 px-3 gap-2 flex flex-col items-center justify-center border-[1px] border-solid', showLeague].join(' ')}
                        onClick={() => setActiveModal(MODAL_BANK)}
                    >
                        <LeagueTitle 
                            league={showLeague}
                            text={t('leagueBankTitle')}
                        />
                        <LeagueValue 
                            league={showLeague}
                            text={`${formatNumberWithSpacesShort(Math.round(prizepool ? prizepool[showLeague] : 0))} CBC`}
                        />
                        <div className='bg-white/10 px-1.5 text-base font-semibold rounded-xl tracking-wider'>
                            {countDown}
                        </div>
                        <p className='League--bankboxsub text-sm text-center'>
                            {t('leagueBoxSubtitle')}
                        </p>
                    </div>
                    <LeagueInfo />
                    { myPosition && myPosition.league === showLeague ? <div className="League--self sticky top-0 bg-[rgb(8, 10, 33)] z-3">
                        <LeagueUser 
                            imgSrc={myPosition.photo}
                            name={myPosition.name}
                            value={myPosition.profit}
                            position={getPos}
                            self={true}
                            premium={premium && premium.active ? true : false}
                        />
                    </div> : null}
                    <div className='League--users flex flex-col w-full'>
                        {prizepoolWinners ? prizepoolWinners[showLeague].map((item: any) => 
                            <LeagueUser
                                imgSrc={item.photo}
                                name={item.name}
                                value={item.reward}
                                position={item.position}
                                self={false}
                                premium={item.premium}
                            />
                        ): null}
                    </div>
                </div>
            )}
        </Panel>
    );
};

export default League;