import { useEffect, useRef, useState } from 'react'

export function preloadImage (src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function() {
      resolve(img)
    }
    img.onerror = img.onabort = function() {
      reject(src)
    }
    img.src = src
  })
}

export async function preloadBatch(src: string[]) {
  const imagesPromiseList: Promise<any>[] = []
  for (const i of src) {
    imagesPromiseList.push(preloadImage(i))
  }

  await Promise.all(imagesPromiseList)
}

export default function useImagePreloader(imageList: string[]) {
  const preloadCalled = useRef(false)
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false)

  useEffect(() => {
    if (preloadCalled.current) return
    preloadCalled.current = true

    let isCancelled = false

    async function effect() {
      console.log('PRELOAD')

      if (isCancelled) {
        return
      }

      const imagesPromiseList: Promise<any>[] = []
      for (const i of imageList) {
        imagesPromiseList.push(preloadImage(i))
      }
  
      await Promise.all(imagesPromiseList)

      if (isCancelled) {
        return
      }

      setImagesPreloaded(true)
    }

    effect()

    return () => {
      isCancelled = true
    }
  }, [imageList, preloadCalled])

  return { imagesPreloaded }
}