import React, {FC, useMemo} from "react";
import {DefaultStateType} from "../../../../../store/reducer";
import IconText from "../../../../../components/IconText/IconText";
import "./ProfitCells.css"
import {formatNumberWithSpacesShort} from "../../../../../utils/mathUtils";
import OverflowIcon from "../../../../../components/OverflowIcon/OverflowIcon";
import CrystalBase from "../../../../../assets/icons/currency/crystal/Base";
import CrystalShadow from "../../../../../assets/icons/currency/crystal/Shadow";
import Icon28Plus from "../../../../../assets/icons/Icon28Plus"; 
import Icon20Question from "../../../../../assets/icons/Icon20Question";
import WoodRocket from "../../../../../assets/icons/currency/rocket/Wood";
import {useSelector} from "react-redux";
import { MODAL_BUY_BUILDINGS, MODAL_DONATE } from "../../../../../routes";
import useModal from "../../../../../hooks/useModal";
import { t } from "i18next";

const ProfitCells: FC = () => {

  const crystals = useSelector((selector: DefaultStateType) => selector.crystals)
  const goldPerHour = useSelector((selector: DefaultStateType) => selector.goldPerHour)

  const displayProfit = useMemo(() => {
    if (!goldPerHour) return '0'
    else if (goldPerHour < 1_000_000) return formatNumberWithSpacesShort(goldPerHour)
    else return Intl.NumberFormat('en-US', {
      notation: "compact",
      maximumFractionDigits: 3
    }).format(goldPerHour)
  }, [goldPerHour])
  const { setActiveModal } = useModal()
  return (
    <div className="ProfitCells--container mb-4 flex gap-2">
      <div 
        className="ProfitCell--container flex flex-col gap-1 justify-between rounded-2xl p-3 w-1/2"
        onClick={() => setActiveModal(MODAL_BUY_BUILDINGS)}
      >
        <div className="ProfitCell--title flex place-items-center w-full justify-between">
          <p className="ProfitCell--text font-normal text-nowrap">
            {t('profitPerHour')}
          </p>
          <Icon20Question />
        </div>
        <div className="ProfitCell--counter flex place-items-center w-full gap-1.5">
          <WoodRocket height="20px" width="20px" />
          <p className="ProfitCell--icon font-extrabold">
            +{displayProfit}
          </p>
        </div>
      </div>
      <div className="CurrencyCell--container flex justify-between rounded-2xl p-4 w-1/2">
        <div className="CurrencyCell--crystall flex place-items-center w-fit gap-3">
          <OverflowIcon 
            Base={<CrystalBase width='24px' height='36px' />}
            Shadow={<CrystalShadow width='51px' height='44px' />}
            shadowOffsetTop={22}
            shadowOffsetLeft={-14}
            width={24}
            height={40}
          />
          <p className="CurrencyCell--value font-extrabold">
            {formatNumberWithSpacesShort(crystals ?? 0, 1)}
          </p>
        </div>
        <div 
          className="CurrencyCell--add p-2 rounded-lg"
          onClick={() => setActiveModal(MODAL_DONATE)}
        >
          <Icon28Plus />
        </div>
      </div>
    </div>
  )
}

export default ProfitCells;