import { t } from "i18next";
import React, {FC, useMemo} from "react";
import WoodRocket from "../../../../assets/icons/currency/rocket/Wood";
import { formatNumberShort } from "../../../../utils/mathUtils";
import OverflowIcon from '../../../../components/OverflowIcon/OverflowIcon';
import CrystalBase from "../../../../assets/icons/currency/crystal/Base";
import CrystalShadow from "../../../../assets/icons/currency/crystal/Shadow";
import Icon24ChevronRight from "../../../../assets/icons/Icon24ChevronRight";
import { useSelector } from "react-redux";
import { DefaultStateType } from "../../../../store/reducer";

export interface BoostProps {
  id: string
  price: number
  isPremium: boolean
  level: number
  available: boolean
  running?: string
  onOpenEvent: React.MouseEventHandler
}

const BoostItem: FC<BoostProps> = ({id, price, level, isPremium, available, running, onOpenEvent}) => {

  const goldPerHour = useSelector((s: DefaultStateType) => s.goldPerHour)

  const showProfit = useMemo(() => {
    if (!goldPerHour) return null
    if (id === 'exchange10') return 10 * goldPerHour
    if (id === 'exchange24') return 24 * goldPerHour
    return null 
  }, [goldPerHour])

  return (
    <div 
      className="BoostItem--container [&:not(:last-child)]:mb-2 flex w-full py-2 pl-3 pr-4 bg-[#16172F] items-center gap-3 rounded-2xl"
      onClick={onOpenEvent}
    >
      <img width={60} height={60} className="BoostItem--image object-contain h-full rounded-lg"
        src={require(`../../../../assets/images/boosts/${id}.png`)} alt=""
      />
      <div className="BoostItem--info flex w-full flex-col gap-1">
        <p className="font-base text-base text-white">
          {t(`boostName--${id}`)}
        </p>
        {
            showProfit ? 
            <div className="BoostItem--coins flex gap-1.5 items-center w-full">
              <div className="w-1/2 flex flex-col">
                <p className="text-xs text-[var(--secondary\_hint)] font-black text-nowrap">
                  {t('boostsYouGet')}
                </p>
                <div className="flex flex-row w-fit items-center h-5">
                  <WoodRocket height="16px" width="16px" />
                  <p className="BoostItem--icon font-extrabold text-xs/4 ml-1">
                    {formatNumberShort(Math.ceil(showProfit))}
                  </p>
                </div>
              </div>
              <div className="BuildingItemBox--full border-l-1 border-slate-700/[.85] pl-4 h-9 flex flex-col"/>
              <div className="w-1/2 flex flex-col">
                <p className="text-xs text-[var(--secondary\_hint)] font-black">
                  {t('boostsPrice')}
                </p>
                <div className="flex flex-row w-fit items-center">
                  <OverflowIcon 
                    Base={<CrystalBase width='12px' height='20px' />}
                    Shadow={<CrystalShadow width='26px' height='23px' />}
                    shadowOffsetTop={11}
                    shadowOffsetLeft={-7}
                    width={12}
                    height={20}
                  />
                  <p className="BoostItem--icon font-extrabold text-xs/4 ml-1">
                    {formatNumberShort(Math.ceil(price))}
                  </p>
                </div>
              </div>
            </div> : 
            <div className="BoostItem--coins flex gap-1.5 items-center w-full">
              <div className="BoostItem--price flex place-items-center w-fit">
              {
                  isPremium ?
                  <OverflowIcon 
                    Base={<CrystalBase width='12px' height='20px' />}
                    Shadow={<CrystalShadow width='26px' height='23px' />}
                    shadowOffsetTop={11}
                    shadowOffsetLeft={-7}
                    width={12}
                    height={20}
                  /> :
                  <WoodRocket height="16px" width="16px" /> 
                }
                <p className="BoostItem--icon font-extrabold text-xs/4 ml-1">
                  {formatNumberShort(Math.ceil(price))}
                </p>
              </div>
              { level || !available ? <p className="BoostItem--divider">•</p> : null }
              {
                !available ? <p className="text-sm text-red-600 font-normal">{t('boostsInsufficientFunds')}</p> : (
                  level ?
                  <p className="BoostItem--level text-sm font-base">
                    {level}{' ' + t('boostsLevel')}
                  </p> : null
                )
              }
            </div>
          }
      </div>
      <div className="BoostItem--crystals flex w-fit gap-1.5">
        {
          running ? <div className="bg-[#4F3CC8] px-1.5 text-base font-semibold rounded-xl tracking-wider">
              {running}
            </div> : <Icon24ChevronRight />
        }
      </div>
    </div>
  )
}

export default BoostItem;