import React, {FC} from 'react'

const Icon20Question: FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.99997 16.5714C13.6293 16.5714 16.5714 13.6293 16.5714 9.99997C16.5714 6.37067 13.6293 3.42854 9.99997 3.42854C6.37067 3.42854 3.42854 6.37067 3.42854 9.99997C3.42854 13.6293 6.37067 16.5714 9.99997 16.5714ZM9.99997 17.8571C14.3393 17.8571 17.8571 14.3393 17.8571 9.99997C17.8571 5.66058 14.3393 2.14282 9.99997 2.14282C5.66058 2.14282 2.14282 5.66058 2.14282 9.99997C2.14282 14.3393 5.66058 17.8571 9.99997 17.8571Z" fill="#4B526A"/>
      <path d="M10.8928 13.3928C10.8928 13.8859 10.4931 14.2857 9.99997 14.2857C9.50685 14.2857 9.10711 13.8859 9.10711 13.3928C9.10711 12.8997 9.50685 12.5 9.99997 12.5C10.4931 12.5 10.8928 12.8997 10.8928 13.3928Z" fill="#4B526A"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.0072 7.14282C9.19932 7.14282 8.94249 7.65169 8.90704 7.79292C8.811 8.17553 8.42297 8.40785 8.04035 8.31181C7.65773 8.21576 7.42541 7.82773 7.52146 7.44511C7.68523 6.79268 8.4245 5.71425 10.0072 5.71425C11.59 5.71425 12.3292 6.79268 12.493 7.44511C12.6551 8.09084 12.6019 8.65592 12.3886 9.14268C12.1823 9.61348 11.8509 9.95399 11.5547 10.2038C11.4058 10.3293 11.2562 10.4397 11.1281 10.5327C11.1035 10.5505 11.08 10.5675 11.0576 10.5837C10.9561 10.6572 10.8757 10.7154 10.8039 10.7725C10.7584 10.8088 10.7277 10.836 10.7076 10.8552C10.6422 11.1822 10.3535 11.4285 10.0072 11.4285C9.61274 11.4285 9.29294 11.1087 9.29294 10.7143C9.29294 10.4207 9.41409 10.1891 9.5349 10.029C9.65121 9.87482 9.79469 9.75007 9.91371 9.65523C10.014 9.5753 10.1276 9.49321 10.2297 9.4194C10.25 9.40477 10.2698 9.39046 10.2889 9.37656C10.4125 9.28684 10.5268 9.20185 10.6337 9.11166C10.8493 8.92987 10.9979 8.75699 11.0801 8.5693C11.1554 8.39758 11.1993 8.15909 11.1074 7.79292C11.072 7.65169 10.8151 7.14282 10.0072 7.14282Z" fill="#4B526A"/>
    </svg>
  )
}

export default Icon20Question;