import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/react';
import React, {FC} from 'react';
import {MODAL_BALANCE} from "../routes";
import useModal from "../hooks/useModal";
import {useTranslation} from "react-i18next";
import CrystalBase from '../assets/icons/currency/crystal/Base';
import CrystalShadow from '../assets/icons/currency/crystal/Shadow';
import OverflowIcon from '../components/OverflowIcon/OverflowIcon';

const BalanceUpdateModal: FC = () => {

    const { t } = useTranslation();

    const {activeModal, setActiveModal, activeModalParams} = useModal();

    const formatCash = Intl.NumberFormat('en-US', {
      notation: "standard",
      maximumFractionDigits: 0
    }).format(activeModalParams ? activeModalParams['value']: 0).replaceAll(',', ' ')

    return (
        <Modal
            isOpen={activeModal === MODAL_BALANCE}
            placement='bottom'
            className='bg-[#19192B]'
            onClose={() => setActiveModal(null)}
        >
            <ModalContent  className='ModalDonate--ellipse'>
                {(onClose) => (
                    <>
                        <ModalHeader></ModalHeader>
                        <ModalBody className='px-6'>
                            <div className='flex flex-col gap-3 items-center justify-center'>
                                <div className='flex gap-3 text-4xl font-black text-white'>
                                    +
                                    {
                                      activeModalParams['balance'] === 'usdt' ? '$' : null
                                    }
                                    {formatCash}
                                    {
                                      activeModalParams['balance'] === 'ttc' ? ' CBC' : null
                                    }
                                    {
                                      activeModalParams['balance'] === 'donatediamonds' ? <OverflowIcon 
                                      Base={<CrystalBase width='24px' height='36px' />}
                                      Shadow={<CrystalShadow width='51px' height='44px' />}
                                      shadowOffsetTop={22}
                                      shadowOffsetLeft={-14}
                                      width={24}
                                      height={40}
                                    /> : null
                                    }
                                </div>
                            </div>
                            <p className="text-xl text-white font-normal text-center">
                              {t('modalCurrency')}
                            </p>
                        </ModalBody>
                        <ModalFooter className='px-6 mb-4'>
                            <Button
                                fullWidth
                                className='bg-[var(--link\_color)] opacity-100 rounded-xl text-lg py-4 h-fit'
                                onPress={onClose}
                            >
                                {t('modalOkay')}
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default BalanceUpdateModal;