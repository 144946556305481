import React, {FC} from 'react';
import TelegramBackButton from "../../components/TelegramBackButton/TelegramBackButton";
import {Table, TableBody, TableCell, TableColumn, TableHeader, TableRow} from '@nextui-org/react';
import {useTranslation} from "react-i18next";
import Icon24ChevronRight from '../../assets/icons/Icon24ChevronRight';
import { ROUTE_BALANCE, ROUTE_PRIVATE_SALE } from '../../routes';
import { useNavigate } from 'react-router-dom';

const EMISSIONS: any = [
    { id: 1, price: 0.05, mult: 10},
    { id: 2, price: 0.075, mult: 15},
    { id: 3, price: 0.113, mult: 22.5},
    { id: 4, price: 0.169, mult: 33.75},
    { id: 5, price: 0.253, mult: 50.62},
    { id: 6, price: 0.38, mult: 75.93}
];

const PublicSale: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const classNames = React.useMemo(
        () => ({
            th: ["EmissionTable--header bg-transparent h-fit text-xs px-4 py-3 text-white text-wrap text-center"],
            td: ["py-3 px-3 border-white/10 text-center",
                "group-data-[first=true]:border-b-[1px]",
                // middle
                "group-data-[middle=true]:border-b-[1px]"
            ],
          
        }),
        [],
      );

    return (
      <div className='overflow-x-hidden grow relative flex flex-col h-full w-full pt-4'>
        <TelegramBackButton returnTo={ROUTE_BALANCE} />

        <div className='flex flex-col w-full grow'>
          <div className='EmissionTable--title'>
              <p className='font-bold max-[359px]:text-2xl min-[360px]:text-3xl text-white text-center w-full'>
                  Public-Sale
              </p>
          </div>

          <>
              <Table 
                  removeWrapper 
                  aria-label="Friends bonus table"
                  className='w-full h-full mt-8 max-w-full'
                  classNames={classNames}
                  layout='fixed'
              >
                  <TableHeader>
                      <TableColumn width={'15%'} align='start'>{t('emissionStage')}</TableColumn>
                      <TableColumn width={'25%'} align='start'>{t('emissionPrice')}</TableColumn>
                      <TableColumn width={'60%'} align='start'>{t('emissionMult')}</TableColumn>
                  </TableHeader>

                  <TableBody>

                      {EMISSIONS.map((item: any, index: number) => (
                          <TableRow key={index + 'emission'}>
                              <TableCell>
                                {item.id}
                              </TableCell>
                              <TableCell>
                                ${item.price}
                              </TableCell>
                              <TableCell>
                                x{item.mult}
                              </TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
          </>
        </div>
        

        <div className='flex flex-col gap-3 w-full h-fit px-4'>
          <div 
            className='bg-[#16172F] flex justify-between rounded-2xl p-4 items-center cursor-pointer'
            onClick={() => navigate(ROUTE_PRIVATE_SALE)}
          >
            <p className='text-base text-white'>
            {t('emissionMoreAbout')} Private-Sale
            </p>
            <Icon24ChevronRight />
          </div>
          <a 
            className='px-3 py-2 text-[#4F3CC8] text-center' 
            href={t('emissionHowToLink')}
            target='_blank'
          >
            {t('emissionHowTo')}
          </a>
        </div>
      </div>
    );
};

export default PublicSale;