import React, {FC, ReactNode} from 'react';
import './Panel.css';

interface PanelProps {
    children: ReactNode
    bottomElement?: ReactNode
    spaceBetween?: boolean
    scrollable?: boolean
    padTop?: boolean
    padSides?: boolean
    fullHeight?: boolean
}

const Panel: FC<PanelProps> = ({ children, spaceBetween = true, bottomElement, scrollable = false, padTop = true, fullHeight = false, padSides = true }) => {

    return (
        <div id="Panel" className={[
            'Panel--container overflow-x-hidden grow relative flex flex-col h-full w-full',
            fullHeight ? 'full' : ''
        ].join(' ')}>
            <div className={['Panel--content', spaceBetween ? 'between' : '', scrollable ? 'overflow-y-auto' : '', padTop ? 'pt-4' : '', padSides ? 'px-4': ''].join(' ')}>
                {children}
            </div>
            {bottomElement}
        </div>
    );
};

export default Panel;