import React, {FC, useMemo} from 'react';
import { colorMapping } from './colors';

interface TBAchProps {
  level: number
  height?: number
}

const TBAchievement: FC<TBAchProps> = ({ level, height = 96 }) => {
  
    const colorScheme = useMemo(() => {
      return level in colorMapping ? colorMapping[level] : colorMapping[0]
    }, [level])
  
    return (
      <svg width="100%" height={height} viewBox="4 0 100 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter={`url(#filter0_dd_5801_13851_${level})`}>
          <path fillRule="evenodd" clipRule="evenodd" d="M72.179 35.6049C71.3412 35.6049 70.5036 35.285 69.8638 34.6453L63.36 28.1415C62.7355 27.517 62.4004 26.6945 62.4004 25.8263C62.4004 24.9429 62.7355 24.1204 63.36 23.5112L70.0466 16.8246C70.671 16.2001 71.4935 15.865 72.3617 15.865C73.2452 15.865 74.0677 16.2001 74.6769 16.8246L81.1807 23.3284C82.4602 24.6079 82.4602 26.6945 81.1807 27.974L74.4941 34.6606C73.8544 35.285 73.0166 35.6049 72.179 35.6049Z" 
            fill={`url(#paint0_linear_5801_13851_${level})`} 
            stroke={`url(#paint1_linear_5801_13851_${level})`} 
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M86.9698 64.3768C86.8937 64.3768 86.8023 64.3615 86.7261 64.3464C86.3301 64.2397 86.0559 63.8741 86.0559 63.4629C86.1016 56.4717 84.2282 49.6785 80.4965 43.2512C78.6157 40.0213 76.6526 37.5749 75.2606 36.0221C75.3682 35.9324 75.4721 35.8376 75.5722 35.7375L82.1691 29.1406C85.4254 32.911 87.8121 36.884 89.2698 40.9816C90.6254 44.8046 91.189 48.7341 90.9452 52.6791C90.5187 59.3962 87.8837 63.7522 87.777 63.935C87.5789 64.2245 87.2744 64.3768 86.9698 64.3768ZM61.9867 22.76C60.4358 21.3663 57.9777 19.3887 54.7249 17.4947C48.3126 13.7631 41.5195 11.8896 34.5283 11.9353C34.1171 11.9353 33.7668 11.6611 33.6448 11.2652C33.5382 10.8692 33.6905 10.4427 34.0408 10.2295C34.2236 10.1228 38.5798 7.48784 45.2967 7.0613C49.2417 6.80243 53.1712 7.36587 56.9942 8.73669C61.1008 10.1976 65.0823 12.5916 68.8598 15.8589L62.2843 22.4344C62.1796 22.5391 62.0805 22.6477 61.9867 22.76Z" 
            fill={`url(#paint2_linear_5801_13851_${level})`} 
            stroke={`url(#paint3_linear_5801_13851_${level})`} 
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M75.6855 15.6799L77.2217 14.1438C78.1052 13.2603 79.2779 12.7729 80.5421 12.7729C81.7911 12.7729 82.9792 13.2603 83.8625 14.1438C85.6903 15.9715 85.6903 18.957 83.8625 20.7847L82.3263 22.3209L82.2587 22.2516L75.7548 15.7478L75.6855 15.6799Z" 
            fill="#E1AB5C" 
            stroke={`url(#paint3_linear_5801_13851_${level})`}
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M19.2506 85C19.0068 85 18.7784 84.9086 18.6109 84.7258L13.2646 79.3949C12.9144 79.0446 12.9144 78.4657 13.2646 78.1001L25.2957 66.0655L31.923 72.6931L19.8903 84.7258C19.7228 84.9086 19.4944 85 19.2506 85ZM26.5903 64.7706L37.951 53.4064L44.5803 60.0358L33.2177 71.3984L26.5903 64.7706ZM39.2456 52.1115L50.6064 40.7473L57.2374 47.3787L45.875 58.7411L39.2456 52.1115ZM51.9009 39.4524L62.2086 29.1415L62.2836 29.2183L68.7875 35.7222L68.8412 35.7749L58.5322 46.084L51.9009 39.4524Z" 
            fill={`url(#paint4_linear_5801_13851_${level})`} 
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M19.2506 85C19.0068 85 18.7784 84.9086 18.6109 84.7258L13.2646 79.3949C12.9144 79.0446 12.9144 78.4657 13.2646 78.1001L25.2957 66.0655L31.923 72.6931L19.8903 84.7258C19.7228 84.9086 19.4944 85 19.2506 85ZM26.5903 64.7706L37.951 53.4064L44.5803 60.0358L33.2177 71.3984L26.5903 64.7706ZM39.2456 52.1115L50.6064 40.7473L57.2374 47.3787L45.875 58.7411L39.2456 52.1115ZM51.9009 39.4524L62.2086 29.1415L62.2836 29.2183L68.7875 35.7222L68.8412 35.7749L58.5322 46.084L51.9009 39.4524Z" 
            fill={`url(#paint5_linear_5801_13851_${level})`} 
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M19.2506 85C19.0068 85 18.7784 84.9086 18.6109 84.7258L13.2646 79.3949C12.9144 79.0446 12.9144 78.4657 13.2646 78.1001L25.2957 66.0655L31.923 72.6931L19.8903 84.7258C19.7228 84.9086 19.4944 85 19.2506 85ZM26.5903 64.7706L37.951 53.4064L44.5803 60.0358L33.2177 71.3984L26.5903 64.7706ZM39.2456 52.1115L50.6064 40.7473L57.2374 47.3787L45.875 58.7411L39.2456 52.1115ZM51.9009 39.4524L62.2086 29.1415L62.2836 29.2183L68.7875 35.7222L68.8412 35.7749L58.5322 46.084L51.9009 39.4524Z" 
            stroke={`url(#paint6_linear_5801_13851_${level})`} 
          />
        </g>
        <defs>
          <filter id={`filter0_dd_5801_13851_${level}`} x="-10" y="-2" width="124" height="112" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="8"/>
            <feGaussianBlur stdDeviation="5"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.00392157 0 0 0 0 0.0196078 0 0 0 0 0.2 0 0 0 0.08 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5857_13446"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="8"/>
            <feGaussianBlur stdDeviation="5"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.00392157 0 0 0 0 0.0196078 0 0 0 0 0.2 0 0 0 0.08 0"/>
            <feBlend mode="normal" in2="effect1_dropShadow_5857_13446" result="effect2_dropShadow_5857_13446"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_5857_13446" result="shape"/>
          </filter>
          <linearGradient id={`paint0_linear_5801_13851_${level}`} x1="62.3897" y1="25.7317" x2="82.1446" y2="25.7317" gradientUnits="userSpaceOnUse">
            <stop stopColor={colorScheme.mainGrad.to}/>
            <stop offset="0.38" stopColor={colorScheme.mainGrad.middle}/>
            <stop offset="1" stopColor={colorScheme.mainGrad.from}/>
          </linearGradient>
          <linearGradient id={`paint1_linear_5801_13851_${level}`} x1="72.2703" y1="15.865" x2="72.2703" y2="35.6049" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="#B3B3B3"/>
          </linearGradient>
          <linearGradient id={`paint2_linear_5801_13851_${level}`} x1="33.5802" y1="35.6791" x2="91.0128" y2="35.6791" gradientUnits="userSpaceOnUse">
            <stop stopColor={colorScheme.extraGrad?.first ?? colorScheme.mainGrad.from}/>
            <stop offset="0.3123" stopColor={colorScheme.extraGrad?.second ?? colorScheme.mainGrad.middle}/>
            <stop offset="0.7592" stopColor={colorScheme.extraGrad?.third ?? colorScheme.mainGrad.to}/>
            <stop offset="1" stopColor={colorScheme.extraGrad?.fourth ?? colorScheme.mainGrad.from}/>
          </linearGradient>
          <linearGradient id={`paint3_linear_5801_13851_${level}`} x1="62.3059" y1="7.00012" x2="62.3059" y2="64.3768" gradientUnits="userSpaceOnUse">
            <stop stopColor={colorScheme.secGrad.from}/>
            <stop offset="1" stopColor={colorScheme.secGrad.to}/>
          </linearGradient>
          <linearGradient id={`paint4_linear_5801_13851_${level}`} x1="65" y1="32.4999" x2="15.5" y2="82.9999" gradientUnits="userSpaceOnUse">
            <stop stopColor="#AD7B31"/>
            <stop offset="1" stopColor="#231A08"/>
          </linearGradient>
          <linearGradient id={`paint5_linear_5801_13851_${level}`} x1="65" y1="32.4999" x2="15.5" y2="82.9999" gradientUnits="userSpaceOnUse">
            <stop stopColor="#E1AB5C"/>
            <stop offset="1" stopColor="#7B5100"/>
          </linearGradient>
          <linearGradient id={`paint6_linear_5801_13851_${level}`} x1="40.9216" y1="29.1415" x2="40.9216" y2="85" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="#B3B3B3"/>
          </linearGradient>
        </defs>
      </svg>
    )
}

export default TBAchievement;
