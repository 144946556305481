import ReactDOM from "react-dom";
import App from "./App";
import {Provider} from "react-redux";
import store from "./store/store";
import './css/style.css';
import {WebAppProvider} from "@vkruglikov/react-telegram-web-app";
import {NextUIProvider} from "@nextui-org/react";
import * as Sentry from "@sentry/react"
import './i18n';
//import './eruda';
import './firebase';
import ErrorBoundary from "./components/ErrorBoundary";
import './touch';
import React, { StrictMode } from 'react';

Sentry.init({
    dsn: "https://e0ff7b1e0a904ef98c84aa7d5d10ff1c@o4507498045505536.ingest.de.sentry.io/4507498047340624",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/]
});

// @ts-ignore
const tg = window['Telegram'].WebApp;
console.log(tg)

tg.setHeaderColor('#08121D');
tg.setBackgroundColor('#08121D');
tg.enableClosingConfirmation();
tg.ready();
tg.expand();



// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <WebAppProvider options={{ smoothButtonsTransition: false }}>
                <NextUIProvider className="h-full">
                    <main className="dark text-foreground h-full">
                        <ErrorBoundary>
                            <App />
                        </ErrorBoundary>
                    </main>
                </NextUIProvider>
        </WebAppProvider>
    </Provider>
);