import React, {FC} from 'react';
import Panel from "../../components/Panel/Panel";
import EnergyBar from "./components/EnergyBar/EnergyBar";
import CoinButton from "./components/CoinButton/CoinButton";
import LeagueProgress from "../General/LeagueProgress/LeagueProgress"
import LeagueInfo from '../General/LeagueInfo/LeagueInfo';


const Home: FC = () => {

    return (
        <Panel>
            <div className='flex flex-col w-full h-fit'>
                <LeagueProgress />
                <LeagueInfo />
            </div>
            <CoinButton />

            <EnergyBar />
        </Panel>
    );
};

export default Home;