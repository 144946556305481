import React, {FC} from 'react'

const Icon28Lock: FC = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.6996 3.79725C11.3329 3.79725 9.41428 5.71584 9.41428 8.08254V9.98477H17.9848V8.08254C17.9848 5.71584 16.0662 3.79725 13.6996 3.79725ZM19.7848 9.99285V8.08254C19.7848 4.72173 17.0604 1.99725 13.6996 1.99725C10.3387 1.99725 7.61427 4.72173 7.61427 8.08254V10.035C5.84605 10.3208 4.48042 11.7879 4.34946 13.6043C4.33931 13.7451 4.33933 13.9033 4.33936 14.1241L4.33936 19.9675C4.33935 20.7756 4.33934 21.4364 4.38322 21.9735C4.42864 22.5294 4.52553 23.0315 4.76444 23.5004C5.13834 24.2342 5.73497 24.8308 6.4688 25.2047C6.93769 25.4436 7.43977 25.5405 7.99568 25.5859C8.53275 25.6298 9.1936 25.6298 10.0017 25.6298H17.9433C18.7513 25.6298 19.4122 25.6298 19.9493 25.5859C20.5052 25.5405 21.0072 25.4436 21.4761 25.2047C22.21 24.8308 22.8066 24.2342 23.1805 23.5004C23.4194 23.0315 23.5163 22.5294 23.5617 21.9735C23.6056 21.4364 23.6056 20.7756 23.6056 19.9675V14.7055C23.6056 14.6695 23.6056 14.6341 23.6056 14.5992C23.6058 13.9397 23.606 13.4662 23.5159 13.0532C23.1907 11.5633 22.027 10.3996 20.5371 10.0745C20.3113 10.0252 20.0674 10.0029 19.7848 9.99285ZM20.1533 11.8331C19.956 11.79 19.695 11.7848 18.8848 11.7848H8.51428C8.24331 11.7848 8.15561 11.7854 8.08835 11.7902C7.04762 11.8652 6.21983 12.693 6.1448 13.7338C6.13995 13.801 6.13936 13.8887 6.13936 14.1597V19.9298C6.13936 20.7847 6.14006 21.3718 6.17724 21.8269C6.21357 22.2715 6.28018 22.5103 6.36825 22.6832C6.56958 23.0783 6.89084 23.3996 7.28598 23.6009C7.45883 23.689 7.69766 23.7556 8.14226 23.7919C8.59736 23.8291 9.18443 23.8298 10.0394 23.8298H17.9056C18.7605 23.8298 19.3476 23.8291 19.8027 23.7919C20.2473 23.7556 20.4861 23.689 20.6589 23.6009C21.0541 23.3996 21.3753 23.0783 21.5767 22.6832C21.6648 22.5103 21.7314 22.2715 21.7677 21.8269C21.8049 21.3718 21.8056 20.7847 21.8056 19.9298V14.7055C21.8056 13.8954 21.8004 13.6344 21.7573 13.437C21.5822 12.6348 20.9556 12.0081 20.1533 11.8331Z" fill="white"/>
      <path d="M15.7198 17.8081C15.7198 18.7605 14.9376 19.5325 13.9727 19.5325C13.0078 19.5325 12.2256 18.7605 12.2256 17.8081C12.2256 16.8558 13.0078 16.0837 13.9727 16.0837C14.9376 16.0837 15.7198 16.8558 15.7198 17.8081Z" fill="white"/>
    </svg>
  )
}

export default Icon28Lock;
