import React, {FC, useMemo} from 'react';

interface Building25AchProps {
  id: string
  height?: number
}

const Building25Achievement: FC<Building25AchProps> = ({ id, height = 96 }) => {
  
    return (
      <svg width="100%" height={height} viewBox="4 -4 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_5859_13656)">
          <path d="M83 47.891C83 49.1615 82.9273 50.4319 82.7819 51.6661C82.6366 53.0455 82.3458 54.4249 82.0188 55.7317C81.8734 56.3125 81.728 56.857 81.5463 57.4378C81.2192 58.4541 80.8558 59.4705 80.4197 60.4506C79.4748 62.5923 78.3119 64.5888 76.9309 66.44C75.7679 68.0009 74.4596 69.4529 73.0059 70.796C67.5182 75.8779 60.1771 78.9634 52.1092 78.9997C44.0412 79.036 36.6638 75.9868 31.1397 70.9412C29.7224 69.6344 28.4141 68.2187 27.2511 66.6941C25.0706 63.8265 23.3989 60.5595 22.3449 57.0748C21.8362 55.4413 21.4727 53.7352 21.2547 51.9928C21.1093 50.7223 21.0003 49.4519 21.0003 48.1814C20.9276 30.9754 34.7376 17.0727 51.8911 17.0001C69.0083 16.9638 82.9273 30.7576 83 47.891Z" 
            fill="#1C1D2B"
          />
          <path d="M83 47.891C83 49.1615 82.9273 50.4319 82.7819 51.6661C82.6366 53.0455 82.3458 54.4249 82.0188 55.7317C81.8734 56.3125 81.728 56.857 81.5463 57.4378C81.2192 58.4541 80.8558 59.4705 80.4197 60.4506C79.4748 62.5923 78.3119 64.5888 76.9309 66.44C75.7679 68.0009 74.4596 69.4529 73.0059 70.796C67.5182 75.8779 60.1771 78.9634 52.1092 78.9997C44.0412 79.036 36.6638 75.9868 31.1397 70.9412C29.7224 69.6344 28.4141 68.2187 27.2511 66.6941C25.0706 63.8265 23.3989 60.5595 22.3449 57.0748C21.8362 55.4413 21.4727 53.7352 21.2547 51.9928C21.1093 50.7223 21.0003 49.4519 21.0003 48.1814C20.9276 30.9754 34.7376 17.0727 51.8911 17.0001C69.0083 16.9638 82.9273 30.7576 83 47.891Z" 
            fill="url(#paint0_linear_5859_13656)"
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M54.6503 4.34314C53.1372 3.00509 50.8639 3.00509 49.3508 4.34314L21.5326 28.9427C20.8814 29.5185 20.4355 30.2908 20.2625 31.1426L12.8677 67.5336C12.4655 69.5131 13.6022 71.4818 15.5175 72.1231L50.7304 83.9146C51.5547 84.1907 52.4464 84.1907 53.2707 83.9146L88.4836 72.1231C90.3989 71.4818 91.5356 69.5131 91.1334 67.5336L83.7386 31.1426C83.5656 30.2908 83.1197 29.5185 82.4685 28.9427L54.6503 4.34314ZM81.9758 51.57C82.1174 50.3682 82.1881 49.131 82.1881 47.8938C82.1174 31.2095 68.5631 17.7772 51.8946 17.8125C35.1906 17.8832 21.7425 31.4216 21.8133 48.1766C21.8133 49.4138 21.9195 50.651 22.0611 51.8881C22.2734 53.5849 22.6273 55.2462 23.1227 56.8369C24.149 60.2303 25.777 63.4116 27.9004 66.2041C29.0328 67.6888 30.3069 69.0673 31.6871 70.3399C37.0663 75.2533 44.2504 78.2225 52.1069 78.1872C59.9634 78.1518 67.1121 75.1472 72.456 70.1985C73.8716 68.8906 75.1456 67.4767 76.2781 65.9567C77.6229 64.1539 78.7553 62.2098 79.6755 60.1243C80.1002 59.1699 80.4541 58.1801 80.7726 57.1904C80.9495 56.6248 81.0911 56.0946 81.2326 55.529C81.5511 54.2565 81.8343 52.9132 81.9758 51.57Z" fill="url(#paint1_linear_5859_13656)"/>
          <g filter="url(#filter1_d_5859_13656)">
            <path fillRule="evenodd" clipRule="evenodd" d="M87.9376 48C87.9376 67.8477 71.8478 83.9375 52.0001 83.9375C32.1523 83.9375 16.0625 67.8477 16.0625 48C16.0625 28.1523 32.1523 12.0625 52.0001 12.0625C71.8478 12.0625 87.9376 28.1523 87.9376 48ZM81.9753 51.57C82.1168 50.3682 82.1876 49.131 82.1876 47.8938C82.1168 31.2095 68.5626 17.7772 51.894 17.8126C35.1901 17.8833 21.742 31.4216 21.8128 48.1766C21.8128 49.4138 21.9189 50.651 22.0605 51.8882C22.2728 53.5849 22.6267 55.2463 23.1222 56.8369C24.1485 60.2303 25.7764 63.4117 27.8998 66.2042C29.0323 67.6888 30.3063 69.0674 31.6865 70.3399C37.0657 75.2533 44.2498 78.2225 52.1064 78.1872C59.9629 78.1518 67.1116 75.1472 72.4554 70.1985C73.871 68.8906 75.1451 67.4767 76.2775 65.9567C77.6223 64.154 78.7548 62.2098 79.6749 60.1243C80.0996 59.1699 80.4535 58.1801 80.772 57.1904C80.949 56.6248 81.0905 56.0946 81.2321 55.529C81.5506 54.2565 81.8337 52.9133 81.9753 51.57Z" 
              fill="url(#paint2_linear_5859_13656)"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M83.6251 48C83.6251 65.466 69.4661 79.625 52.0001 79.625C34.534 79.625 20.375 65.466 20.375 48C20.375 30.534 34.534 16.375 52.0001 16.375C69.4661 16.375 83.6251 30.534 83.6251 48ZM81.9753 51.57C82.1168 50.3682 82.1876 49.131 82.1876 47.8938C82.1168 31.2095 68.5626 17.7772 51.894 17.8126C35.1901 17.8833 21.742 31.4216 21.8128 48.1766C21.8128 49.4138 21.9189 50.651 22.0605 51.8882C22.2728 53.5849 22.6267 55.2463 23.1222 56.8369C24.1485 60.2303 25.7764 63.4117 27.8998 66.2042C29.0323 67.6888 30.3063 69.0674 31.6865 70.3399C37.0657 75.2533 44.2498 78.2225 52.1064 78.1872C59.9629 78.1518 67.1116 75.1472 72.4554 70.1985C73.871 68.8906 75.1451 67.4767 76.2775 65.9567C77.6223 64.154 78.7548 62.2098 79.6749 60.1243C80.0996 59.1699 80.4535 58.1801 80.772 57.1904C80.949 56.6248 81.0905 56.0946 81.2321 55.529C81.5506 54.2565 81.8337 52.9133 81.9753 51.57Z" 
            fill="url(#paint3_radial_5859_13656)" fillOpacity="0.1"/>
          </g>
          <rect x="28.334" y="2" width="47" height="92" rx="8" fill={`url(#pattern0_dd_5869_13851_${id})`}/>
      </g>
      <defs>
      <filter id="filter0_d_5859_13656" x="-10" y="0" width="124" height="112" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="8"/>
        <feGaussianBlur stdDeviation="5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.00392157 0 0 0 0 0.0196078 0 0 0 0 0.2 0 0 0 0.08 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5859_13656"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5859_13656" result="shape"/>
      </filter>
      <filter id="filter1_d_5859_13656" x="10.0625" y="9.0625" width="83.875" height="83.875" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="3"/>
        <feGaussianBlur stdDeviation="3"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.00392157 0 0 0 0 0.0196078 0 0 0 0 0.2 0 0 0 0.08 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5859_13656"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5859_13656" result="shape"/>
      </filter>
      <pattern id={`pattern0_dd_5869_13851_${id}`} patternContentUnits="objectBoundingBox" width="1" height="1">
        <image className='object-contain' 
          width={500} height={500} 
          href={require(`../../images/buildings/${id}.png`)}
          transform="matrix(0.002 0 0 0.00102174 0 0.244565)"
        />
      </pattern>
        <linearGradient id="paint0_linear_5859_13656" x1="52" y1="17" x2="52" y2="79" gradientUnits="userSpaceOnUse">
        <stop offset="0.459" stopColor="white" stopOpacity="0"/>
        <stop offset="1" stopColor="white" stopOpacity="0.1"/>
        </linearGradient>
        <linearGradient id="paint1_linear_5859_13656" x1="12.7446" y1="43.7174" x2="91.2309" y2="43.7174" gradientUnits="userSpaceOnUse">
        <stop stopColor="#C2E8FD"/>
        <stop offset="0.3123" stopColor="#67E0AC"/>
        <stop offset="0.7592" stopColor="#138B57"/>
        <stop offset="1" stopColor="#C2E8FD"/>
        </linearGradient>
        <linearGradient id="paint2_linear_5859_13656" x1="16.0235" y1="47.9882" x2="87.9531" y2="47.9882" gradientUnits="userSpaceOnUse">
        <stop stopColor="#C2E8FD"/>
        <stop offset="0.3123" stopColor="#67E0AC"/>
        <stop offset="0.7592" stopColor="#138B57"/>
        <stop offset="1" stopColor="#C2E8FD"/>
        </linearGradient>
        <radialGradient id="paint3_radial_5859_13656" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(51.9842 47.9841) scale(31.6096 31.6096)">
        <stop stopColor="white" stopOpacity="0"/>
        <stop offset="1" stopColor="#571600"/>
        </radialGradient>
      </defs>
      </svg>
      
    )
}

export default Building25Achievement;
