import React, {FC} from 'react';


const Icon24Check: FC = () => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M21.1971 5.27096C21.5548 5.61613 21.5649 6.18589 21.2197 6.54355L9.79052 18.3862C9.62091 18.5619 9.38716 18.6612 9.14292 18.6612C8.89868 18.6612 8.66493 18.5619 8.49532 18.3862L2.78072 12.4649C2.43555 12.1072 2.44567 11.5374 2.80333 11.1923C3.16099 10.8471 3.73075 10.8572 4.07592 11.2149L9.14292 16.4652L19.9245 5.29356C20.2697 4.9359 20.8394 4.92578 21.1971 5.27096Z" fill="#4F3CC8"/>
      </svg>
    );
};

export default Icon24Check;