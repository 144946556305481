import React, {FC} from 'react';

interface IconQuestProps {
  active: boolean
}

const IconQuests: FC<IconQuestProps> = ({ active }) => {
    return (
      active ?
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.4218 9.13233C22.3282 8.83834 22.1481 8.57941 21.9051 8.38931C21.6621 8.1992 21.3674 8.08676 21.0595 8.06665L15.4916 7.677L13.4262 2.47607C13.3144 2.18854 13.1184 1.94148 12.8638 1.76727C12.6092 1.59305 12.3079 1.49981 11.9995 1.49976H11.998C11.69 1.50031 11.3893 1.59379 11.1353 1.76798C10.8812 1.94216 10.6857 2.18895 10.5742 2.47607L8.47797 7.70703L2.94086 8.06665C2.63298 8.08676 2.3383 8.1992 2.09529 8.3893C1.85227 8.57941 1.67219 8.83834 1.57855 9.13233C1.47977 9.43048 1.47305 9.75148 1.55927 10.0535C1.64549 10.3555 1.82065 10.6246 2.06195 10.8257L6.32172 14.4263L5.05463 19.4104C4.96729 19.7461 4.98335 20.1004 5.10071 20.4269C5.21806 20.7533 5.43124 21.0367 5.71234 21.24C5.98374 21.4374 6.30828 21.5486 6.64374 21.5591C6.9792 21.5695 7.31004 21.4788 7.5932 21.2986L11.9877 18.5147C11.9936 18.5103 11.9994 18.5073 12.0126 18.5147L16.7411 21.5103C16.9979 21.6743 17.2981 21.757 17.6026 21.7476C17.9071 21.7382 18.2016 21.6371 18.4477 21.4575C18.7024 21.2734 18.8956 21.0167 19.002 20.7209C19.1083 20.4252 19.123 20.1042 19.0439 19.8001L17.6977 14.3552L21.9384 10.8257C22.1797 10.6246 22.3549 10.3555 22.4411 10.0535C22.5273 9.75148 22.5206 9.43048 22.4218 9.13233Z" fill="#4F3CC8"/>
        </svg>
        : 
        <svg width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.4135 16.8812L16.1419 19.8769C16.7463 20.2598 17.4967 19.6903 17.3173 18.9847L15.9512 13.6108C15.9127 13.4611 15.9173 13.3036 15.9643 13.1564C16.0114 13.0092 16.0991 12.8783 16.2172 12.7787L20.4573 9.24959C21.0144 8.78588 20.7269 7.86126 20.0111 7.81481L14.4738 7.45544C14.3247 7.44479 14.1816 7.39198 14.0613 7.30317C13.941 7.21437 13.8484 7.09321 13.7943 6.95382L11.7292 1.75323C11.673 1.60528 11.5732 1.4779 11.443 1.38802C11.3127 1.29814 11.1582 1.25 11 1.25C10.8418 1.25 10.6873 1.29814 10.557 1.38802C10.4268 1.4779 10.327 1.60528 10.2708 1.75323L8.20568 6.95382C8.15157 7.09321 8.05897 7.21437 7.93868 7.30317C7.81838 7.39198 7.67533 7.44479 7.52618 7.45544L1.98894 7.81481C1.27315 7.86126 0.985597 8.78588 1.54272 9.24959L5.78278 12.7787C5.90095 12.8783 5.9886 13.0092 6.03566 13.1564C6.08272 13.3036 6.08727 13.4611 6.0488 13.6108L4.78188 18.5945C4.56667 19.4412 5.46715 20.1246 6.19243 19.6651L10.5865 16.8812C10.71 16.8025 10.8535 16.7607 11 16.7607C11.1465 16.7607 11.29 16.8025 11.4135 16.8812V16.8812Z" stroke="#4B526A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default IconQuests;