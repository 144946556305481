import React, {FC} from 'react';

const Icon24ChevronRight: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.29192 3.79289C7.68244 3.40237 8.31561 3.40237 8.70613 3.79289L16.2061 11.2929C16.5967 11.6834 16.5967 12.3166 16.2061 12.7071L8.70613 20.2071C8.31561 20.5976 7.68244 20.5976 7.29192 20.2071C6.90139 19.8166 6.90139 19.1834 7.29192 18.7929L14.0848 12L7.29192 5.20711C6.90139 4.81658 6.90139 4.18342 7.29192 3.79289Z" fill="#4B526A"/>
    </svg>
  )
}

export default Icon24ChevronRight;