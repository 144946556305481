import { t } from "i18next";
import React, {FC} from "react";
import WoodRocket from "../../../../assets/icons/currency/rocket/Wood";
import { formatNumberShort } from "../../../../utils/mathUtils";
import Button from "../../../../components/Button/Button";
import { useSelector } from "react-redux";
import { DefaultStateType } from "../../../../store/reducer";
import OverflowIcon from "../../../../components/OverflowIcon/OverflowIcon";
import CrystalBase from "../../../../assets/icons/currency/crystal/Base";
import CrystalShadow from "../../../../assets/icons/currency/crystal/Shadow";
import Icon12Crown from "../../../../assets/icons/Icon12Crown";

export interface ImprovementProps {
  imgSrc: any
  name: string
  piecePrice: number
  description: string
  requirement: string
  premium: boolean
  scalePercent: number
  status: 'bought' | 'available' | 'locked'
  onBuy: Function
}

//@ts-ignore
const tg = window['Telegram'].WebApp;

const Imrpovement: FC<ImprovementProps> = ({imgSrc, name, description, scalePercent, requirement, piecePrice, premium, status, onBuy}) => {

  const gold = useSelector((selector: DefaultStateType) => selector.gold);
  const crystals = useSelector((selector: DefaultStateType) => selector.crystals);

  const buildingName = name.replace(/[0-9]/g, '');
  const level = Number((name.match(/\d+$/) ?? [0])[0]);

  const buyImprovment = async () => {
    onBuy()
  }

  const buttonStyles = () => {
    switch (status) {
      case 'locked': return 'bg-[#4F3CC826] text-[#4F3CC8] text-base';
      case 'bought': return 'bg-transparent text-[#16C784] border-1 border-solid border-[#4B526A] text-sm';
      case 'available': return ((premium && ((crystals ?? 0) < piecePrice)) || (!premium && ((gold ?? 0) < piecePrice))) ? 'bg-transparent border-solid border-1 border-[#4B526A]' : 'bg-[#4F3CC8]'
    }
  }

  return (
    <div className={`ImprovementItemBox--container mb-2 flex w-full h-fit relative p-2 gap-2 rounded-2xl bg-[#16172F]`}>
      <div className={`ImprovementItemBox--image flex justify-center items-center w-[4.75rem] relative`}>
        <img className="object-contain" src={imgSrc} alt={name}/>
        <div className={`ImprovementItemBox--level${premium ? 'premium' : 'normal'} absolute h-10 inset-x-0 bottom-0 flex items-center justify-center gap-1 px-1.5 py-2 rounded-lg`}>
          {
            premium ? 
            <div className="flex flex-col w-fit h-fit">
              <Icon12Crown />
              <p className="text-[6px]/[8px] text-center w-full tracking-widest text-[#4F3CC8] font-black">
                VIP
              </p>
            </div> : null
          }
          <p className={`text-sm uppercase ${premium ? 'text-[#4F3CC8]' : 'text-white'}`}>
            {`LVL${level}`}
          </p>
        </div>
      </div>
      <div className={`ImprovementItemBox--info w-full`}>
        <p className={`ImprovementItemBox--title font-normal text-sm`}>
          {t(`buildingName--${buildingName}`)}
        </p>
        <p className={`ImprovementItemBox--description font-semibold text-sm min-h-[3.75rem]`}>
          {status === 'locked' ? 
          requirement : 
          `${t(`buildingName--${buildingName}`)} ${t(scalePercent === 50 ? 'improvementsIs50' : 'improvementsIs100')} ${scalePercent === 50 ? '50%' : t('improvementsTwice')} ${t('improvementsMoreEffective')}`}
        </p>
        <Button
          width="full"
          disabled={status !== 'available' || ((premium && ((crystals ?? 0) < piecePrice)) || (!premium && ((gold ?? 0) < piecePrice)))}
          customClass={`ImprovementItemBox--button ${buttonStyles()}`}
          onClick={buyImprovment}
          content={
            {
              "available": ((premium && ((crystals ?? 0) < piecePrice)) || (!premium && ((gold ?? 0) < piecePrice))) ?
                <div className="ImprovementItemBox--buyHour flex place-items-center w-fit">
                  {
                    premium ?
                    <OverflowIcon 
                      Base={<CrystalBase width="7.2px" height="12px" />} 
                      Shadow={<CrystalShadow width="17px" height="16px"/>}
                      shadowOffsetTop={6}
                      shadowOffsetLeft={-5}
                      width={8}
                      height={12}
                    /> :
                    <WoodRocket height="12px" width="12px" /> 
                  }
                  <p className="ImprovementItemBox--value text-red-500 font-extrabold text-xs/4 ml-1">
                    {formatNumberShort(piecePrice)}
                  </p>
                </div>
                : 
                <div className="ImprovementItemBox--buy flex flex-col w-fit place-items-center">
                  <p className="text-xs">
                    {t('improvementBuyFor')}
                  </p>
                  <div className="ImprovementItemBox--buyHour flex place-items-center w-fit">
                    {
                      premium ?
                      <OverflowIcon 
                        Base={<CrystalBase width="7.2px" height="12px" />} 
                        Shadow={<CrystalShadow width="17px" height="16px"/>}
                        shadowOffsetTop={6}
                        shadowOffsetLeft={-5}
                        width={8}
                        height={12}
                      /> :
                      <WoodRocket height="12px" width="12px" /> 
                    }
                    <p className="ImprovementItemBox--value font-extrabold text-xs/4 ml-1">
                      {formatNumberShort(piecePrice)}
                    </p>
                  </div>
                </div>,
              "bought": t('improvementBought'),
              "locked": t('improvementExecute')
            }[status]
          }
        />
      </div>
    </div>
  )
}

export default Imrpovement;