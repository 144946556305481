import React, {FC} from "react";
import './LeagueValue.css'

interface LeagueValueProps {
  text: string
  league: 'wood' | 'iron' | 'bronze' | 'silver' | 'gold' | 'platinum' | 'diamond' | 'master'
}

const LeagueValue: FC<LeagueValueProps> = ({ text, league }) => {
  return (
    <p className={['LeagueValue text-4xl font-black', league].join(' ')}>
      {text}
    </p>
  )
}

export default LeagueValue;