import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/react';
import React, {FC, useEffect, useState} from 'react';
import {MODAL_AD, MODAL_DAILY_TASK} from "../routes";
import useModal from "../hooks/useModal";
import {useTranslation} from "react-i18next";
import WoodRocket from '../assets/icons/currency/rocket/Wood';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_GOLD, ADD_TTC, DefaultStateType, SET_AD_TIMER, SET_CURRENT_DAILY, SET_DAILIES, getDispatchObject } from '../store/reducer';
import { formatNumberShort } from '../utils/mathUtils';
import { getTimeLeft } from '../utils/utils';
import { fetchData } from '../utils/api';
import dayjs from 'dayjs';
import { delay } from '../utils/utils';
import { AdController } from '../utils/ads';

const adController = new AdController()

interface DailyParams {
  unlockTime: number
  available: boolean
  day: number
  profit: number
}

const DayCell: FC<{day: number, profit: number, status: 'available' | 'locked' | 'claimed'}> = ({ day, profit, status }) => {
  const { t } = useTranslation();
  return (
    <div 
      key={day + profit}
      className={[
        'flex w-20 flex-col items-center justify-center py-2 rounded-xl', 
        status === 'available' ? 'border-solid border-2 border-[#16C784]' : (
          status === 'claimed' ? 'from-[#0CB876] to-[#0B6140] bg-gradient-to-b' : 'opacity-40'
        )
      ].join(' ')}
    >
      <p className='mb-3 capitalize'>
      {t('day')} {day}
      </p>
      <WoodRocket width='24px' height='24px' />
      <p className='mt-1 text-base/6'>
        +{formatNumberShort(profit)}
      </p>
    </div>
  )
}

const TasksDailyModal: FC = () => {

    const { t } = useTranslation();

    const {activeModal, setActiveModal} = useModal();
    const [params, setParams] = useState<DailyParams>()
    const dailies = useSelector((s: DefaultStateType) => s.dailies)
    const currentDaily = useSelector((s: DefaultStateType) => s.currentDaily)

    const [timeLeft, setTimeLeft] = useState<string>('24:00:00')
    const dispatch = useDispatch()

    const showTime = () => {
      return (timeLeft !== '24:00:00') ? timeLeft : null
    }

    useEffect(() => {
      if (!currentDaily) return

      setParams({
        unlockTime: currentDaily.unlockedAt,
        available: currentDaily.available,
        day: currentDaily.rewardLevel,
        profit: currentDaily.profit
      })
    }, [currentDaily])

    useEffect(() => {
      if (!params) return

      const addTime = !params.available ? 0 : 60 * 60 * 24
  
      const interval = setInterval(() => {
        setTimeLeft(getTimeLeft(params.unlockTime + addTime, 'hours'))
      }, 1000);
  
      return () => clearInterval(interval);
    }, [params]);

    const getReward = async () => {
      if (currentDaily && params && params.available) {
        const response = await fetchData('/user/take_daily_reward', {
          rewardLevel: currentDaily.rewardLevel
        })
        if (response.result) {
          dispatch(getDispatchObject(ADD_GOLD, currentDaily.profit))
          const response2 = await fetchData('/user/get_daily_rewards')
          if (response2.error) return

          const next = Math.round(new Date().getTime() / 1000) + 24 * 60 * 60 - 1
          const curr = {
              profit: response2.result.currentTask.goldAmount,
              unlockedAt: next,
              available: false,
              rewardLevel: response2.result.currentTask.rewardLevel
          }
          dispatch(getDispatchObject(SET_CURRENT_DAILY, curr))
        }
        watchAd(true)
      }
      else {
        setActiveModal(null)
      }
    }

    const watchAd = async (onlyClaim: boolean) => {
      const res = await adController.showAd()
      console.log(dayjs().toDate())
      if (res == 'success') {
          try {
              const response = await fetchData('/user/claim_ad_reward')
  
              const result = response.result
              if (!result) return
              setActiveModal(MODAL_AD, {bonus: result.tokenReward !== 0})
              dispatch(getDispatchObject(SET_AD_TIMER, dayjs().toDate()))
              if (result.goldReward) dispatch(getDispatchObject(ADD_GOLD, Math.round(result.goldReward)))
              if (result.tokenReward !== 0) dispatch(getDispatchObject(ADD_TTC, result.tokenReward))
  
          } catch (err) {
              console.log(err)
          }
      }
    }

    return (
        <Modal
            isOpen={activeModal === MODAL_DAILY_TASK}
            placement='bottom'
            scrollBehavior='inside'
            className='bg-[#19192B] relative max-h-[70%]'
            onClose={() => setActiveModal(null)}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader></ModalHeader>
                        <ModalBody className={['px-4 gap-6', params && params.available ? 'pb-16' : 'pb-36'].join(' ')}>
                            <div className='flex flex-col items-center justify-center'>
                              <img width={96} height={96} className='object-contain' src={require('../assets/images/tasks/daily.png')} alt="" />
                              <div className='text-3xl font-bold text-center mt-5'>
                                  {t('tasksDaily')}
                              </div>
                              <div className='text-sm font-normal text-center mt-1.5'>
                                  {t('tasksDailyDescription')}
                              </div>
                            </div>
                            <div className='grid grid-cols-4 gap-2'>
                              {
                                dailies && params? 
                                  Object.entries(dailies).map(([key, item]) => DayCell({day: item.rewardLevel + 1, profit: item.profit, 
                                    status: params.day < item.rewardLevel ? 'locked' : (
                                      params.day > item.rewardLevel ? 'claimed' : 'available'
                                    )}))
                                  : null
                              }
                            </div>
                        </ModalBody>
                        <div className='absolute left-0 right-0 bottom-0 px-4 pb-4 flex flex-col'>
                          {
                            params && !params.available ? 
                            <div className='flex bg-[#080A23] items-center w-full mb-3 rounded-xl px-3 py-2'>
                              <p className='font-normal text-base'>
                                {t('tasksPickupTimer')}
                              </p>
                              <div className="bg-[#EA3943] px-1.5 text-base font-semibold rounded-xl tracking-wider">
                                {showTime()}
                              </div>
                            </div> : null
                          }
                          <Button
                              fullWidth
                              className={`${params && params.available ? 'bg-[#4F3CC8] text-white' : 'bg-white text-[#4F3CC8]'} opacity-100  text-lg rounded-lg py-3 h-fit font-semibold`}
                              onClick={() => getReward()}
                          >
                            { t(params && params.available ? 'tasksTake' : 'tasksCancel') }
                          </Button>
                        </div>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default TasksDailyModal;