import React, {FC, useMemo} from 'react';

interface Building50AchProps {
  id: string
  height?: number
}

const Building50Achievement: FC<Building50AchProps> = ({ id, height = 96 }) => {
  
    return (
      <svg width="100%" height={height} viewBox="6 0 100 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_5859_13669)">
          <g filter="url(#filter1_d_5859_13669)">
          <path fillRule="evenodd" clipRule="evenodd" d="M58.6643 4.38152C57.1463 3.02517 54.8518 3.02517 53.3339 4.38152L43.1359 13.4939C42.4603 14.0975 41.5998 14.454 40.6952 14.5048L27.0407 15.2725C25.0083 15.3868 23.3858 17.0092 23.2715 19.0417L22.5038 32.6962C22.453 33.6007 22.0966 34.4612 21.4929 35.1368L12.3805 45.3349C11.0242 46.8528 11.0242 49.1473 12.3805 50.6653L21.4929 60.8633C22.0966 61.5389 22.453 62.3994 22.5038 63.3039L23.2715 76.9585C23.3858 78.9909 25.0083 80.6133 27.0407 80.7276L40.6952 81.4953C41.5998 81.5462 42.4603 81.9026 43.1359 82.5062L53.3339 91.6186C54.8518 92.9749 57.1463 92.975 58.6643 91.6186L68.8623 82.5062C69.5379 81.9026 70.3984 81.5462 71.303 81.4953L84.9575 80.7276C86.9899 80.6133 88.6124 78.9909 88.7266 76.9585L89.4943 63.3039C89.5452 62.3994 89.9016 61.5389 90.5053 60.8633L99.6176 50.6653C100.974 49.1473 100.974 46.8528 99.6176 45.3349L90.5053 35.1368C89.9016 34.4613 89.5452 33.6007 89.4943 32.6962L88.7266 19.0417C88.6124 17.0092 86.9899 15.3868 84.9575 15.2725L71.303 14.5048C70.3984 14.454 69.5379 14.0975 68.8623 13.4939L58.6643 4.38152ZM85.9743 51.5701C86.1159 50.3683 86.1867 49.1311 86.1867 47.8939C86.1159 31.2096 72.5616 17.7773 55.8931 17.8126C39.1892 17.8833 25.7411 31.4217 25.8119 48.1767C25.8119 49.4139 25.918 50.651 26.0596 51.8882C26.2719 53.5849 26.6258 55.2463 27.1213 56.837C28.1476 60.2304 29.7755 63.4117 31.8989 66.2042C33.0314 67.6889 34.3054 69.0674 35.6856 70.34C41.0648 75.2533 48.2489 78.2226 56.1054 78.1872C63.962 78.1519 71.1107 75.1473 76.4545 70.1986C77.8701 68.8907 79.1441 67.4768 80.2766 65.9568C81.6214 64.154 82.7539 62.2099 83.674 60.1243C84.0987 59.1699 84.4526 58.1802 84.7711 57.1905C84.9481 56.6249 85.0896 56.0947 85.2312 55.5291C85.5497 54.2566 85.8328 52.9133 85.9743 51.5701Z" fill="url(#paint0_linear_5859_13669)"/>
          <path d="M87 47.891C87 49.1615 86.9273 50.4319 86.7819 51.6661C86.6366 53.0455 86.3458 54.4249 86.0188 55.7317C85.8734 56.3125 85.728 56.857 85.5463 57.4378C85.2192 58.4541 84.8558 59.4705 84.4197 60.4506C83.4748 62.5923 82.3119 64.5888 80.9309 66.44C79.7679 68.0009 78.4596 69.4529 77.0059 70.796C71.5182 75.8779 64.1771 78.9634 56.1092 78.9997C48.0412 79.036 40.6638 75.9868 35.1397 70.9412C33.7224 69.6344 32.4141 68.2187 31.2511 66.6941C29.0706 63.8265 27.3989 60.5595 26.3449 57.0748C25.8362 55.4413 25.4727 53.7352 25.2547 51.9928C25.1093 50.7223 25.0003 49.4519 25.0003 48.1814C24.9276 30.9754 38.7376 17.0727 55.8911 17.0001C73.0083 16.9638 86.9273 30.7576 87 47.891Z" fill="#1C1D2B"/>
          <path d="M87 47.891C87 49.1615 86.9273 50.4319 86.7819 51.6661C86.6366 53.0455 86.3458 54.4249 86.0188 55.7317C85.8734 56.3125 85.728 56.857 85.5463 57.4378C85.2192 58.4541 84.8558 59.4705 84.4197 60.4506C83.4748 62.5923 82.3119 64.5888 80.9309 66.44C79.7679 68.0009 78.4596 69.4529 77.0059 70.796C71.5182 75.8779 64.1771 78.9634 56.1092 78.9997C48.0412 79.036 40.6638 75.9868 35.1397 70.9412C33.7224 69.6344 32.4141 68.2187 31.2511 66.6941C29.0706 63.8265 27.3989 60.5595 26.3449 57.0748C25.8362 55.4413 25.4727 53.7352 25.2547 51.9928C25.1093 50.7223 25.0003 49.4519 25.0003 48.1814C24.9276 30.9754 38.7376 17.0727 55.8911 17.0001C73.0083 16.9638 86.9273 30.7576 87 47.891Z" fill="url(#paint1_linear_5859_13669)"/>
          <g filter="url(#filter2_d_5859_13669)">
            <path fillRule="evenodd" clipRule="evenodd" d="M91.9376 48C91.9376 67.8477 75.8478 83.9375 56.0001 83.9375C36.1523 83.9375 20.0625 67.8477 20.0625 48C20.0625 28.1523 36.1523 12.0625 56.0001 12.0625C75.8478 12.0625 91.9376 28.1523 91.9376 48ZM85.9753 51.57C86.1168 50.3682 86.1876 49.131 86.1876 47.8938C86.1168 31.2095 72.5626 17.7772 55.894 17.8126C39.1901 17.8833 25.742 31.4216 25.8128 48.1766C25.8128 49.4138 25.9189 50.651 26.0605 51.8882C26.2728 53.5849 26.6267 55.2463 27.1222 56.8369C28.1485 60.2303 29.7764 63.4117 31.8998 66.2042C33.0323 67.6888 34.3063 69.0674 35.6865 70.3399C41.0657 75.2533 48.2498 78.2225 56.1064 78.1872C63.9629 78.1518 71.1116 75.1472 76.4554 70.1985C77.871 68.8906 79.1451 67.4767 80.2775 65.9567C81.6223 64.154 82.7548 62.2098 83.6749 60.1243C84.0996 59.1699 84.4535 58.1801 84.772 57.1904C84.949 56.6248 85.0905 56.0946 85.2321 55.529C85.5506 54.2565 85.8337 52.9133 85.9753 51.57Z" fill="url(#paint2_linear_5859_13669)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M87.6251 48C87.6251 65.466 73.4661 79.625 56.0001 79.625C38.534 79.625 24.375 65.466 24.375 48C24.375 30.534 38.534 16.375 56.0001 16.375C73.4661 16.375 87.6251 30.534 87.6251 48ZM85.9753 51.57C86.1168 50.3682 86.1876 49.131 86.1876 47.8938C86.1168 31.2095 72.5626 17.7772 55.894 17.8126C39.1901 17.8833 25.742 31.4216 25.8128 48.1766C25.8128 49.4138 25.9189 50.651 26.0605 51.8882C26.2728 53.5849 26.6267 55.2463 27.1222 56.8369C28.1485 60.2303 29.7764 63.4117 31.8998 66.2042C33.0323 67.6888 34.3063 69.0674 35.6865 70.3399C41.0657 75.2533 48.2498 78.2225 56.1064 78.1872C63.9629 78.1518 71.1116 75.1472 76.4554 70.1985C77.871 68.8906 79.1451 67.4767 80.2775 65.9567C81.6223 64.154 82.7548 62.2098 83.6749 60.1243C84.0996 59.1699 84.4535 58.1801 84.772 57.1904C84.949 56.6248 85.0905 56.0946 85.2321 55.529C85.5506 54.2565 85.8337 52.9133 85.9753 51.57Z" fill="url(#paint3_radial_5859_13669)" fillOpacity="0.1"/>
            </g>
          </g>
          <rect x="32.334" y="2" width="47" height="92" rx="8" fill={`url(#pattern0_dd_5859_13851_${id})`}/>
        </g>
      <defs>
        <filter id="filter0_d_5859_13669" x="-6" y="0" width="124" height="112" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="8"/>
        <feGaussianBlur stdDeviation="5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.00392157 0 0 0 0 0.0196078 0 0 0 0 0.2 0 0 0 0.08 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5859_13669"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5859_13669" result="shape"/>
        </filter>
        <filter id="filter1_d_5859_13669" x="0" y="0" width="112" height="112" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="8"/>
        <feGaussianBlur stdDeviation="5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.00392157 0 0 0 0 0.0196078 0 0 0 0 0.2 0 0 0 0.08 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5859_13669"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5859_13669" result="shape"/>
        </filter>
        <filter id="filter2_d_5859_13669" x="14.0625" y="9.0625" width="83.875" height="83.875" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="3"/>
        <feGaussianBlur stdDeviation="3"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.00392157 0 0 0 0 0.0196078 0 0 0 0 0.2 0 0 0 0.08 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5859_13669"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5859_13669" result="shape"/>
        </filter>
        <pattern id={`pattern0_dd_5859_13851_${id}`} patternContentUnits="objectBoundingBox" width="1" height="1">
          <image className='object-contain' 
            width={500} height={500} 
            href={require(`../../images/buildings/${id}.png`)}
            transform="matrix(0.002 0 0 0.00102174 0 0.244565)"
          />
        </pattern>
        <linearGradient id="paint0_linear_5859_13669" x1="11.3149" y1="47.9854" x2="100.654" y2="47.9854" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFF8C1"/>
        <stop offset="0.0001" stopColor="#C2E8FD"/>
        <stop offset="0.3123" stopColor="#919191"/>
        <stop offset="0.7592" stopColor="#DDDDDD"/>
        <stop offset="1" stopColor="#E3E3E3"/>
        </linearGradient>
        <linearGradient id="paint1_linear_5859_13669" x1="56" y1="17" x2="56" y2="79" gradientUnits="userSpaceOnUse">
        <stop offset="0.459" stopColor="white" stopOpacity="0"/>
        <stop offset="1" stopColor="white" stopOpacity="0.1"/>
        </linearGradient>
        <linearGradient id="paint2_linear_5859_13669" x1="20.0235" y1="47.9882" x2="91.9531" y2="47.9882" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFF8C1"/>
        <stop offset="0.0001" stopColor="#C2E8FD"/>
        <stop offset="0.3123" stopColor="#919191"/>
        <stop offset="0.7592" stopColor="#DDDDDD"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <radialGradient id="paint3_radial_5859_13669" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(55.9842 47.9841) scale(31.6096 31.6096)">
        <stop stopColor="white" stopOpacity="0"/>
        <stop offset="1" stopColor="#571600"/>
        </radialGradient>
      </defs>
      </svg>
      
    )
}

export default Building50Achievement;
