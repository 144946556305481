import React, { FC, useEffect, useMemo, useState } from "react";
import Panel from "../../components/Panel/Panel";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { formatNumberWithSpacesShort } from "../../utils/mathUtils";
import { Button } from "@nextui-org/react";
import Icon12Check from "../../assets/icons/Icon12Check";
import { useNavigate } from "react-router-dom";
import { ROUTE_HOME } from "../../routes";
import { fetchData } from "../../utils/api";
import { sendAnalytics } from "../../utils/gtag";

// @ts-ignore
const tg = window['Telegram'].WebApp;
const lang = tg.initDataUnsafe.user.language_code !== 'ru' ? 'en' : 'ru';

const Giveaway: FC = () => {

  const [prizepool, setPrizepool] = useState(0)
  const [status, setStatus] = useState<'loading' | 'not_joined' | 'success' | 'already_joined' | 'no_group' | 'finished'>('loading')
  const [amount, setAmount] = useState(0)
  const [endsAt, setEndsAt] = useState(dayjs().add(12, 'h'))
  const [drawingId, setDrawingId] = useState(0)
  const {t} = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (tg.initDataUnsafe.start_param && tg.initDataUnsafe.start_param.includes('drawing')) {
      const drawing = tg.initDataUnsafe.start_param.split('-')[1]
      setDrawingId(drawing)
      initData(drawing)
    } else {
      navigate('/')
    }
  }, [])

  const initData = async (id: string) => {

    sendAnalytics('giveaway-open', {
      give_id: id
    })

    const response = await fetchData('/channel_drawings/info', { drawingId: id })
    if (response.error) {
      navigate('/')
      return
    }
    const result = response.result

    setAmount(result.winnersCount)
    setPrizepool(result.budget)
    setEndsAt(dayjs(result.drawingDate))

    if (result.status && result.status === 'CLOSED') {
      setStatus('finished')
      return
    }

    if (result.channelDrawingUsers && result.channelDrawingUsers.length > 0) {
      setStatus('already_joined')
    } else {
      setStatus('not_joined')
    }

  }

  const participate = async () => {
    const response = await fetchData('/channel_drawings/join', { drawingId: drawingId })
    if (response.error) {
      navigate('/')
      return
    }

    const result = response.result

    if (result === 'DRAWING_CLOSED') {
      setStatus('finished')
      
    } else if (result === 'JOINED') {
      
      sendAnalytics('giveaway-join', {
        give_id: drawingId
      })

      setStatus('success')
      
    } else if (result === 'IS_NOT_A_CHANNEL_MEMBER') {
      setStatus('no_group')

    } else if (result === 'ALREADY_JOINED') {
      setStatus('already_joined')
    }
  }

  return (
    <Panel scrollable={false} padTop={false} fullHeight>
      <div className="flex flex-col items-center w-full h-full justify-center">
        <div className='w-24 h-24 self-center'>
            <img className='w-full object-contain' src={require('../../assets/images/preload/crown.png')} alt="" />
        </div>
        {
          status === 'success' ?
          <>
            <div className="flex flex-nowrap text-3xl mt-4 font-bold text-white text-center">
              {t('giveawayYouAreIn')}
            </div>
          </> :
          <>
            <div className="flex flex-nowrap text-3xl mt-4 font-bold text-white text-center">
              {`${t('giveaway')} ${status === 'loading' ? '???' : prizepool} CBC`}
            </div>
            <div className="text-base font-normal text-white mt-4 text-center px-12">
              {`${status === 'loading' ? '???' : amount} ${t('giveawayWinnersWillGet')} ${status === 'loading' ? '???' : prizepool} CBC, ${t('giveawayEach')} ${status === 'loading' ? '???' : formatNumberWithSpacesShort(prizepool / amount)} CBC`}
            </div>
            <div className="text-base font-normal text-white mt-6 text-center px-8">
              {t('giveawayRules')}
            </div>
            <div 
              className="flex mt-4 bg-[#4F3CC820] px-3 py-2 bg-opacity-20 rounded-full text-center"
            >
              <div 
                className='text-[var(--link\_color)] text-base text-center' 
                onClick={() => {
                  if (tg && 'openTelegramLink' in tg) {
                    tg.openTelegramLink('https://t.me/bunny_announcement')
                  }
                }}
              >
                Crypto Bunny
              </div>
            </div>
          </>
        }
        
        <div className="text-base font-normal text-white mt-4 text-center px-8">
          {`${status === 'loading' ? '???' : endsAt.locale(lang).format('DD MMMM YYYY HH:mm')} ${t('giveawayPick')}`}
        </div>
        {
          status === 'no_group' ? 
          <div className="text-red-500 text-lg font-normal mt-4">
            {t('giveawayNoGroup')}
          </div> : null
        }
        {
          status === 'finished' ? 
          <div className="text-red-500 text-lg font-normal mt-4">
            {t('giveawayEnded')}
          </div> : null
        }
        {
          status === 'already_joined' ? 
          <div className="text-green-400 text-lg mt-4 font-normal flex gap-1 items-center">
            <Icon12Check />
            {t('giveawayAlreadyIn')}
          </div> : null
        }
        {
          status === 'already_joined' || status === 'finished' || status === 'success' ? 
          <>
            <div className="text-base text-white font-normal mt-4">
              {t('giveawayMoreCBC')}
            </div>
            <Button 
                className='bg-white w-full mt-6 h-fit rounded-lg py-3 flex gap-2.5 items-center justify-center'
                onClick={() => {
                  sendAnalytics('giveaway-go-app', {
                    give_id: drawingId
                  })
                  navigate(ROUTE_HOME)}
                }
            >
                <div className='font-semibold text-[var(--link\_color)] text-lg'>
                  {t('giveawayOpenApp')}
                </div>
            </Button> 
          </> : null
        }
        {
          status === 'not_joined' || status === 'no_group' ? 
          <Button 
              className='bg-[var(--link\_color)] w-full mt-6 h-fit rounded-lg py-3 flex gap-2.5 items-center justify-center'
              onClick={participate}
          >
              <div className='font-semibold text-lg'>
                {t('giveawayParticipate')}
              </div>
          </Button> : null
        }

      </div>
    </Panel>
  )
}

export default Giveaway;