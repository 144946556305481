import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/react';
import React, {FC, useEffect, useMemo, useState} from 'react';
import {MODAL_ACHIEVEMENT} from "../routes";
import useModal from "../hooks/useModal";
import {useTranslation} from "react-i18next";
import { useNavigate } from 'react-router-dom';
import AchievementCell from '../panels/Achievements/components/Cell/Cell';
import { formatNumberShort } from '../utils/mathUtils';

const AchievementModal: FC = () => {

    const { t } = useTranslation();
    const {activeModal, setActiveModal, activeModalParams} = useModal();
    const navigate = useNavigate()

    const params = useMemo(() => {
      if (activeModal !== MODAL_ACHIEVEMENT || !activeModalParams) return null
      if (!activeModalParams.type || activeModalParams.level === undefined) return null
      return {
        type: activeModalParams.type,
        level: activeModalParams.level,
        id: activeModalParams.id ?? undefined,
        target: activeModalParams.target
      }
    }, [activeModalParams])

    const description = useMemo(() => {
      if (!params) return ''
      let line = ''
      if (params.target) {
        line += Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 0 }).format(params.target) + ' '
      } else return ''
      if (params.type === 'building' && params.id) {
        line += t(`buildingName--${params.id}`) + ' '
      }
      line += t(`ach--${params.type}`)
      return line
    }, [params])
    
    return (
        <Modal
            isOpen={activeModal === MODAL_ACHIEVEMENT}
            placement='bottom'
            scrollBehavior='inside'
            className='bg-[#19192B] relative'
            onClose={() => setActiveModal(null)}
        >
            <ModalContent>
                {() => (
                    <>
                        <ModalHeader></ModalHeader>
                        <ModalBody className="px-6 gap-6">
                            <div className='flex flex-col items-center justify-center'>
                              {
                                params ? 
                                  <AchievementCell 
                                    type={params.type}
                                    id={params.id}
                                    level={params.level}
                                    showModal={false}
                                    target={params.target}
                                    unlocked
                                  /> : null
                              }
                              <p className='text-3xl font-bold text-center mt-5'>
                                {description}
                              </p>
                              <p className='text-base/6 text-center mt-1.5'>
                                {t('achievementProfit')}
                              </p>
                            </div>
                        </ModalBody>
                        <ModalFooter className='flex-col'>
                          <Button
                            fullWidth
                            className='bg-[var(--link\_color)] opacity-100 rounded-lg px-3 h-fit py-4 text-lg'
                            onPress={() => {
                              setActiveModal(null)
                            }}
                          >
                            {t('modalCool')}
                          </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default AchievementModal;