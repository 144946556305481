

import React, {FC} from 'react';

interface IconProfileProps {
  active: boolean
}

const IconProfile: FC<IconProfileProps> = ({ active }) => {
  return (
    active ?
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.7985 3.89998C10.7364 3.89998 9.0661 5.57001 9.0661 7.62855C9.0661 9.68709 10.7364 11.3571 12.7985 11.3571C14.8605 11.3571 16.5308 9.68709 16.5308 7.62855C16.5308 5.57001 14.8605 3.89998 12.7985 3.89998ZM7.2661 7.62855C7.2661 4.57451 9.74371 2.09998 12.7985 2.09998C15.8532 2.09998 18.3308 4.57451 18.3308 7.62855C18.3308 10.6826 15.8532 13.1571 12.7985 13.1571C9.74371 13.1571 7.2661 10.6826 7.2661 7.62855ZM4.91803 16.2241C5.95257 15.2699 7.21543 14.9571 8.16608 14.9571H17.4308C18.3814 14.9571 19.6443 15.2699 20.6788 16.2241C21.7352 17.1984 22.4484 18.7438 22.4484 21C22.4484 21.497 22.0455 21.9 21.5484 21.9C21.0514 21.9 20.6484 21.497 20.6484 21C20.6484 19.1418 20.0749 18.1158 19.4585 17.5473C18.8202 16.9586 18.0243 16.7571 17.4308 16.7571H8.16608C7.57262 16.7571 6.77666 16.9586 6.1384 17.5473C5.52199 18.1158 4.94844 19.1418 4.94844 21C4.94844 21.497 4.54549 21.9 4.04844 21.9C3.55138 21.9 3.14844 21.497 3.14844 21C3.14844 18.7438 3.86165 17.1984 4.91803 16.2241Z" fill="#4F3CC8"/>
      </svg>
      : 
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.7985 3.89998C10.7364 3.89998 9.0661 5.57001 9.0661 7.62855C9.0661 9.68709 10.7364 11.3571 12.7985 11.3571C14.8605 11.3571 16.5308 9.68709 16.5308 7.62855C16.5308 5.57001 14.8605 3.89998 12.7985 3.89998ZM7.2661 7.62855C7.2661 4.57451 9.74371 2.09998 12.7985 2.09998C15.8532 2.09998 18.3308 4.57451 18.3308 7.62855C18.3308 10.6826 15.8532 13.1571 12.7985 13.1571C9.74371 13.1571 7.2661 10.6826 7.2661 7.62855ZM4.91803 16.2241C5.95257 15.2699 7.21543 14.9571 8.16608 14.9571H17.4308C18.3814 14.9571 19.6443 15.2699 20.6788 16.2241C21.7352 17.1984 22.4484 18.7438 22.4484 21C22.4484 21.497 22.0455 21.9 21.5484 21.9C21.0514 21.9 20.6484 21.497 20.6484 21C20.6484 19.1418 20.0749 18.1158 19.4585 17.5473C18.8202 16.9586 18.0243 16.7571 17.4308 16.7571H8.16608C7.57262 16.7571 6.77666 16.9586 6.1384 17.5473C5.52199 18.1158 4.94844 19.1418 4.94844 21C4.94844 21.497 4.54549 21.9 4.04844 21.9C3.55138 21.9 3.14844 21.497 3.14844 21C3.14844 18.7438 3.86165 17.1984 4.91803 16.2241Z" fill="#4B526A"/>
      </svg>
  );
};

export default IconProfile;