import React, {FC, ReactNode} from "react";
import { Button } from "@nextui-org/react";
import './Button.css'
import { MouseEventHandler } from "react";

interface ButtonProps {
  width: 'full' | 'fit'
  content: ReactNode
  customClass?: string
  disabled?: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}

const ButtonUI: FC<ButtonProps> = ({width, content, customClass, disabled = false, onClick}) => {
  return (
    <Button
      isDisabled={disabled}
      className={`Button--container w-${width} opacity-100 rounded-lg pt-1 px-4 pb-1.5 ${customClass ?? ''}`}
      children={content}
      onClick={onClick}
    />
  )
}

export default ButtonUI;