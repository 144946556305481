import React, {FC, useState} from 'react';
import Div from "../../../../components/Div/Div";
import {Button} from "@nextui-org/react";
import Spacing from "../../../../components/Spacing/Spacing";
import BottomLayout from "../../../../components/BottomLayout/BottomLayout";
import {useTranslation} from "react-i18next";
import {copyText} from "../../../../utils/utils";
import Icon24Copy from '../../../../assets/icons/Icon24Copy';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DefaultStateType } from '../../../../store/reducer';

// @ts-ignore
const tg = window["Telegram"]['WebApp'];
const link = `${process.env.REACT_APP_TELEGRAM_BOT_URL}?start=r_${tg['initDataUnsafe']['user']['id']}`

interface InviteFriendProps {

}

const InviteFriend: FC<InviteFriendProps> = ({}) => {

    const { t } = useTranslation();

    const link = useSelector((s: DefaultStateType) => s.inviteLink)

    const popup = async () => {
        if (!link) return
        copyText(link);
    }

    // получить ссылку

    return (
        <div className='InviteButtons--container mt-4 flex gap-2 px-4'>
            <Button
                size="lg"
                className="InviteButtons--popup opacity-100 bg-[#4F3CC8] rounded-lg text-base"
                onClick={() => {
                    if (!link) return
                    const newLink = encodeURIComponent(link)
                    const inviteText = encodeURIComponent(t('friendsInviteText'))
                    window.location.href = `https://t.me/share/url?url=${newLink}&text=${inviteText}`;
                    return null;
                }}
                fullWidth
            >
                {t('friendsInviteTitle')}
            </Button>
            <Button
                className="InviteButtons--copy w-fit opacity-100 bg-[#4F3CC8] rounded-lg min-w-fit h-12"
                onClick={popup}
            >
                <Icon24Copy />
            </Button>
        </div>
    );
};

export default InviteFriend;