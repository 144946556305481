import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/react';
import React, {FC, useEffect, useMemo, useState} from 'react';
import {MODAL_DEFAULT_TASK, SNACKBAR_ERROR} from "../routes";
import useModal from "../hooks/useModal";
import {useTranslation} from "react-i18next";
import WoodRocket from '../assets/icons/currency/rocket/Wood';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_CRYSTALS, ADD_GOLD, ADD_TTC, DefaultStateType, SET_TASKS, getDispatchObject } from '../store/reducer';
import { formatNumberWithSpaces } from '../utils/mathUtils';
import i18n from '../i18n';
import { delay, getBonusTypes, taskMapCodes } from '../utils/utils';
import { fetchData } from '../utils/api';
import useSnackbar from '../hooks/useSnackbar';
import dayjs from 'dayjs';
import OverflowIcon from '../components/OverflowIcon/OverflowIcon';
import CrystalBase from '../assets/icons/currency/crystal/Base';
import CrystalShadow from '../assets/icons/currency/crystal/Shadow';

type ModalParams = {
  time: string | null
  completed: boolean
  code: string
  bonus: number | null
  bonuses: { type: string, value: number }[] | null
  type: string
  action: string | null
  link: string | null
  dbtype: string
  imageLink: string | null
  description: string | null
} | null

const TasksDefaultModal: FC = () => {

    const { t, i18n } = useTranslation();

    const {activeModal, setActiveModal, activeModalParams} = useModal();
    const [timeLeft, setTimeLeft] = useState<string>('24:00:00')
    const unlockTime = new Date(activeModalParams && (activeModalParams['time'] ?? 0)).getTime()
    const dispatch = useDispatch()
    const tasks = useSelector((s: DefaultStateType) => s.tasks)
    const {setActiveSnackbar} = useSnackbar();
    const [txOngoing, setTxOngoing] = useState(false)

    const showAsCompleted = useMemo(() => {
      return (activeModalParams && activeModalParams['completed']) ? true : false
    }, [activeModalParams])

    useEffect(() => {
      if (!activeModalParams || !activeModalParams['time']) return
  
      const interval = setInterval(() => {
       setTimeLeft(new Date(unlockTime - new Date().getTime()).toLocaleTimeString())
      }, 1000);
  
      return () => clearInterval(interval);
    }, [unlockTime]);

    const params = useMemo<ModalParams>(() => {
      if (activeModal !== MODAL_DEFAULT_TASK) return null
      if (!activeModalParams || !activeModalParams['code'] || !activeModalParams['type']) return null
      return {
        time: activeModalParams['time'] ?? null,
        completed: activeModalParams['completed'] ?? true,
        code: activeModalParams['code'],
        description: activeModalParams['description'] ?? null,
        bonus: activeModalParams['bonus'] ?? null,
        bonuses: activeModalParams['bonuses'] ?? null,
        type: activeModalParams['type'],
        action: activeModalParams['action'] ?? null,
        link: activeModalParams['link'] ?? null,
        dbtype: activeModalParams['dbtype'] ?? 'NONE',
        imageLink: activeModalParams['imageLink'] ?? null,
      }
    }, [activeModal, activeModalParams])
  
    const checkTask = async () => {

      if (!tasks  || !params || !params.bonuses) return
      
      const response = await fetchData('/tasks/complete', {taskCode : params.code, locale: i18n?.language ?? 'en'})

      if (response.result === null) {
        setActiveSnackbar(SNACKBAR_ERROR, { error: t('task--error') })
        await delay(2000)
        setTxOngoing(false)
        return
      }

      const response2 = await fetchData('/tasks/get', { locale: i18n?.language ?? 'en' })

      if (response2.error) {
        await delay(2000)
        setTxOngoing(false)
        return
      }

      const uncompleted = response2.result.uncompletedTasks.map((item: any) => {
        const code = item.code in taskMapCodes ? item.code : 'fallback'
        
        return {
          code: item.code,
          type: taskMapCodes[code].type,
          action: taskMapCodes[code].action,
          bonus: item.goldReward,
          bonuses: getBonusTypes(item),
          completed: false,
          link: item.link,
          imageLink: item.imageLink ?? null,
          startsFrom: item.startAvailableAt ? dayjs(item.startAvailableAt).unix() : null,
          endsAt: item.stopAvailableAt ? dayjs(item.stopAvailableAt).unix() : null,
          dbType: item.type,
          locale: {
              title: item.locale ? item.locale.title : (item.fallbackLocale?.title ?? t('tasks--fallback')),
              description: item.locale ? item.locale.description : (item.fallbackLocale?.description ?? t('tasks--fallback')),
          }
        }
      })
      const completed = response2.result.completedTasks.map((i: any) => {
        const item = i.task
        const code = item.code in taskMapCodes ? item.code : 'fallback'
        return {
          code: item.code,
          type: taskMapCodes[code].type,
          action: taskMapCodes[code].action,
          bonus: null,
          bonuses: null,
          completed: true,
          link: item.link,
          imageLink: item.imageLink ?? null,
          dbType: item.type,
          startsFrom: item.startAvailableAt ? dayjs(item.startAvailableAt).unix() : null,
          endsAt: item.stopAvailableAt ? dayjs(item.stopAvailableAt).unix() : null,
          locale: {
            title: item.locale ? item.locale.title : (item.fallbackLocale?.title ?? t('tasks--fallback')),
            description: item.locale ? item.locale.description : (item.fallbackLocale?.description ?? t('tasks--fallback')),
          }
        }
      })
      setActiveModal(null)
      setTxOngoing(false)
      if (params.bonuses) {
        for (const item of params.bonuses) {
          switch (item.type) {
            case 'gold': {
              dispatch(getDispatchObject(ADD_GOLD, item.value))
              break
            }
            // case 'ttc': {
            //   dispatch(getDispatchObject(ADD_TTC, item.value))
            //   break
            // }
            // case 'crystals': {
            //   dispatch(getDispatchObject(ADD_CRYSTALS, item.value))
            //   break
            // }
            default: break
          }
        }
      }
      dispatch(getDispatchObject(SET_TASKS, [...uncompleted, ...completed].reduce((res: any, item) => ({ ...res, [item.code]: item}), {})))
    }

    const buttonText = () => {
      if (!params) return 'tasksCheckOut'
      else {
        if (params.completed) return 'tasksCheckOut'
        switch (params.type) {
          case 'youtube':
          case 'telegram': {
            return 'tasksSubscribe'
          }
          case 'twitter': {
            return 'tasksFollow'
          }
          case 'notion': {
            return 'tasksRead'
          }
          case 'cryptobot': {
            return 'tasksBot'
          }
          case 'facebook':
          case 'discord': {
            return 'tasksJoin'
          }
          case 'fallback': {
            return 'tasksCheckOut'
          }
        }
      }
    }

    const openAction = async () => {
      if (!params) return

      if (!params.completed && params.dbtype === 'NONE') await checkTask()

      let link = ""

      if (params.link) {
        link = params.link
      } else {
        switch (params.type) {
          case 'telegram': {
            if (params.action === 'channel') link = 'https://t.me/bunny_announcement'
            else if (params.action === 'group') {
              if (i18n.language === 'ru') link = 'https://t.me/cryptobunny_ru'
              else link = 'https://t.me/cryptobunny_en'
            }
            break;
          }
          case 'notion': {
            link = t('emissionHowToLink')
            break
          }
          case 'cryptobot': {
            link = 'https://t.me/send?start=r-3d8ix'
            break
          }
        }
      }
      if (link !== '') {
        window.location.assign(link)
      }
    }

    return (
        <Modal
            isOpen={activeModal === MODAL_DEFAULT_TASK}
            placement='bottom'
            scrollBehavior='inside'
            className='bg-[#19192B] relative'
            onClose={() => setActiveModal(null)}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader></ModalHeader>
                        <ModalBody className="px-6 gap-6">
                            <div className='flex flex-col items-center justify-center'>
                              <img width={96} height={96} className='object-contain rounded-xl' src={params?.imageLink ?? require(`../assets/images/tasks/${(params?.type) ?? 'telegram'}.png`)} alt="" />
                              <div className='text-lg font-semibold text-center mt-5'>
                                {(params?.description) ?? t('noDescription')}
                              </div>
                              {
                                !showAsCompleted && params?.bonuses ? 
                                <div className='flex flex-col items-center'>
                                  {
                                    params.bonuses.some(item => item.type === 'crystals') ?
                                    <div className='flex w-fit gap-2 items-center mt-3'>
                                      <OverflowIcon 
                                        Base={<CrystalBase width='24px' height='36px' />}
                                        Shadow={<CrystalShadow width='51px' height='44px' />}
                                        shadowOffsetTop={22}
                                        shadowOffsetLeft={-14}
                                        width={24}
                                        height={40}
                                      />
                                      <p className='text-3xl font-bold text-center'>
                                        +{formatNumberWithSpaces(params.bonuses.find(item => item.type === 'crystals')?.value ?? 0)}
                                      </p>
                                    </div> : null
                                  }
                                  {
                                    params.bonuses.some(item => item.type === 'ttc') ?
                                    <div className='flex w-fit gap-2 items-center mt-3'>
                                      <p className='text-3xl font-bold text-center'>
                                        +{formatNumberWithSpaces(params.bonuses.find(item => item.type === 'ttc')?.value ?? 0)} CBC
                                      </p>
                                    </div> : null
                                  }
                                  {
                                    params.bonuses.some(item => item.type === 'gold') ?
                                    <div className='flex w-fit gap-2 items-center mt-3'>
                                      <WoodRocket width='28px' height='28px'/>
                                      <p className='text-3xl font-bold text-center'>
                                        +{formatNumberWithSpaces(params.bonuses.find(item => item.type === 'gold')?.value ?? 0)}
                                      </p>
                                    </div> : null
                                  }
                                </div> : null
                              }
                            </div>
                        </ModalBody>
                        {
                          params?.type ? <ModalFooter className='flex-col'>
                            {
                              params.dbtype !== 'NONE' && !params.completed ? 
                              <Button
                                fullWidth
                                isDisabled={txOngoing}
                                className='bg-[var(--link\_color)] opacity-100 rounded-lg px-3 h-fit py-4 text-lg data-[disabled=true]:opacity-30'
                                onPress={() => {
                                  setTxOngoing(true)
                                  checkTask()
                                }}
                              >
                                {t('tasksCheck')}
                              </Button> : null
                            }
                            <Button
                                fullWidth
                                className='bg-[var(--link\_color)] opacity-100 rounded-lg px-3 h-fit py-4 text-lg'
                                onPress={openAction}
                            >
                              {t(buttonText() as string)}
                            </Button>
                          </ModalFooter> : null
                        }
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default TasksDefaultModal;