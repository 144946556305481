import { t } from "i18next";
import React, {FC, useEffect, useState} from "react";
import WoodRocket from "../../../../assets/icons/currency/rocket/Wood";
import { formatNumberShort, formatNumberWithSpaces } from "../../../../utils/mathUtils";
import Icon24ChevronRight from "../../../../assets/icons/Icon24ChevronRight";
import Icon24Check from "../../../../assets/icons/Icon24Check";
import { delay, getTimeLeft } from "../../../../utils/utils";
import { getDispatchObject, RESET_CURRENT_DAILY, SET_CURRENT_DAILY, SET_DAILIES } from "../../../../store/reducer";
import { fetchData } from "../../../../utils/api";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import OverflowIcon from "../../../../components/OverflowIcon/OverflowIcon";
import CrystalBase from "../../../../assets/icons/currency/crystal/Base";
import CrystalShadow from "../../../../assets/icons/currency/crystal/Shadow";

export interface FriendProps {
  type: string //'daily' | 'telegram' | 'discord' | 'special'
  title: string
  startsFrom: number | null
  endsAt: number | null
  status: 'extra' | 'time_limited' | 'time_locked' | 'completed'
  bonus: number
  bonuses: { type: string, value: number }[] | null

  onClick: Function

  link: string | null
  imageLink: string | null
  locale: {
    title: string | null
    description: string | null
  }
}

const TaskItem: FC<FriendProps> = ({type, title, startsFrom, endsAt, status, bonus, bonuses, onClick, locale, link, imageLink}) => {

  const [timeLeft, setTimeLeft] = useState<string>(endsAt ? getTimeLeft(endsAt, 'hours') : '24:00:00')
  const [unlockTime, setUnlockTime] = useState(endsAt ?? 0)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!endsAt || !startsFrom) return
    const interval = setInterval(() => {
      updateTime(unlockTime)
    }, 1000);

    return () => clearInterval(interval);
  }, [unlockTime]);

  useEffect(() => { 
    setUnlockTime(endsAt ?? 0)
  }, [endsAt])

  const updateTime = async (unlockAt: number) => {
    const newTimeLeft = getTimeLeft(unlockAt, 'hours')
      if (newTimeLeft === '0:00:00') {
        setTimeLeft(newTimeLeft)
        if (type === 'daily')
          await fetchDailies()
      } else {
        setTimeLeft(newTimeLeft)
      }
  }

  const formatNumber1Digit = (input: number) => {
    if (input < 10000) return formatNumberWithSpaces(input) 
    return Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 0 }).format(input)
  }

  const fetchDailies = async () => {
    setUnlockTime(value => value + 24*60*60)
    if (type === 'daily' && status === 'time_limited') {
      dispatch(getDispatchObject(RESET_CURRENT_DAILY, {}))
      await delay(1000)
      await fetchData('/user/get_daily_rewards')
    } else if (type === 'daily' && status === 'time_locked') {
      await delay(1000)
      const response = await fetchData('/user/get_daily_rewards')
      if (response.error) return
  
      const next = Math.round(new Date().getTime() / 1000) + 24 * 60 * 60 - 1
      const curr = {
          profit: response.result.currentTask.goldAmount,
          unlockedAt: next,
          available: true,
          rewardLevel: response.result.currentTask.rewardLevel
      }
      dispatch(getDispatchObject(SET_CURRENT_DAILY, curr))
    }
  }

  const onClickItem = () => {
    onClick()
  }

  return (
    <div 
      className="TaskItem--container flex w-full py-2 pl-3 pr-4 bg-[#16172F] items-center gap-3 rounded-2xl"
      onClick={onClickItem}
    >
      <img 
        width={60} height={60} 
        className="TaskItem--image object-contain rounded-lg" 
        src={imageLink ?? require(`../../../../assets/images/tasks/${type === 'special' ? 'daily' : type}.png`)} alt="" 
      />
      <div className="TaskItem--info flex w-full flex-col gap-1">
        <p className="font-base text-base text-white">
          {locale.title}
        </p>
        <div className="TaskItem--coins flex gap-1.5 w-full">
          {
            bonuses ?  
            <div className="TaskItem--price flex place-items-center w-fit">
            {
              bonuses.some(item => item.type === 'ttc') ?
              <div className='flex w-fit items-center pr-2'>
                <p className='text-sm text-nowrap font-bold text-center'>
                  +{formatNumber1Digit(bonuses.find(item => item.type === 'ttc')?.value ?? 0)} CBC
                </p>
              </div> : null
            }
            {
              bonuses.some(item => item.type === 'crystals') ?
              <div className='flex flex-nowrap w-fit gap-1 items-center [&:not(:first-child)]:pl-2 pr-2 relative'>
                <OverflowIcon 
                  Base={<CrystalBase width='12px' height='20px' />}
                  Shadow={<CrystalShadow width='26px' height='23px' />}
                  shadowOffsetTop={11}
                  shadowOffsetLeft={-7}
                  width={12}
                  height={20}
                />
                <p className='text-sm font-bold text-center'>
                  +{formatNumber1Digit(bonuses.find(item => item.type === 'crystals')?.value ?? 0)}
                </p>
              </div> : null
            }
              {
                bonuses.some(item => item.type === 'gold') ?
                <div className='flex flex-nowrap w-fit gap-1 items-center [&:not(:first-child)]:pl-2 relative'>
                  <WoodRocket width='16px' height='16px'/>
                  <p className='text-sm font-bold text-center'>
                    +{formatNumber1Digit(bonuses.find(item => item.type === 'gold')?.value ?? 0)}
                  </p>
                </div> : null
              }
            </div> 
            :
            bonus ? 
            <div className="TaskItem--price flex place-items-center w-fit">
              <div className='flex flex-nowrap w-fit gap-1 items-center [&:not(:first-child)]:pl-2 relative'>
                <WoodRocket height="16px" width="16px" />
                <p className="text-sm font-bold text-center">
                  {
                    `+${formatNumberWithSpaces(bonus)}`
                  }
                </p>
              </div>
            </div> : null
          }
        </div>
      </div>
      <div className="TaskItem--crystals flex w-fit gap-1.5">
        {
          status === 'completed' ? <Icon24Check /> : (
            status === 'extra' ? <Icon24ChevronRight /> :
            <div className={`${status === 'time_locked' ? 'bg-[#EA3943]' : 'bg-[#4F3CC8]'} px-1.5 text-base text-nowrap font-semibold rounded-xl tracking-wider`}>
              {timeLeft}
            </div>
          )
        }
      </div>
    </div>
  )
}

export default TaskItem;