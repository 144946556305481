import React, {FC} from 'react';
import ScoreBar from './components/ScoreBar/ScoreBar';
import ProfitCells from './components/ProfitCells/ProfitCells';

interface LeagueProgressProps {

}

const EnergyBar: FC<LeagueProgressProps> = ({}) => {
    
    return (
        <div className="LeagueProgress--wrapper h-[140px]">
            <ProfitCells />
            <ScoreBar />
        </div>
    );
};

export default EnergyBar;