import React, {FC, useRef, useState} from "react"
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import GlowBlob from "./components/GlowBlob/GlowBlob";
import '@splidejs/react-splide/css';
import './Onboarding.css'
import { Button } from "@nextui-org/react";
import { t } from "i18next";

const GlowOver: FC = () => {
  return (
    <div
      style={{
        height: '60px',
        zIndex: 20,
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        background: `linear-gradient(to top, rgb(8, 10, 33), transparent)`
      }}/>
  )
}

const Onboarding: FC<{ onClose: Function }> = (props) => {
  const ref = useRef<Splide>({} as Splide)
  const [current, setCurrent] = useState(0)
  const [locker, setLocker] = useState(false)

  return (
    <div className="Base--wrapper w-full h-full flex flex-col justify-between pb-10 fixed inset-0 z-[60]">
      <Splide 
        ref={ ref }
        hasTrack={ false } 
        className="flex flex-col justify-between h-full w-full" 
        aria-label="onboarding"
        onMoved={(s, i, p, n) => {
          if (locker) return
          setLocker(true)
          s.go(i)
          setCurrent(i)
          setLocker(false)
        }}
      >
        <SplideTrack>
          <SplideSlide>
              <div className="w-full flex flex-col">
                <div className="relative overflow-hidden w-full h-[330px] flex items-baseline justify-center">
                  <img className="z-10 object-contain" width={300} src={require('../../assets/images/onboarding/main.png')} alt="" />
                  {GlowOver({})}
                  <GlowBlob x={-24} y={-38} scale={1.4} opacity={100} glow="circle" />
                  <GlowBlob x={300} y={-20} scale={1.6} opacity={80} glow="circle" />
                  <GlowBlob x={-200} y={150} scale={1.6} opacity={80} glow="ellipse" />
                </div>
                <div className="mt-7 text-3xl font-bold text-white text-center px-4">
                  {t('onboardingTitle--Home')}
                </div>
                <div className="mt-3 text-base font-normal text-white text-center px-4">
                  {t('onboardingDesc--Home')}
                </div>
              </div>
          </SplideSlide>
          <SplideSlide>
              <div className="w-full flex flex-col">
                <div className="relative overflow-hidden w-full h-[330px] flex items-baseline justify-center">
                  <img className="z-10 object-contain" width={300} src={require('../../assets/images/onboarding/builds.png')} alt="" />
                  {GlowOver({})}
                  <GlowBlob x={300} y={50} scale={3} opacity={80} glow="circle" />
                  <GlowBlob x={24} y={-38} scale={2} opacity={80} glow="circle" />
                  <GlowBlob x={0} y={150} scale={1.6} opacity={80} glow="circle" />
                </div>
                <div className="mt-7 text-3xl font-bold text-white text-center px-4">
                  {t('onboardingTitle--Builds')}
                </div>
                <div className="mt-3 text-base font-normal text-white text-center px-4">
                  {t('onboardingDesc--Builds')}
                </div>
              </div>
          </SplideSlide>
          <SplideSlide>
              <div className="w-full flex flex-col">
                <div className="relative overflow-hidden w-full h-[330px] flex items-baseline justify-center">
                  <img className="z-10 object-contain" width={300} src={require('../../assets/images/onboarding/league.png')} alt="" />
                  {GlowOver({})}
                  <GlowBlob x={-20} y={140} scale={3} opacity={100} glow="circle" />
                  <GlowBlob x={0} y={0} scale={2} opacity={80} glow="ellipse" />
                </div>
                <div className="mt-7 text-3xl font-bold text-white text-center px-4">
                  {t('onboardingTitle--Prize')}
                </div>
                <div className="mt-3 text-base font-normal text-white text-center px-4">
                  {t('onboardingDesc--Prize')}
                </div>
              </div>
          </SplideSlide>
          <SplideSlide>
              <div className="w-full flex flex-col">
                <div className="relative overflow-hidden w-full h-[330px] flex items-baseline justify-center">
                  <div className="h-[270px] w-full"></div>
                  <img className="z-50 object-contain absolute bottom-0" width={144} height={144} src={require('../../assets/images/leagues/master.png')} alt="" />
                  <img className="z-30 object-contain absolute bottom-16 translate-x-[55px]" width={144} height={144} src={require('../../assets/images/leagues/diamond.png')} alt="" />
                  <img className="z-40 object-contain absolute bottom-16 translate-x-[-55px]" width={144} height={144} src={require('../../assets/images/leagues/silver.png')} alt="" />
                  <img className="z-10 object-contain absolute bottom-32 translate-x-[110px]" width={144} height={144} src={require('../../assets/images/leagues/wood.png')} alt="" />
                  <img className="z-20 object-contain absolute bottom-32 translate-x-[-110px]" width={144} height={144} src={require('../../assets/images/leagues/bronze.png')} alt="" />
                  <GlowBlob x={-50} y={50} scale={3} opacity={100} glow="circle" />
                  <GlowBlob x={250} y={50} scale={4} opacity={80} glow="circle" />
                  {GlowOver({})}
                </div>
                <div className="mt-7 text-3xl font-bold text-white text-center px-4">
                  {t('onboardingTitle--League')}
                </div>
                <div className="mt-3 text-base font-normal text-white text-center px-4">
                  {t('onboardingDesc--League')}
                </div>
              </div>
          </SplideSlide>
          <SplideSlide>
              <div className="w-full flex flex-col">
                <div className="relative overflow-hidden w-full h-[330px] flex items-baseline justify-center">
                  <img className="z-10 object-contain" width={300} src={require('../../assets/images/onboarding/balance.png')} alt="" />
                  {GlowOver({})}
                  <GlowBlob x={-24} y={-38} scale={1.4} opacity={100} glow="circle" />
                  <GlowBlob x={300} y={-20} scale={1.6} opacity={80} glow="circle" />
                  <GlowBlob x={-200} y={150} scale={1.6} opacity={80} glow="ellipse" />
                </div>
                <div className="mt-7 text-3xl font-bold text-white text-center px-4">
                  {t('onboardingTitle--CBC')}
                </div>
                <div className="mt-3 text-base font-normal text-white text-center px-4">
                  {t('onboardingDesc--CBC')}
                </div>
              </div>
          </SplideSlide>
        </SplideTrack>

      <div className="splide__arrows px-4 flex flex-row items-center relative">
        <button className="splide__arrow bg-transparent splide__arrow--prev relative px-3 py-2 text-base min-w-[60px] text-[#4F3CC8] font-semibold">Back</button>
        <ul className="splide__pagination relative w-full bottom-0"></ul>
        <button className="z-0 relative splide__arrow splide__arrow--next px-3 py-2 text-base opacity-100 h-fit min-w-[60px] w-fit text-[#4F3CC8] font-semibold bg-transparent">
          Next
        </button>
        {
          current === 4 ? 
          <div 
            className="z-10 absolute right-8 inset-y-0 px-3 py-2 text-base font-semibold opacity-100 h-fit min-w-[60px] w-fit bg-[#4F3CC8] text-white rounded-lg cursor-pointer"
            onClick={() => props.onClose()}
          >
            Play
          </div> : null
        }
      </div>
    </Splide>
    </div>
  )
}

export default Onboarding;