import React, {FC} from 'react'
import './GlowBlob.css'

const GlowBlob: FC<{x: number, y: number, scale: number, opacity: number, glow: 'ellipse' | 'circle'}> = (props) => {
  return (
    <div 
      className={`GlowBlob z-0 absolute ${props.glow}`}
      style={{
        left: props.x,
        top: props.y,
        width: Math.round(props.scale * 100 * (props.glow === 'circle' ? 1 : 5)),
        height: Math.round(props.scale * 100),
        opacity: `${props.opacity}%`
      }}/>
  )
}

export default GlowBlob