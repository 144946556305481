import React, {FC, useEffect} from 'react';
import Panel from "../../components/Panel/Panel";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {DefaultStateType, SET_RATE, SET_STAGE, getDispatchObject} from "../../store/reducer";
import {useNavigate} from "react-router-dom";
import Icon24ChevronRight from '../../assets/icons/Icon24ChevronRight';
import { Button } from '@nextui-org/react';
import { MODAL_EXCHANGE, MODAL_WITHDRAW, ROUTE_PRIVATE_SALE } from '../../routes';
import { formatNumberWithSpacesShort } from "../../utils/mathUtils";
import { fetchData } from '../../utils/api';

const Balance: FC = () => {

    const navigate = useNavigate();

    const selector = useSelector((s: DefaultStateType) => s);
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const exchangeRate = useSelector((s: DefaultStateType) => s.exchangeRate);

    return (
        <Panel scrollable={false}>
            <div className='flex flex-col w-full'>
                <div className='w-24 h-24 self-center'>
                    <img className='w-full object-contain' src={require('../../assets/images/preload/crown.png')} alt="" />
                </div>
                <p className='w-full text-white font-medium text-4xl text-center'>
                    Public-Sale
                </p>
                <div className='w-full pt-6 pb-4 mt-3 relative flex flex-col gap-2'>
                    <p className='font-black text-white text-4xl relative text-center z-40'>
                        1 CBC = {selector.exchangeRate}$
                    </p>
                    <div className='ScreenLoader--ellipse absolute inset-0'/>
                </div>
                <div className='Balance--values p-4 mt-2 rounded-lg border-[0.5px] border-solid border-white/15 from-white/0 to-white/15 bg-gradient-to-br'>
                    <p className='text-sm text-[var(--secondary\_hint)]'>
                        {t('balanceCBC')}
                    </p>
                    <div className='flex w-fit items-baseline'>
                        <p className='text-white text-3xl font-black'>
                        {Math.round(((Number(selector.ttc) ?? 0) + Number.EPSILON) * 10e4) / 10e4}
                        </p>
                        <p className='text-[var(--secondary\_hint)] ml-2 font-bold text-2xl'>~ ${Math.round((Number(selector.ttc) ?? 0) * (exchangeRate ?? 0.005))}</p>
                    </div>
                    
                </div>
            </div>
        </Panel>
    );
};

export default Balance;