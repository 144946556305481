import React, {FC} from 'react'

const Icon28Plus: FC = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.37549 14H23.6244" stroke="#4F3CC8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 4.37549V23.6244" stroke="#4F3CC8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Icon28Plus;