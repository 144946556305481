import React, {FC} from 'react';

const Icon24PremiumCrown: FC = () => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.5 17H5.5C5.225 17 5 17.225 5 17.5V18.5C5 18.775 5.225 19 5.5 19H18.5C18.775 19 19 18.775 19 18.5V17.5C19 17.225 18.775 17 18.5 17ZM20.5 7C19.6719 7 19 7.67188 19 8.5C19 8.72188 19.05 8.92812 19.1375 9.11875L16.875 10.475C16.3938 10.7625 15.7719 10.6 15.4937 10.1125L12.9469 5.65625C13.2812 5.38125 13.5 4.96875 13.5 4.5C13.5 3.67188 12.8281 3 12 3C11.1719 3 10.5 3.67188 10.5 4.5C10.5 4.96875 10.7188 5.38125 11.0531 5.65625L8.50625 10.1125C8.22813 10.6 7.60313 10.7625 7.125 10.475L4.86562 9.11875C4.95 8.93125 5.00312 8.72188 5.00312 8.5C5.00312 7.67188 4.33125 7 3.50312 7C2.675 7 2 7.67188 2 8.5C2 9.32812 2.67188 10 3.5 10C3.58125 10 3.6625 9.9875 3.74063 9.975L6 16H18L20.2594 9.975C20.3375 9.9875 20.4188 10 20.5 10C21.3281 10 22 9.32812 22 8.5C22 7.67188 21.3281 7 20.5 7Z" fill="#D4AA14"/>
      <path d="M18.5 17H5.5C5.225 17 5 17.225 5 17.5V18.5C5 18.775 5.225 19 5.5 19H18.5C18.775 19 19 18.775 19 18.5V17.5C19 17.225 18.775 17 18.5 17ZM20.5 7C19.6719 7 19 7.67188 19 8.5C19 8.72188 19.05 8.92812 19.1375 9.11875L16.875 10.475C16.3938 10.7625 15.7719 10.6 15.4937 10.1125L12.9469 5.65625C13.2812 5.38125 13.5 4.96875 13.5 4.5C13.5 3.67188 12.8281 3 12 3C11.1719 3 10.5 3.67188 10.5 4.5C10.5 4.96875 10.7188 5.38125 11.0531 5.65625L8.50625 10.1125C8.22813 10.6 7.60313 10.7625 7.125 10.475L4.86562 9.11875C4.95 8.93125 5.00312 8.72188 5.00312 8.5C5.00312 7.67188 4.33125 7 3.50312 7C2.675 7 2 7.67188 2 8.5C2 9.32812 2.67188 10 3.5 10C3.58125 10 3.6625 9.9875 3.74063 9.975L6 16H18L20.2594 9.975C20.3375 9.9875 20.4188 10 20.5 10C21.3281 10 22 9.32812 22 8.5C22 7.67188 21.3281 7 20.5 7Z" fill="url(#paint0_linear_6039_197)"/>
      <defs>
      <linearGradient id="paint0_linear_6039_197" x1="2" y1="2.5" x2="24.6984" y2="8.13956" gradientUnits="userSpaceOnUse">
      <stop stopColor="#17AEE9"/>
      <stop offset="0.496" stopColor="#E44EBF"/>
      <stop offset="1" stopColor="#FE7014"/>
      </linearGradient>
      </defs>
      </svg>
    );
};

export default Icon24PremiumCrown;
