import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/react';
import React, {FC} from 'react';
import {MODAL_DONATE, MODAL_OFFLINE_MINING, ROUTE_GIVEAWAY} from "../routes";
import useModal from "../hooks/useModal";
import {useTranslation} from "react-i18next";
import WoodRocket from '../assets/icons/currency/rocket/Wood';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_GOLD, DefaultStateType, getDispatchObject } from '../store/reducer';
import { formatNumberShort } from '../utils/mathUtils';
import { useLocation } from 'react-router-dom';

const OfflineMiningModal: FC = () => {

    const { t } = useTranslation();
    const location = useLocation()

    const {activeModal, setActiveModal, activeModalParams} = useModal();
    const dispatch = useDispatch()

    const onCloseModal = () => {
        if (activeModalParams && activeModalParams['minedGold'])
            dispatch(getDispatchObject(ADD_GOLD, activeModalParams['minedGold']))
        setActiveModal(null)
    }

    const formatCash = Intl.NumberFormat('en-US', {
        notation: "compact",
        maximumFractionDigits: 0
    }).format(activeModalParams ? activeModalParams['minedGold']: 0)

    return (
        <Modal
            isOpen={activeModal === MODAL_OFFLINE_MINING && location.pathname !== ROUTE_GIVEAWAY}
            placement='bottom'
            className='bg-[#19192B]'
            onClose={() => onCloseModal()}
        >
            <ModalContent>
                {() => (
                    <>
                        <ModalHeader></ModalHeader>
                        <ModalBody className='px-6'>
                            <div className='flex flex-col gap-3 items-center justify-center'>
                                <div className='flex gap-3 text-4xl font-black'>
                                    <WoodRocket width='40px' height='40px'/>
                                    +{formatCash}
                                </div>
                            </div>
                            <p className="text-xl text-white font-normal text-center">{t('offlineBuildings')}</p>
                        </ModalBody>
                        <ModalFooter className='px-6 mb-4'>
                            <Button
                                fullWidth
                                className='bg-[var(--link\_color)] opacity-100 rounded-xl text-lg py-4 h-fit'
                                onPress={onCloseModal}
                            >
                                {t('offlineThanks')}
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default OfflineMiningModal;