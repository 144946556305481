import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from "@nextui-org/react";
import { t } from "i18next";
import React, {FC, useEffect, useMemo, useRef, useState} from "react";
import { MODAL_EXCHANGE, MODAL_BALANCE, MODAL_BUY_CBC } from "../routes";
import useModal from "../hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import Icon24Close from "../assets/icons/Icon24Close";
import { DefaultStateType, SET_TTC, SET_USDT, getDispatchObject } from "../store/reducer";
import { fetchData } from "../utils/api";

interface ExchangeParams {
  min: number
  max: number
}

const ExchangeModal: FC = () => {
  
  const {activeModal, setActiveModal, activeModalParams} = useModal();
  const [params, setParams] = useState<ExchangeParams>()
  const [currentUSDT, setCurrentUSDT] = useState<string | null>(null)
  const [currentTTC, setCurrentTTC] = useState<string | null>(null)
  const [inFocus, setInFocus] = useState<null | 'usdt' | 'ttc'>(null)
  const exchangeRate = useSelector((s: DefaultStateType) => s.exchangeRate);

  const usdtRef = useRef<HTMLInputElement>(null)
  const ttcRef = useRef<HTMLInputElement>(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!activeModalParams || !exchangeRate) return
    setParams({
      min: 0.05,
      max: 1000
    })
    setCurrentUSDT('0.05')
    setCurrentTTC(String(Math.round(0.05 / exchangeRate)))
  }, [activeModalParams, exchangeRate])

  const handleInput = (newValue: HTMLInputElement, currency: 'usdt' | 'ttc') => {
    if (!params || !exchangeRate) return
    if (!newValue.value) {
      setCurrentUSDT(null)
      setCurrentTTC(null)
      return
    }
    if (newValue.value.endsWith(',')) {
      newValue.value = newValue.value.replace(',', '.')
    }
    if (!newValue.validity.valid || Number.isNaN(parseInt(newValue.value))) return;

    if (/^0\.?[0]*$/.test(newValue.value)) {
      const allZeros = /^[0]{2,}$/.test(newValue.value)
      setCurrentUSDT(allZeros ? '0' : newValue.value)
      setCurrentTTC(allZeros ? '0' : newValue.value)
      return
    }

    const value: number = Number(newValue.value)
    if (currency === 'usdt' && (value > params.max || value < params.min)) return;
    if (currency === 'ttc' && (value * exchangeRate > params.max || value * exchangeRate < params.min)) return;
    const usdt = +Math.round((currency === 'usdt' ? value : value * exchangeRate + Number.EPSILON) * 10e6) / 10e6
    const ttc = +Math.round((currency === 'ttc' ? value : value / exchangeRate + Number.EPSILON) * 10e6) / 10e6
    const addDot = newValue.value.endsWith('.') ? '.' : ''
    setCurrentUSDT(usdt + (currency === 'usdt' ? addDot : ''))
    setCurrentTTC(ttc + (currency === 'ttc' ? addDot : ''))
  }

  const exchange = async () => {
    if (!params || !currentUSDT || currentUSDT === '0' || Number(currentUSDT) < params.min) return
    setActiveModal(MODAL_BUY_CBC, {
      usdt: Number(currentUSDT),
      cbc: Number(currentTTC)
    })
    // const response = await fetchData('/ttc/buy', {
    //   amountUSDT: Number(currentUSDT)
    // })
    // if (response.result) {
    //   dispatch(getDispatchObject(SET_USDT, response.result.usdt))
    //   dispatch(getDispatchObject(SET_TTC, response.result.ttc))
    //   beforeLeaveModal()
    //   setActiveModal(MODAL_BALANCE, { balance: 'ttc', value: response.result.ttc - (ttc ?? 0) })
    // }
  }

  const beforeLeaveModal = () => {
    if (usdtRef.current) usdtRef.current.blur()
    if (ttcRef.current) ttcRef.current.blur()
    setInFocus(null)
  }

  return (
    <Modal
      isOpen={activeModal === MODAL_EXCHANGE}
      placement='top-center'
      scrollBehavior='inside'
      className='bg-[#19192B] relative mt-8'
      onClose={() => {
        beforeLeaveModal()
        setActiveModal(null)
      }}
    >
        <ModalContent >
            {(onClose) => (
                <>
                    <ModalHeader></ModalHeader>
                    <ModalBody className="px-6 gap-6">
                        <div className='flex flex-col items-center justify-center'>
                          <p className="text-3xl text-white">
                            {t('balanceCBCPurchase')}
                          </p>
                          <div className="mt-5 flex flex-col gap-6 w-full">
                            <div className="Exchange--USDT relative">
                              <input
                                ref={usdtRef}
                                type='tel'
                                pattern="(\d*[\.\,]\d{1,7}|\d{1,7}[\.\,]\d*|\d{1,7})"
                                id="USDT"
                                className="w-full border-2 border-solid outline-none border-white/10 px-4 py-3 rounded-2xl bg-transparent focus:border-[#4F3CC8]"
                                value={currentUSDT ?? "0"}
                                onChange={e => handleInput(e.target, 'usdt')}
                                onFocus={() => setInFocus('usdt')}
                                onBlur={() => inFocus === 'usdt' && setInFocus(null)}
                                required
                              />
                              <p className={`bg-[rgb(25,25,42)] px-1 font-semibold absolute top-[-8px] left-6 ${inFocus === 'usdt' ? 'text-[#4F3CC8]' : 'text-[#4B526A]'}`}>
                                {t('balanceGiveUSDT')}
                              </p>
                              {
                                currentUSDT && currentUSDT !== '0' && inFocus !== 'ttc' ?
                                <div 
                                  className="absolute right-4 top-2.5 cursor-pointer"
                                  onClick={() => {
                                    setCurrentUSDT('0')
                                    setCurrentTTC('0')
                                  }}
                                >
                                  <Icon24Close />
                                </div> : null 
                              }
                            </div>
                            <div className="Exchange--USDT relative">
                              <input 
                                ref={ttcRef}
                                type='tel'
                                pattern="(\d*[\.\,]\d+|\d+[\.\,]\d*|\d+)"
                                id="TTC"
                                className="w-full border-2 border-solid outline-none border-white/10 px-4 py-3 rounded-2xl bg-transparent focus:border-[#4F3CC8]"
                                value={currentTTC ?? "0"}
                                onChange={(e) => handleInput(e.target, 'ttc')}
                                onFocus={() => setInFocus('ttc')}
                                onBlur={() => inFocus === 'ttc' && setInFocus(null)}
                                required
                              />
                              <p className={`bg-[rgb(25,25,42)] px-1 font-semibold absolute top-[-8px] left-6 ${inFocus === 'ttc' ? 'text-[#4F3CC8]' : 'text-[#4B526A]'}`}>
                                {t('balanceGetCBC')}
                              </p>
                            </div>
                          </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className='flex-col'>
                      <Button
                        fullWidth
                        className='bg-[var(--link\_color)] opacity-100 rounded-lg px-3 h-fit py-4 text-lg'
                        onPress={() => exchange()}
                      >
                        {t('pay')}
                      </Button>
                    </ModalFooter>
                </>
            )}
        </ModalContent>
    </Modal>
  )
}

export default ExchangeModal