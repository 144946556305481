import React, {FC} from 'react';
import IconBalance from '../../../../../assets/icons/routes/IconBalance'
import IconUsers from '../../../../../assets/icons/routes/IconUsers'
import IconBuilding from '../../../../../assets/icons/routes/IconBuilding'
import IconQuests from '../../../../../assets/icons/routes/IconQuests'
import IconProfile from '../../../../../assets/icons/routes/IconProfile';

interface IconProps {
  name: 'main' | 'builds' | 'users' | 'quests' | 'balance' | 'profile'
  active: boolean
}

const RouteIcon: FC<IconProps> = ({ name, active}) => {
  const Icon = () => {
    switch (name) {
      case 'balance': return IconBalance({ active });
      case 'profile': return IconProfile({ active });
      case 'quests': return IconQuests({ active });
      case 'builds': return IconBuilding({ active });
      case 'users': return IconUsers({ active });
    }
  }

  return (
    <Icon />
  )
}

export default RouteIcon;
