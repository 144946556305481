export const colorMapping = {
  0: {
    mainGrad: { from: '#86D4B2', middle: '#29B578', to: '#2B6656' },
    secGrad: { from: 'white', to: '#B3B3B3' },
    extraGrad: { first: '#C2E8FD', second: "#67E0AC", third: '#138B57', fourth: '#C2E8FD'}
  },
  1: {
    mainGrad: { from: '#ffe9fb', middle: '#ffc4f4', to: '#b27fa9' },
    secGrad: { from: 'white', to: '#B3B3B3' },
    extraGrad: { first: '#ffe9fb', second: "#ffc4f4", third: '#b27fa9', fourth: '#ffe9fb'}
  },
  2: {
    mainGrad: { from: '#F8C94F', middle: '#F5D635', to: '#B08900' },
    secGrad: { from: 'white', to: '#B3B3B3' },
    extraGrad: { first: '#FDC910', second: "#DCC345", third: '#FFE195', fourth: '#FDC910'}
  },
  3: {
    mainGrad: { from: '#7CF7E8', middle: '#3CE5F0', to: '#287373' },
    secGrad: { from: 'white', to: '#B3B3B3' },
    extraGrad: { first: '#41FDE6', second: "#00BDBD", third: '#58E0BF', fourth: '#6CFDF4'}
  },
  4: {
    mainGrad: { from: '#7C90F7', middle: '#3C4EF0', to: '#283073' },
    secGrad: { from: 'white', to: '#B3B3B3' },
    extraGrad: { first: '#41B8FD', second: "#0013BD", third: '#5866E0', fourth: '#6CC7FD'}
  },
  5: {
    mainGrad: { from: '#F281F4', middle: '#CC3CF0', to: '#682279' },
    secGrad: { from: 'white', to: '#B3B3B3' },
    extraGrad: { first: '#D741FD', second: "#AE00BD", third: '#D558E0', fourth: '#FD6CE6'}
  },
  6: {
    mainGrad: { from: '#EFA175', middle: '#EAA451', to: '#91561F' },
    secGrad: { from: 'white', to: '#B3B3B3' }
  },
  7: {
    mainGrad: { from: '#F59A9A', middle: '#F65A5A', to: '#7C2F25' },
    secGrad: { from: 'white', to: '#B3B3B3' }
  },
  8: {
    mainGrad: { from: '#CECECE', middle: '#838383', to: '#2F2F2F' },
    secGrad: { from: '#94F11E', to: '#04C222' }
  },
  9: {
    mainGrad: { from: '#63E4E4', middle: '#0C7CE3', to: '#C51C77' },
    secGrad: { from: '#F11E90', to: '#0704C2' }
  },
  10: {
    mainGrad: { from: '#E2A508', middle: '#F5ED31', to: '#E0650B' },
    secGrad: { from: '#A00B0B', to: '#0F0C91' }
  }
} as{
  [key: number] : {
    mainGrad: {
      from: string
      middle: string
      to: string
    }
    secGrad: {
      from: string
      to: string
    }
    extraGrad?: {
      first: string
      second: string
      third: string
      fourth: string
    }
  }
}