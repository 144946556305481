import React, {FC} from 'react';

const Icon12Check: FC = () => {
    return (
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.637 2.49408C10.9543 2.81951 10.9543 3.34715 10.637 3.67259L4.94952 9.50592C4.63222 9.83136 4.11778 9.83136 3.80048 9.50592L1.36298 7.00592C1.04567 6.68049 1.04567 6.15285 1.36298 5.82741C1.68028 5.50197 2.19472 5.50197 2.51202 5.82741L4.375 7.73815L9.48798 2.49408C9.80528 2.16864 10.3197 2.16864 10.637 2.49408Z" fill="#16C784"/>
      </svg>
    )
}

export default Icon12Check;