import React, { FC } from "react";
import { TadsWidget } from 'react-tads-widget';

const TaskAds: FC = () => {
  return (
    <div className='Tasks--section flex flex-col w-full mt-3'>
      <TadsWidget 
          id="174" 
          debug={ process.env.NODE_ENV === 'production' ? false : true } 
      />
    </div>
  )
}

export default TaskAds;