import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/react';
import React, {FC, useEffect, useState} from 'react';
import {MODAL_BUY_BUILDINGS, ROUTE_BUILDINGS} from "../routes";
import useModal from "../hooks/useModal";
import {useTranslation} from "react-i18next";
import { useNavigate } from 'react-router-dom';

// @ts-ignore
const tg = window['Telegram'].WebApp;

const BoostsBuyBuildings: FC = () => {

    const { t } = useTranslation();
    const {activeModal, setActiveModal, activeModalParams} = useModal();
    const navigate = useNavigate()
    
    return (
        <Modal
            isOpen={activeModal === MODAL_BUY_BUILDINGS}
            placement='bottom'
            scrollBehavior='inside'
            className='bg-[#19192B] relative'
            onClose={() => setActiveModal(null)}
        >
            <ModalContent>
                {() => (
                    <>
                        <ModalHeader></ModalHeader>
                        <ModalBody className="px-6 gap-6">
                            <div className='flex flex-col items-center justify-center'>
                              <img width={96} height={96} className='object-contain' src={require(`../assets/images/boosts/pig.png`)} alt="" />
                              <p className='text-3xl font-bold text-center mt-5'>
                                {t('boostIncreaseProfits')}
                              </p>
                              <p className='text-base/6 text-center mt-1.5'>
                                {t('boostBuyBuildingsDescription')}
                              </p>
                            </div>
                        </ModalBody>
                        <ModalFooter className='flex-col'>
                          <Button
                            fullWidth
                            className='bg-[var(--link\_color)] opacity-100 rounded-lg px-3 h-fit py-4 text-lg'
                            onPress={() => {
                              navigate(ROUTE_BUILDINGS)
                              setActiveModal(null)
                            }}
                          >
                            {t('boostsStartMining')}
                          </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default BoostsBuyBuildings;