import React, {FC} from 'react';
import {BackButton} from "@vkruglikov/react-telegram-web-app";
import {To, useNavigate} from "react-router-dom";

const TelegramBackButton: FC<{ returnTo?: To }> = ({ returnTo }) => {

    const navigate = useNavigate();

    return (
        <BackButton
            onClick={() => navigate(returnTo ? returnTo : -1 as To)}
        />
    );
};

export default TelegramBackButton;