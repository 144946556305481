import React, {FC} from 'react'

const WoodRocket: FC<{ width: string, height: string }> = ({width, height}) => {
  return (
    <svg height={`${height}`} width={`${width}`} viewBox="0 0 284 284" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="142" cy="142" r="142" fill="url(#paint0_linear_4715_15006)"/>
      <circle cx="141.999" cy="141.999" r="122.746" fill="url(#paint1_linear_4715_15006)"/>
      <g filter="url(#filter0_f_4715_15006)">
      <circle cx="141.999" cy="141.999" r="122.321" stroke="url(#paint2_linear_4715_15006)" strokeWidth="0.85"/>
      </g>
      <g opacity="0.5">
      <circle cx="141.996" cy="141.996" r="120.339" fill="url(#paint3_radial_4715_15006)"/>
      <g filter="url(#filter1_i_4715_15006)">
      <circle cx="142" cy="142" r="110.712" fill="#6052B4" fillOpacity="0.01"/>
      </g>
      </g>
      <defs>
      <filter id="filter0_f_4715_15006" x="18.9988" y="18.9987" width="246" height="246" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="0.127183" result="effect1_foregroundBlur_4715_15006"/>
      </filter>
      <filter id="filter1_i_4715_15006" x="31.2881" y="31.2883" width="221.424" height="221.424" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="5.70131"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.458333 0 0 0 0 0.256743 0 0 0 0 0.0706597 0 0 0 1 0"/>
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_4715_15006"/>
      </filter>
      <linearGradient id="paint0_linear_4715_15006" x1="82.36" y1="34.2163" x2="182.244" y2="264.804" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF5DE"/>
      <stop offset="1" stopColor="#CFA365"/>
      </linearGradient>
      <linearGradient id="paint1_linear_4715_15006" x1="90.4457" y1="48.8298" x2="176.786" y2="248.151" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF5DE"/>
      <stop offset="1" stopColor="#CFA365"/>
      </linearGradient>
      <linearGradient id="paint2_linear_4715_15006" x1="92.3091" y1="44.2014" x2="187.907" y2="244.05" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FFF6E7"/>
      <stop offset="1" stopColor="#FFF8EC"/>
      </linearGradient>
      <radialGradient id="paint3_radial_4715_15006" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(141.996 141.996) rotate(90) scale(120.339)">
      <stop stopColor="#FFE4BA"/>
      <stop offset="1" stopColor="#DEA44D"/>
      </radialGradient>
      </defs>
    </svg>
  )
}

export default WoodRocket;