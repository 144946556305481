import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Tabs, Tab, Select, SelectItem } from "@nextui-org/react";
import { t } from "i18next";
import React, {FC, useEffect, useRef, useState} from "react";
import { MODAL_WITHDRAW } from "../routes";
import useModal from "../hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import Icon24Close from "../assets/icons/Icon24Close";
import { DefaultStateType } from "../store/reducer";
import {fetchData} from "../utils/api";

interface ExchangeParams {
  min: number
  max: number
}

const regexList = {
  trc20: /^T[A-Za-z1-9]{33}$/,
  erc20: /^(0x)?[0-9a-fA-F]{40}$/,
  bep20: /^(0x)?[0-9a-fA-F]{40}$/,
  monero: /^4[0-9AB][1-9A-HJ-NP-Za-km-z]{93}$/
} as {[key: string]: RegExp}

const walletList = [
  { key: 'trc20', label: 'TRC20' },
  { key: 'erc20', label: 'ERC20' },
  { key: 'bep20', label: 'BEP20' },
  { key: 'ton', label: 'TON' }
]

const tonAddress = (input: string) : string | null => {

  if (input.length !== 48 && input.length !== 66 && input.length !== 67) return null
  if ((input.length === 66 && input[1] === ':' && input.split(':')[1].match(/^[\da-fA-F]{64}$/))
      || (input.length === 67 && input[2] === ':' && input.split(':')[1].match(/^[\da-fA-F]{64}$/))
      || input.match(/[UEk0][Qf][\w-]{46}/)) return input

  return null
}

const checkWalletValidity = (chain: string, input: string) => {
  if (input === '') return false
  if (chain !== 'ton') {
    console.log(regexList[chain])
    console.log(input)
    return regexList[chain].test(input)
  }
  else return (tonAddress(input))
}

const WithdrawalModal: FC = () => {

  const {activeModal, setActiveModal, activeModalParams} = useModal();
  const usdt = useSelector((s: DefaultStateType) => s.usdt)
  const [chain, setChain] = useState<string>('')
  const [invalidAddr, setInvalidAddr] = useState(false)
  const [invalidVal, setInvalidVal] = useState(false)
  const [params, setParams] = useState<ExchangeParams>()
  const [currentUSDT, setCurrentUSDT] = useState<string | null>(null)
  const [currentAddr, setCurrentAddr] = useState<string>("")
  const [inFocus, setInFocus] = useState<null | 'usdt' | 'wallet' | 'address'>(null)

  const dispatch = useDispatch()

  const usdtRef = useRef<HTMLInputElement>(null)
  const addrRef = useRef<HTMLInputElement>(null)
  const selectorRef = useRef<HTMLSelectElement>(null)

  useEffect(() => {
    if (!activeModalParams) return
    setParams({
      min: 10,
      max: Math.round(((Number(usdt) ?? 0) + Number.EPSILON) * 10e6) / 10e6
    })
    setCurrentUSDT(String(Math.round(((Number(usdt) ?? 0) + Number.EPSILON) * 10e6) / 10e6))
  }, [activeModalParams, usdt])

  const handleInput = (newValue: HTMLInputElement) => {
    if (!params) return
    if (!newValue.value) {
      setCurrentUSDT(null)
      return
    }
    if (newValue.value.endsWith(',')) {
      newValue.value = newValue.value.replace(',', '.')
    }
    if (!newValue.validity.valid || Number.isNaN(parseInt(newValue.value))) return;
    const zeros = /^0.[0]+$/.test(newValue.value)
    const value: number = Number(newValue.value)
    if (value > params.max || value < 0) return;
    const addDot = newValue.value.endsWith('.') ? '.' : ''
    setCurrentUSDT(zeros ? newValue.value : ((value + addDot )))
  }

  const exchange = async () => {
    if (!params) return
    let flag = true
    if (Number(currentUSDT) > params.max || Number(currentUSDT) < params.min) {
      flag = false
      setInvalidVal(true)
      console.log('invalid value')
    }
    // if (!checkWalletValidity(chain, currentAddr ?? '')) {
    //   flag = false
    //   setInvalidAddr(true)
    //   console.log('invalid address')
    // }
    if (!flag) return
    // // withdrawal starts here
    // closeModal()

    const response = await fetchData('/user/withdrawal', {
      amount: currentUSDT,
    });

    if (response.error) {
      return;
    }

    const result = response.result;

    if (result) {
      console.log(result)
    }
    closeModal()
  }

  const closeModal = () => {
    if (usdtRef.current) usdtRef.current.blur()
    if (addrRef.current) addrRef.current.blur()
    if (selectorRef.current) selectorRef.current.blur()
    setActiveModal(null)
    setInvalidAddr(false)
    setInvalidVal(false)
    setCurrentAddr('')
    setChain('')
  }

  const cryptoBotLink = process.env.REACT_APP_CRYPTO_BOT_LINK || 'https://t.me/send?start=r-3d8ix'

  return (
    <Modal
            isOpen={activeModal === MODAL_WITHDRAW}
            placement='top-center'
            scrollBehavior='inside'
            className='bg-[#19192B] relative mt-8'
            onClose={() => {
              closeModal()
            }}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader></ModalHeader>
                        <ModalBody className="px-6 gap-6">
                            <div className='flex flex-col items-center justify-center'>
                              <p className="text-3xl text-white">
                                {t('balanceWithdrawUSDT')}
                              </p>
                              <div className="mt-5 flex flex-col gap-6 w-full">
                                <p className='tracking-wider text-sm font-bold uppercase'>
                                  {t('balanceWithdrawUSDTCaption')}
                                  <a className='px-3 py-2 text-[#4F3CC8] text-center' href={cryptoBotLink}
                                     target='_blank'>
                                    @send
                                  </a>
                                </p>
                                {/*<div className="relative">*/}
                                {/*  <Select*/}
                                {/*    ref={selectorRef}*/}
                                {/*    label={null}*/}
                                {/*    placeholder={t('walletSelect')}*/}
                                {/*    classNames={{*/}
                                {/*      listboxWrapper: "max-h-[400px]",*/}
                                {/*      label: "text-base",*/}
                                {/*      trigger: `w-full h-fit border-2 border-solid outline-none border-white/10 px-4 py-3 rounded-2xl bg-transparent data-[hover=true]:bg-inherit ${inFocus === 'wallet' ? 'border-[#4F3CC8]' : 'border-[#4B526A88]'}`*/}
                                {/*    }}*/}
                                {/*    onOpenChange={(isOpen) => isOpen ? setInFocus('wallet') : setInFocus(null)}*/}
                                {/*    onChange={(key) => {*/}
                                {/*      setChain(key.target.value)*/}
                                {/*      setInvalidAddr(false)*/}
                                {/*    }}*/}
                                {/*  >*/}
                                {/*    {walletList.map((wallet) => (*/}
                                {/*      <SelectItem key={wallet.key}>*/}
                                {/*        {wallet.label}*/}
                                {/*      </SelectItem>*/}
                                {/*    ))}*/}
                                {/*  </Select>*/}
                                {/*  <p className={`bg-[rgb(25,25,42)] px-1 font-semibold absolute top-[-8px] left-6 ${inFocus === 'wallet' ? 'text-[#4F3CC8]' : 'text-[#4B526A]'}`}>*/}
                                {/*    {t('wallet')}*/}
                                {/*  </p>*/}
                                {/*</div>*/}
                                {/*{*/}
                                {/*  chain !== '' ?*/}
                                {/*  <div className="Exchange--Address relative">*/}
                                {/*    <input*/}
                                {/*      ref={addrRef}*/}
                                {/*      type='text'*/}
                                {/*      id="addr"*/}
                                {/*      className={`w-full border-2 border-solid outline-none px-4 py-3 rounded-2xl bg-transparent focus:border-[#4F3CC8] ${invalidAddr ? 'border-red-500': 'border-white/10'}`}*/}
                                {/*      value={currentAddr ?? ""}*/}
                                {/*      onChange={e => {*/}
                                {/*          setCurrentAddr(e.target.value)*/}
                                {/*          setInvalidAddr(false)*/}
                                {/*        }*/}
                                {/*      }*/}
                                {/*      onFocus={() => setInFocus('address')}*/}
                                {/*      onBlur={() => setInFocus(null)}*/}
                                {/*      required*/}
                                {/*    />*/}
                                {/*    <p className={`bg-[rgb(25,25,42)] px-1 font-semibold absolute top-[-8px] left-6 ${inFocus === 'address' ? 'text-[#4F3CC8]' : (invalidAddr ? 'text-red-500' : 'text-[#4B526A]') }`}>*/}
                                {/*      {t('address')}*/}
                                {/*    </p>*/}
                                {/*    {*/}
                                {/*      currentAddr && currentAddr !== '' ?*/}
                                {/*      <div */}
                                {/*        className="absolute right-4 top-2.5 cursor-pointer bg-[rgb(25,25,42)]"*/}
                                {/*        onClick={() => {*/}
                                {/*          setCurrentAddr('')*/}
                                {/*        }}*/}
                                {/*      >*/}
                                {/*        <Icon24Close />*/}
                                {/*      </div> : null */}
                                {/*    }*/}
                                {/*  </div> : null*/}
                                {/*}*/}
                                <div className="Exchange--USDT relative">
                                  <input
                                    ref={usdtRef}
                                    type='tel'
                                    pattern="(\d*[\.\,]\d{1,7}|\d{1,7}[\.\,]\d*|\d{1,7})"
                                    id="USDT"
                                    className={`w-full border-2 border-solid outline-none px-4 py-3 rounded-2xl bg-transparent focus:border-[#4F3CC8] ${invalidVal ? 'border-red-500' : 'border-white/10'}`}
                                    value={currentUSDT ?? ""}
                                    onChange={e => {
                                      handleInput(e.target)
                                      setInvalidVal(false)
                                    }}
                                    onFocus={() => setInFocus('usdt')}
                                    onBlur={() => setInFocus(null)}
                                    required
                                  />
                                  <p
                                    className={`bg-[rgb(25,25,42)] px-1 font-semibold absolute top-[-8px] left-6 ${inFocus === 'usdt' ? 'text-[#4F3CC8]' : (invalidVal ? 'text-red-500' : 'text-[#4B526A]')}`}>
                                    {t('balanceGiveUSDT')} {'(>10$)'}
                                  </p>
                                  {
                                    currentUSDT && currentUSDT !== '0' ?
                                      <div
                                        className="absolute right-4 top-2.5 cursor-pointer"
                                        onClick={() => {
                                          setCurrentUSDT('0')
                                        }}
                                      >
                                        <Icon24Close/>
                                      </div> : null
                                  }
                                </div>
                              </div>
                            </div>
                        </ModalBody>
                      <ModalFooter className='flex-col'>
                          <Button
                            fullWidth
                            className='bg-[var(--link\_color)] opacity-100 rounded-lg px-3 h-fit py-4 text-lg'
                            onPress={() => exchange()}
                          >
                            {t('balanceWithdraw')}
                          </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
  )
}

export default WithdrawalModal
