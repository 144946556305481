import React, {FC} from 'react';
import Cell from "../../../../components/Cell/Cell";
import Img from "../../../../components/Img/Img";
import Icon16Chevron from "../../../../assets/icons/Icon16Chevron";
import IconText from "../../../../components/IconText/IconText";
import CellContainer from "../../../../components/CellContainer/CellContainer";
import {useTranslation} from "react-i18next";
import './InviteInfo.css'
import OverflowIcon from '../../../../components/OverflowIcon/OverflowIcon';
import CrystalBase from '../../../../assets/icons/currency/crystal/Base';
import CrystalShadow from '../../../../assets/icons/currency/crystal/Shadow';

interface InviteInfoProps {
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const InviteInfo: FC<InviteInfoProps> = ({ onClick }) => {

    const { t } = useTranslation();
    
    return (
        <div className='InviteInfo--wrapper rounded-xl mt-6'>
            <div className='InviteInfo--container py-4 rounded-xl pl-4 relative overflow-hidden'>
                <div className='InviteInfo--normal flex flex-col gap-1'>
                    <p className='text-base font-bold text-white'>
                        {t('friendsInviteAFriend')}
                    </p>
                    <div className='InviteInfo--normalReward flex gap-1'>
                        <OverflowIcon 
                            Base={<CrystalBase width='12px' height='20px' />}
                            Shadow={<CrystalShadow width='26px' height='23px' />}
                            shadowOffsetTop={11}
                            shadowOffsetLeft={-7}
                            width={12}
                            height={20}
                        />
                        <p className='text-sm font-black'>
                            {t('friendsUpTo')} 60
                        </p>
                        <p className='text-sm font-normal tracking-wider'>
                            {t('friendsYouAndMate')}
                        </p>
                    </div>
                </div>
                <div className='InviteInfo--premium flex flex-col gap-1 mt-4'>
                    <div className='InviteInfo--premium flex gap-1 flex-nowrap'>
                        <p className='text-base font-bold text-white'>
                            {t('friendsWith')}
                        </p>
                        <p className='InviteInfo--gradient text-base font-bold'>
                            Telegram Premium
                        </p>
                    </div>
                    <div className='InviteInfo--premiumReward flex gap-1'>
                        <OverflowIcon 
                            Base={<CrystalBase width='12px' height='20px' />}
                            Shadow={<CrystalShadow width='26px' height='23px' />}
                            shadowOffsetTop={11}
                            shadowOffsetLeft={-7}
                            width={12}
                            height={20}
                        />
                        <p className='text-sm font-black'>
                            {t('friendsUpTo')} 120
                        </p>
                        <p className='text-sm font-normal tracking-wider'>
                            {t('friendsYouAndMate')}
                        </p>
                    </div>
                </div>
                <div className='InviteInfo--image overflow-hidden max-h-[148px] absolute top-[-4px] right-[-52px]'>
                    <div className='InviteInfo--imagebg w-[172px] h-[159px] overflow-hidden'/>
                </div>
            </div>
        </div>
    );
};

export default InviteInfo;