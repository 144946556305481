import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/react';
import React, {FC, useEffect, useState} from 'react';
import {MODAL_BOOST_NORMAL} from "../routes";
import useModal from "../hooks/useModal";
import {useTranslation} from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { DefaultStateType, REDUCE_GOLD, SET_DAILY_ENERGY, SET_GOLD_PER_CLICK, UPDATE_BOOST, getDispatchObject } from '../store/reducer';
import WoodRocket from '../assets/icons/currency/rocket/Wood';
import { fetchData } from '../utils/api';
import { boostsFrontToServerId } from '../utils/utils';
import { formatNumberShort } from '../utils/mathUtils';
import { sendAnalytics } from '../utils/gtag';

interface ModalParams {
  id: string
  name: string
  description: string
  effect: string
  level: number
  price: number
}

// @ts-ignore
const tg = window['Telegram'].WebApp;

const BoostsNormalModal: FC = () => {

    const { t } = useTranslation();
    const {activeModal, setActiveModal, activeModalParams} = useModal();
    const [params, setParams] = useState<ModalParams>()
    const dispatch = useDispatch()

    useEffect(() => {
      if (!activeModalParams) return
      setParams({
        id: activeModalParams.id,
        name: t(`boostName--${activeModalParams.id}`),
        description: t(`boostDescription--${activeModalParams.id}`),
        price: activeModalParams.price,
        effect: t(`boostEffect--${activeModalParams.id}`),
        level: activeModalParams.level
      })
    }, [activeModalParams])

    const updateBoost = async () => {
      if (!params?.id) return
      const response = await fetchData('/boosts/get')
      const result = response.result
      const newBoost = result.find((item: any) => item.id === boostsFrontToServerId[params?.id])

      if (!newBoost) return

      dispatch(getDispatchObject(UPDATE_BOOST, {
        id: params.id,
        level: newBoost.level,
        price: newBoost.updateCost,
        isPremium: newBoost?.updateCostCurrency !== 'GOLD'
      }))
    }

    const buyBoost = async () => {
      if (!params?.id || !params?.price) return

      const response = await fetchData('/boosts/buy', {
        boost: boostsFrontToServerId[params?.id]
      })

      if (response.error || !response.result) return
      const result = response.result

      if (result.id === 'multiclick') {
          dispatch(getDispatchObject(SET_GOLD_PER_CLICK, result['newValue']));
      }

      if (result.id === 'energy') {
          dispatch(getDispatchObject(SET_DAILY_ENERGY, result['newValue']));
      }

      sendAnalytics('spend_virtual_currency', {
        event_category: 'boost',
        value: params.price,
        virtual_currency_name: 'coins',
        item_name: params.id
      })
      dispatch(getDispatchObject(REDUCE_GOLD, params.price));
      await updateBoost()
      setActiveModal(null)
      // TODO: API Call
    }

    const confirm =() => {
        tg.showPopup({
          title: t('boostsConfirmTitle'),
          message: t('boostsConfirmText'),
          buttons: [
              {
                  id: 'confirm',
                  type: 'default',
                  text: t('boostsConfirmButton')
              },
              {
                  id: 'cancel',
                  type: 'cancel',
                  text: t('boostsConfirmCancelButton')
              },
          ],
      }, (id: string) => {
          if (id === 'confirm') {
            buyBoost();
          }
      });
    }

    return (
        <Modal
            isOpen={params && activeModal === MODAL_BOOST_NORMAL}
            placement='bottom'
            scrollBehavior='inside'
            className='bg-[#19192B] relative'
            onClose={() => setActiveModal(null)}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader></ModalHeader>
                        <ModalBody className="px-6 gap-6">
                            <div className='flex flex-col items-center justify-center'>
                              <img width={96} height={96} className='object-contain' src={require(`../assets/images/boosts/${params?.id ?? 'tap'}.png`)} alt="" />
                              <p className='text-3xl font-bold text-center mt-5'>
                                {(params?.name) ?? t('noTitle')}
                              </p>
                              <p className='text-base/6 text-center mt-1.5'>
                                {(params?.description) ?? t('noDescription')}
                              </p>
                              <p className='text-base/6 text-white font-semibold text-center mt-4'>
                                {params?.effect ?? t('noEffect')} {t('boostsForLevel')} {(params?.level ?? 0) + 1}
                              </p>
                              <div className='flex w-fit gap-2 items-center mt-4'>
                                <WoodRocket height='36px' width='36px' />
                                <p className='text-3xl font-bold text-center'>
                                  {formatNumberShort(Math.ceil(params?.price ?? 0))}
                                </p>
                                <p className="BoostItem--divider text-3xl">•</p>
                                <p className="BoostItem--level text-3xl">
                                  {params?.level ?? 0} {t('boostsLevel')}
                                </p>
                              </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='flex-col'>
                          <Button
                            fullWidth
                            className='bg-[var(--link\_color)] opacity-100 rounded-lg px-3 h-fit py-4 text-lg'
                            onClick={confirm}
                          >
                            {t('boostsGet')}
                          </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default BoostsNormalModal;