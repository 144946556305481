import React, {FC, useMemo} from 'react';
import { colorMapping } from './colors';

interface FreindsAchProps {
  level: number
  height?: number
}

const FriendsAchievement: FC<FreindsAchProps> = ({ level, height = 96 }) => {
  
  const colorScheme = useMemo(() => {
    return level in colorMapping ? colorMapping[level] : colorMapping[0]
  }, [level])
  
    return (
      <svg width="100%" height={height} viewBox="4 0 100 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter={`url(#filter0_dd_5869_13851_${level})`}>
          <path 
            d="M71.286 32.6435L52.0205 37.447L53.7762 44.4884C55.0208 49.4804 51.9395 54.5625 46.9077 55.8171C41.8758 57.0717 36.7693 54.031 35.5247 49.0391L31.7124 33.749L24.7092 40.7038C22.6236 42.7597 21.7832 45.7606 22.4854 48.5772L23.9683 54.5247L15.277 62.8621C13.6136 64.4521 13.5564 67.0841 15.1641 68.7267L29.649 83.6263C31.2441 85.272 33.8913 85.3065 35.5546 83.7164L46.7886 72.9421L64.1022 68.6254C68.5764 67.5098 71.3142 62.9941 70.2076 58.5554L72.2355 58.0498C74.4789 57.4905 75.8431 55.2405 75.2882 53.0149L73.2817 44.9675L74.2957 44.7146C75.9814 44.2943 77.0022 42.6108 76.5852 40.9384L75.0804 34.9029C74.6634 33.2305 72.9718 32.2232 71.286 32.6435ZM88.834 23.2733L74.3491 8.37373C72.754 6.72799 70.1068 6.69353 68.4434 8.28355L57.2095 19.0579L44.256 22.2875C42.735 22.6668 41.3586 23.464 40.2633 24.5785L36.6726 28.2651C35.663 29.2915 35.2761 30.7636 35.621 32.1467L39.5806 48.0278C40.2734 50.8067 43.1033 52.4918 45.9044 51.7934C48.7055 51.095 50.4131 48.2786 49.7203 45.4997L46.9614 34.4345L70.2828 28.6198C74.1993 27.6433 78.1675 30.0062 79.1363 33.8916L80.0298 37.4752L88.7211 29.1379C90.3813 27.5353 90.4291 24.919 88.834 23.2733Z" 
            fill={`url(#paint0_linear_5869_13851_${level})`} 
            stroke={`url(#paint1_linear_5869_13851_${level})`} 
            strokeWidth="2"
          />
        </g>
      <defs>
      <filter id={`filter0_dd_5869_13851_${level}`} x="-10" y="-2" width="124" height="112" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="8"/>
        <feGaussianBlur stdDeviation="5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.00392157 0 0 0 0 0.0196078 0 0 0 0 0.2 0 0 0 0.08 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5869_13851"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="8"/>
        <feGaussianBlur stdDeviation="5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.00392157 0 0 0 0 0.0196078 0 0 0 0 0.2 0 0 0 0.08 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_5869_13851" result="effect2_dropShadow_5869_13851"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_5869_13851" result="shape"/>
      </filter>
      <linearGradient id={`paint0_linear_5869_13851_${level}`} x1="80.6312" y1="11.9265" x2="29.0621" y2="80.5139" gradientUnits="userSpaceOnUse">
        <stop stopColor={colorScheme.mainGrad.from}/>
        <stop offset="0.38" stopColor={colorScheme.mainGrad.middle}/>
        <stop offset="1" stopColor={colorScheme.mainGrad.to}/>
      </linearGradient>
      <linearGradient id={`paint1_linear_5869_13851_${level}`} x1="43.9711" y1="13.8138" x2="60.0213" y2="78.1876" gradientUnits="userSpaceOnUse">
        <stop stopColor={colorScheme.secGrad.from}/>
        <stop offset="1" stopColor={colorScheme.secGrad.to}/>
      </linearGradient>
      </defs>
      </svg>
    )
}

export default FriendsAchievement;
